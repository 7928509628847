import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PlayIconSvg from "../assets/icon_play.svg";
import { LightboxWrapper, LightboxItem } from './LightboxModal'
import Image from './Image';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import { parseCredits } from '../common/Media';
import useFetchMedia from '../hooks/useFetchMedia';
import TextCrop from './TextCrop';

interface BlockMediaWrapperProps {
  layout: "auto" | "full" | "thumbs" | "slide",
  lightbox?: boolean,
  children: JSX.Element | JSX.Element[]
}

interface BlockMediaItemProps {
  media?: any,
  lightbox?: boolean,
  caption?: string,
  credits?: string,
  onClick?: () => void
}

const BlockContainer = styled.ul`
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0 25px;
  float: left;
  width: 100%;
  display: block;
  position: relative;

  &:empty {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & img {
    border-radius: 4px;
  }

  &.thumbs {
    display: grid;
    gap: 20px 10px;
    justify-content: start;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: 450px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 600px) {
      grid-template-columns: repeat(5, 1fr);
    }

    & li {
      display: grid;
      align-content: space-between;
      padding: 10px;
      background-color: ${p => p.theme.backgroundColor};
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, .1);
      border-radius: 4px;

      &:active {
        filter: brightness(.95);
      }
    }

    & img {
      height: 110px;
      object-fit: contain;
    }

    & span {
      text-align: left;
    }
  }

  &.slide {
    display: flex;
    align-items: flex-start;
    overflow-x: auto;
    flex: 0 0 auto;

    & li:last-of-type {
      margin-right: 0;
    }

    & li {
      width: 140px;
      margin-right: 5px;
    }

    & img {
      width: 140px;
      height: 110px;
    }
  }

  &.full li:not(:last-of-type) {
    margin-bottom: 30px;
  }

  &.full picture, &.full figure {
    width: 100%;
    min-height: 100px;
  }
`;

const ImageItem = styled.li`
  float: left;
  width: 100%;
  margin-bottom: 0;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  & figure {
    margin: 0;
    display: inline;
    height: max-content;
    position: relative;
    float: left;
  }

  & img {
    max-height: 500px;
  }

  &.placeholder figure {
    background: ${p => p.theme.placeholderColor} !important;
  }

  &.video picture::before, &.youtube picture::before, &.vimeo picture::before, &.sketchfab picture::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: ${p => p.theme.accentColor};
    background-image: url(${PlayIconSvg});
    background-position: 60% 50%;
    background-size: 15px;
    background-repeat: no-repeat;
    pointer-events: none;
  }
`;

const ImageCaption = styled.figcaption`
  font-size: .9em;
  margin-top: 8px;
  display: block;
  float: left;
  clear: both;
  word-break: break-word;
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  width: fit-content;
`;

/**
 * Render an image/video block container with lightbox functionality
 * @returns {JSX.Element} Component template
 */
export const BlockMediaWrapper: FC<BlockMediaWrapperProps> = ({layout, lightbox, children}) => {  
  // Display as lightbox
  if (lightbox) {
    return (
      <BlockContainer className={layout}>
        <LightboxWrapper>
          {children}
        </LightboxWrapper>
      </BlockContainer>
    );
  } else {
    // Display without lightbox
    return (
      <BlockContainer className={layout}>
        {children}
      </BlockContainer>
    );
  }
}

const PlaceholderText = styled.div.attrs((p: any) => ({
  width: p.width || 100,
}))`
  aspect-ratio: 16 / 1;
  background: ${p => p.theme.placeholderColor};
  display: inline-block;
  width: -webkit-fill-available;
  height: 13px;
  border-radius: 2px;
  width: ${p => p.width}%;
`;

/**
 * Render an image/video block item with lightbox functionality
 * @returns {JSX.Element} Component template
 */
export const BlockMediaItem: FC<BlockMediaItemProps> = ({media, caption, credits, lightbox, onClick}) => {
  const { currentLanguage } = useContext(AppContext);
  const [mediaItem, setMediaItem] = useState<any>();
  const [mediaObject] = useFetchMedia(media);
  const [mediaCaption, setMediaCaption] = useState<JSX.Element>();

  // Set media captions and credits
  useEffect(() => {
    const mediaTitle = getTranslation(mediaObject?.title, currentLanguage) || caption || "";
    const mediaDescription = getTranslation(mediaObject?.description, currentLanguage) || "";
    const mediaCredits = credits || parseCredits(mediaObject?.credits) || "";

    setMediaCaption(
      <>
        {mediaTitle && (<><strong>{mediaTitle}</strong><br/></>)}
        <TextCrop text={mediaDescription + " " + mediaCredits} length={100}/>
      </>
    )
  }, [mediaObject, caption, currentLanguage, credits]);

  // Construct media item
  useEffect(() => {
    setMediaItem(
      <ImageWrapper onClick={onClick}>
        {mediaObject && (<Image className="loading link" media={mediaObject}/>)}
      </ImageWrapper>
    );
  }, [mediaObject, onClick]);

  // Display placeholder while loading
  if (!mediaItem) { 
    return (
      <ImageWrapper className="placeholder">
        <Image/>
        <ImageCaption><PlaceholderText width="50"/></ImageCaption>
      </ImageWrapper>
    )
  }

    // Render media item - with or without lightbox wrapper
  if (lightbox) {
    switch (mediaObject?.media_type) {
      case "video":
      case "image":
      case "dm":
      case "sketchfab":
      case "youtube":
      case "vimeo":
        return (
          <ImageItem className={mediaObject?.media_type}>
            <LightboxItem media={mediaObject} caption={caption} credits={credits}>
              {mediaItem}
            </LightboxItem>
            {mediaCaption && (<ImageCaption>{mediaCaption}</ImageCaption>)}
          </ImageItem>
        );
      default:
        return mediaItem;
    }
  } else {
    return (
      <ImageItem className={mediaObject?.media_type}>
        {mediaItem}
        {mediaCaption && (<ImageCaption>{mediaCaption}</ImageCaption>)}
      </ImageItem>
    )
  }
}