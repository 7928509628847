import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Image from './Image';
import DistanceIcon from "../assets/icon_distance_black.svg";
import ChevronRight from "../assets/chevron_next.svg";
import { LocationContext } from '../contexts/LocationContext';
import { calculateDistance } from '../common/Location';
import { PlaceholderText } from './Placeholders';
import useFetchOwnerProfile from '../hooks/useFetchOwnerProfile';

interface ListItemProps {
  media?: any,
  title: string,
  description?: string,
  location?: {lat: number, lng: number},
  owner?: string,
  profile?: any,
  link?: string
}

interface VListPlaceholderItemProps {
  count: number
}

const ListItem = styled.li`
  clear: both;
  float: left;
  padding: 8px 40px 8px 25px;
  background-image: url(${ChevronRight});
  background-repeat: no-repeat;
  background-position: calc(100% - 25px) 50%;
  -webkit-tap-highlight-color: ${p => p.theme.accentColor}10;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    left: 25px;
    right: 25px;
    height: 1px;
    bottom: 0;
    border-bottom: 1px solid #E8EAED;
  }

  &:last-child {
    &::before {
      border-bottom: none;
    }
  }
`;

const ListLink = styled.a`
  display: block;
  text-decoration: none;
  width: 100%;
`;

const Img = styled(Image).attrs((p: any) => ({
  hasLocation: p.hasLocation,
}))`
  float: left;
  width: 85px;
  height: 70px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  align-self: flex-start;
  background-color: #333;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 30px;
    width: 100%;
    height: 40px;
    background: ${p => p.hasLocation ? 'linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)' : "none"};
    z-index: 0;
  }

  &.placeholder {
    background: ${p => p.theme.placeholderColor} !important;
    &::after {
      background: none;
    }
  }
`;

const Metadata = styled.div``;

const Title = styled.span`
  font-weight: 700;
  font-size: .9em;
  line-height: 17px;
  margin-bottom: 5px;
  display: block;
  --max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${p => p.theme.textColor};
`;

const Description = styled.span`
  font-size: .9em;
  font-weight: 400;
  margin-bottom: 10px;
  overflow: hidden;
  --max-lines: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${p => p.theme.textColor};
`;

const Distance = styled.span`
  /*
  color: #fff;
  position: absolute;
  bottom: 12px;
  left: 34px;
  */
  background-image: url(${DistanceIcon});
  background-position: 0 50%;
  background-repeat: no-repeat;
  font-size: .8em;
  font-weight: 400;
  line-height: 17px;
  margin: 0 12px 0 0;
  padding-left: 16px;
  display: block;
  float: left;
  z-index: 1;
  color: ${p => p.theme.textColor};
`;

const Owner = styled.span`
  font-size: .8em;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  --max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${p => p.theme.textColor};
`;

/**
 * Render a vertical list container
 * @returns {JSX.Element} Component template
 */
export const VListWrapper = styled.ul`
  margin: 0 0 25px;
  padding: 0;
  list-style: none;
  width: 100%;
  float: left;
  overflow: hidden;
  position: relative;
`;

/**
 * Render a vertical list element
 * @returns {JSX.Element} Component template
 */
export const VListItem: FC<ListItemProps> = ({media, title, description, location, owner, profile, link}) => {
  const { geoLocation } = useContext(LocationContext);
  const [mediaObject, setMediaObject] = useState<any>(null);
  const [profileImage] = useFetchOwnerProfile(profile);

  // Put media in state to prevent rerenders
  useEffect(() => {
    if (!mediaObject || mediaObject !== media) {
      setMediaObject(media || profileImage);
    }
  }, [media, mediaObject, profileImage]);

  return (
    <ListItem>
      <ListLink href={link} aria-label={title} tabIndex={0}>
        <Img media={mediaObject} usePlaceholder={true} hasLocation={Boolean(location)} alt={title}/>
        <Metadata>
          <Title>{title}</Title>
          {description && (<Description>{description}</Description>)}
          {(geoLocation?.lat && location?.lat) && (<Distance>{calculateDistance([geoLocation?.lat, geoLocation?.lng], [location?.lat, location?.lng])}</Distance>)}
          {owner && (<Owner>{owner}</Owner>)}
        </Metadata>
      </ListLink>
    </ListItem>
  )
};

/**
 * Render placeholder items to be displayed while fetching data
 * @param {number} count Number of items to be rendered 
 * @returns {JSX.Element} Component template
 */
export const VListPlaceholderItem: FC<VListPlaceholderItemProps> = ({count}) => {
  const iterateArray = Array.from(Array(count).keys());

  return (
    <>
      {iterateArray?.map((item: any, i: number) => {
        return <ListItem key={`placeholder${i}`}>
          <Img className="placeholder"/>
          <Metadata>
            <Title><PlaceholderText width={80}/></Title>
            <Description><PlaceholderText width={35}/></Description>
          </Metadata>
        </ListItem>
      })}
    </>
  );
}