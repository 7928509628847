import { FC, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Image from './Image';
import DistanceIcon from "../assets/icon_distance_white.svg";
import { AppContext } from '../contexts/AppContext';
import { calculateDistance } from '../common/Location';
import { LocationContext } from '../contexts/LocationContext';
import FastAverageColor from 'fast-average-color';
import useFetchMedia from '../hooks/useFetchMedia';

interface ArticleHeaderProps {
  media?: any,
  title?: string,
  subtitle?: string
  location?: {lat: number, lng: number},
  link?: string
}

const HeaderWrapper = styled.div`
  &.desktop {
    padding: 10px;
    background-color: ${p => p.theme.placeholderColor};
    margin: 0 25px 50px;
    transition: background-color .2s;
    border-radius: 4px;
    width: calc(100% - 50px);
    cursor: pointer;

    & > div {
      border-radius: 4px;
      overflow: hidden;
      width: 450px;
      height: 300px;
      margin: 0 auto;
    }
  }
`;

const HeroLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  text-decoration: none;

  &:focus-visible::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid ${p => p.theme.accentColor};
    width: calc(100% - 6px);
  }
`;

const Header = styled.div`
  width: 100%;
  display: block;
  height: 330px;
  position: relative;
  background-color: #333;
  margin: 0;
  margin-bottom: 30px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 60%,rgba(0,0,0,0.4) 100%);
    pointer-events: none;
  }

  &.link {
    cursor: pointer;
    -webkit-tap-highlight-color: ${p => p.theme.accentColor}10;
  }
`;

const Img = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Metadata = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 25px;
  padding: 0 25px;
  width: 100%;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin: 0 0 15px 0;
`;

const Subtitle = styled.span`
  font-size: 0.9em;
  color: #fff;
  text-align: center;
  display: block;
`;

const Distance = styled.span`
  color: #fff;
  font-size: 0.8em;
  font-weight: bold;
  margin: 0 auto 10px;
  background-color: #00000060;
  background-image: url(${DistanceIcon});
  background-position: 15px 50%;
  background-size: 10px;
  background-repeat: no-repeat;
  border-radius: 60px;
  padding: 8px 15px 8px 30px;
  display: inline-block;
`;

/**
 * Render the front page hero
 * @returns {JSX.Element} Component template
 */
const HeaderFrontPage: FC<ArticleHeaderProps> = ({media, title, subtitle, location, link}) => {  
  const { geoLocation } = useContext(LocationContext);
  const { isDesktop } = useContext(AppContext);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [mediaObject] = useFetchMedia(media);

  // Set average color background
  useEffect(() => {
    if (!isDesktop || !mediaObject?.url) { return; }

    const fac = new FastAverageColor();
    fac.getColorAsync(`${mediaObject?.url}?dimension=167x167`).then(color => {
      if (wrapperRef.current) {
        wrapperRef.current.style.backgroundColor = color.rgba;
      }
    });
  }, [isDesktop, mediaObject]);

  return (
    <HeaderWrapper className={isDesktop ? "desktop" : "mobile"} ref={wrapperRef}>
      <Header className={`${Boolean(link) ? "link" : undefined}`}>
        <HeroLink href={link} aria-label={title}>
          <Img media={mediaObject} alt={title}/>
          <Metadata>
            {(geoLocation?.lat && location) && (<Distance>{calculateDistance([geoLocation?.lat, geoLocation?.lng], [location?.lat, location?.lng])}</Distance>)}
            {title && (<Title>{title}</Title>)}
            {subtitle && (<Subtitle>{subtitle}</Subtitle>)}
          </Metadata>
        </HeroLink>
      </Header>
    </HeaderWrapper>
  );
}

export default HeaderFrontPage;