import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { useLocation, Link } from 'react-router-dom'
import { ReactComponent as HomeIcon } from "../assets/tabbar_home.svg";
import { ReactComponent as ExploreIcon } from "../assets/tabbar_explore.svg";
import { ReactComponent as CodeIcon } from "../assets/tabbar_code.svg";
import { ReactComponent as ScanIcon } from "../assets/tabbar_scan.svg";
import FloatingAudioPlayer from '../components/FloatingAudioPlayer';

const Tabs = styled.nav`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: baseline;
  position: fixed;
  z-index: 30;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
  background: #fff;
  padding: 12px 0 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`

const Tab = styled(Link)`
  float: left;
  text-align: center;
  position: relative;
  text-decoration: none;

  & span {
    color: ${p => p.theme.textSecondaryColor};
  }

  & svg {
    fill: ${p => p.theme.textSecondaryColor};
  }

  &&::before {
    content: "";
    position: absolute;
    padding: 35px 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:active svg, &.active svg {
    fill: ${p => p.theme.accentColor};
  }

  &:active span, &.active span {
    color: ${p => p.theme.accentColor};
    font-weight: 700;
  }

  &:visited {
    color: #000;
  }
`;

const Title = styled.span`
  font-size: .7em;
  display: block;
  text-align: center;
  margin-top: 3px;
`;

/**
 * Render the bottom tab bar
 * @returns {JSX.Element} Component template
 */
const TabBar: FC = () => {
  const { t } = useTranslation();
  const currentPage = useLocation()?.pathname;

  return (
    <>
      <FloatingAudioPlayer/>
      <Tabs role="menu" className="kp-nav previewdisable">
        <Tab role="menuitem" aria-label={t("tabs.home")} to="/" className={currentPage === "/" ? "active" : ""}><HomeIcon/><Title>{t("tabs.home")}</Title></Tab>
        <Tab role="menuitem" aria-label={t("tabs.explore")} to="/explore/map" className={currentPage.startsWith("/explore") ? "active" : ""}><ExploreIcon/><Title>{t("tabs.explore")}</Title></Tab>
        <Tab role="menuitem" aria-label={t("tabs.code")} to="/code" className={currentPage.startsWith("/code") ? "active" : ""}><CodeIcon/><Title>{t("tabs.code")}</Title></Tab>
        <Tab role="menuitem" aria-label={t("tabs.scan")} to="/scan" className={currentPage.startsWith("/scan") ? "active" : ""}><ScanIcon/><Title>{t("tabs.scan")}</Title></Tab>
      </Tabs>
    </>
  );
}

export default TabBar;