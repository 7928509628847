import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatDate } from '../common/Date';
import { AppContext } from '../contexts/AppContext';
import Api from '../services/Api';
import BlockError from './BlockError';
import Image from './Image';
import Pagination from './Pagination';
import { PlaceholderText } from './Placeholders';

interface BlockMinneProps {
  topicId: any
}

const BlockMinneWrapper = styled.div`
  margin: 0 0 30px 0;
  padding: 0 25px;
  width: 100%;
  float: left;
`;

const Responses = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  align-content: space-between;
  gap: 15px;

  @media (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Response = styled.li`
  padding: 20px;
  background-color: ${p => p.theme.backgroundColor};
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, .1);
  border-radius: 4px;
  cursor: pointer;
  min-height: 110px;

  &:active {
    filter: brightness(.95);
  }

  & a {
    text-decoration: none;
    color: #000;
  }
`;

const ThumbImage = styled(Image)`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin-right: 15px;

  & img {
    border-radius: 50%;
  }
`

const Metadata = styled.div`
`;

const Text = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  --max-lines: 2;
   display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const Author = styled.span`
  margin-top: 10px;
  display: block;
  font-size: .9em;
`;

/**
 * Render a Minne block
 * @returns {JSX.Element} Component template
 */
 export const BlockMinne: FC<BlockMinneProps> = ({topicId}) => {
  const { previewIsActive } = useContext(AppContext);
  const [minneData, setMinneData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(6);
  const [countryCode, setCountryCode] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);

  /**
   * Fetch responses from the Minne API
   */
  const fetchMinneResponses = useCallback((topicId: string, country: string) => {
    return new Promise(function(resolve, reject) {
      Api.getMinneContent(`responses/?topic=${topicId}&offset=${pageSize * (currentPage - 1)}&limit=${pageSize}&order=created`, country).then(data => {
        if (data?.items) {
          setMinneData(data);
          setCountryCode(country);
        }
        resolve(data);
      }).catch(e => {
        reject();
      });
    });
  }, [currentPage, pageSize]);

  // Query topic data from Minne. First try norwegian, and then if not found - try swedish
  useEffect(() => {
    if (!topicId || topicId?.length !== 36) { return; }

    // Try norwegian
    fetchMinneResponses(topicId, "no").then((data: any) => {
      if (data?.items) {
        setHasError(false);  
      } else {
        // Then try swedish
        fetchMinneResponses(topicId, "sv").then((data: any) => {
          if (data?.items) {
            setHasError(false);
          } else {
            setHasError(true);
          }
        }).catch(() => {
          setHasError(true);
        });
      }
    }).catch(() => {
      setHasError(true);
    });
  }, [topicId, fetchMinneResponses]);

  if (!topicId) { return null; }

  if (previewIsActive && (topicId?.length !== 36 || hasError)) {
    return <BlockError text={`Kunne ikke hente data fra Minne-APIet for topicId '${topicId}'.`}></BlockError>;
  }

  return (
    <BlockMinneWrapper>
    {!minneData?.items ? (
      <Responses>
        {[...Array(pageSize)].map((e, i) => <Response key={`placeholder${i}`}><PlaceholderText/><PlaceholderText/><PlaceholderText width={80}/></Response>)}
      </Responses>
    ) : (
      <Responses>
      { minneData?.items?.map((response: any, i: number) => { 
        const createdTime = Date.parse(response?.created);

        return <Response key={`response${i}`}>
          <Link to={`${countryCode === "no" ? "/minner" : "/minnen"}/${response?.uuid}`}>
            {response?.image_dms_id && (<ThumbImage media={{dmsId: response?.image_dms_id, media_type: "image"}}/>)}
            <Metadata>
              <Text>{response?.values?.[0]?.display_value}</Text>
              <Author>{response?.contributor?.display_name}, {formatDate(createdTime)}</Author>
            </Metadata>
          </Link>
        </Response>
      })}
    </Responses>
    )}
    <Pagination currentPage={currentPage} itemCount={minneData?.total_count} pageSize={pageSize} setCurrentPage={setCurrentPage}  />
  </BlockMinneWrapper>
  );
}