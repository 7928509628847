import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { AppContext } from '../contexts/AppContext';
import BlockTitle from '../components/BlockTitle';
import { VListWrapper, VListItem, VListPlaceholderItem } from '../components/ListVertical';
import PageTransition from '../components/PageTransition';
import Separator from '../components/Separator';
import Header from '../framework/Header';
import HeroArticle from '../components/HeroArticle';
import Button, { ButtonContainer } from '../components/Button';
import ButtonDistance from '../components/ButtonDistance';
import ErrorMessage from '../components/ErrorMessage';
import { ReactComponent as CheckInIcon } from "../assets/icon_checkin.svg";
import useFetchOwner from '../hooks/useFetchOwner';
import { Helmet } from 'react-helmet-async';
import { trackOwner } from '../common/Analytics';
import styled from 'styled-components';
import useFetchPresentationList from '../hooks/useFetchPresentationList';
import { getTranslation } from '../common/Translation';
import useFetchOwnerProfile from '../hooks/useFetchOwnerProfile';
import { PlaceholderText } from '../components/Placeholders';
import TextCrop from '../components/TextCrop';
import Api from '../services/Api';

interface OwnerViewProps {
  filter?: boolean
}

const BlockWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  margin: 30px 0 40px;
  padding: 0 25px;
`;

const Preface = styled.div`
  margin: 0 0 30px;
  padding: 0 25px;
  text-align: center;
  width: 100%;
  font-size: 1.125em;
  line-height: 24px;
`;

const DistanceWrapper = styled.div`
  margin: 0 10px 25px;
  text-align: center;
`;


/**
 * Render the owner page
 * @returns {JSX.Element} Component template
 */
const OwnerView: FC<OwnerViewProps> = ({filter}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [metaDescription, setMetaDescription] = useState<string>();
  const [firstArticleImages, setFirstArticleImages] = useState<string[]>([]);
  const { currentLanguage, ownerFilter, setOwnerFilter } = useContext(AppContext);
  const { ownerId } = useParams<{ownerId: string}>();
  const [ownerData, isLoading, error] = useFetchOwner(ownerId);
  const [presentationList] = useFetchPresentationList({ page_size: 100, owner_id: ownerId }, { increment: true }, Boolean(ownerId));
  const [mainImage, avatarImage] = useFetchOwnerProfile(ownerData);

  /**
   * User clicks "show on map"
   */
  const showOnMap = useCallback(() => {
    const mapUrl = `/explore/map/?pos=${ownerData?.location?.lat},${ownerData?.location?.lng}&zoom=18&id=m${ownerData?.id}`;
    navigate(mapUrl);
  }, [ownerData, navigate]);

  /**
   * User clicks filter button
   */
  const activateOwnerFilter = useCallback(() => {
    if (!ownerData) {return}

    setOwnerFilter({id: ownerData?.id, name: ownerData?.name, analyticsId: ownerData?.google_analytics_code});
    if (ownerId) {
      navigate("/");
    }
  }, [setOwnerFilter, navigate, ownerData, ownerId]);

  /**
   * User clicks "check out"
   */
   const deactivateOwnerFilter = useCallback(() => {
    setOwnerFilter(undefined);
  }, [setOwnerFilter]);

  // Check in via URL - owner/:id/filter
  useEffect(() => {
    if (filter && ownerData?.id) {
      activateOwnerFilter();
    }
  }, [filter, activateOwnerFilter, ownerData]);

  // Get image from first article in every presentation
  useEffect(() => {
    if (!presentationList?.items) { return; }
    let articleIds: number[] = [];
    let imageUrls: string[] = [];
    
    // Get first document Id from all presentations
    presentationList?.items?.forEach((presentation: any, i: number) => {
      articleIds[i] = presentation?.content?.articles?.list?.[0]?.to_document_id;
    });

    // Get article head from all articles
    Api.getDocumentHeadList(articleIds).then(articleList => {
      articleIds?.forEach((id: any, i: number) => {
        articleList.forEach((item:any) => {
          if (item?.document_id === id) {
            imageUrls[i] = item?.image_src;
          }
        });
      });

      setFirstArticleImages(imageUrls);
    });
  }, [presentationList]);

  // Update title
  useEffect(() => {
    document.title = `${ownerData?.name || t("list.museums")} - KulturPunkt`;
  }, [t, ownerData?.name]);

  // Update meta description
  useEffect(() => {
    if (ownerData?.description) {
      setMetaDescription(ownerData?.description);
    }
  }, [setMetaDescription, ownerData]);

  // Analytics tracker
  useEffect(() => {
    trackOwner(ownerData?.google_analytics_code);
  }, [ownerData?.google_analytics_code]);

  // Display error - if data could not be fetched
  if (error) {
    return (
      <>
        <Header/>
        <ErrorMessage message={t(error?.message)} tryAgain={true}/>
      </>
    );
  }

  return (
    <>
      {metaDescription && (
      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta property="twitter:description" content={metaDescription} />
      </Helmet>
      )}

      <Header layout='transparent' showProgress={isLoading}/>
      <PageTransition>
        <HeroArticle layout='transparent' media={mainImage} avatar={avatarImage} shareButton={true} fullscreenButton={true}/>
        
        <BlockWrapper>
          <Title>{ownerData?.name || <PlaceholderText width="60"/>}</Title>

          <DistanceWrapper>
            <ButtonDistance onClick={() => showOnMap()} location={ownerData?.location}>{t("article.showOnMap")}</ButtonDistance>
          </DistanceWrapper>

          <Preface><TextCrop text={ownerData?.description}/></Preface>
          
          <ButtonContainer>
            {!ownerFilter || ownerFilter?.id !== ownerData?.id ? (
              <Button layout="filled" onClick={activateOwnerFilter}><CheckInIcon/>{t("filter.activateFilter")}</Button>
            ): (
              <Button layout="filled" onClick={deactivateOwnerFilter}><CheckInIcon/>{t("filter.deactivateFilter")}</Button>
            )}
            {/*<Button layout="outline">{t("article.readMore")}</Button>*/}
          </ButtonContainer>

          <Separator/>

          {(presentationList) ? (
            <>
              {presentationList?.items?.[0] && (
                <>
                  <BlockTitle title={t("owner.content")} level="h2"/>
                  <VListWrapper>
                    {Symbol.iterator in Object(presentationList?.items) && presentationList?.items?.map((item: any, i) => {
                      if (item?.status !== "published" || !item?.title) { return null; }
                      return <VListItem key={`presentation${i}`}
                      title={getTranslation(item?.title, currentLanguage)}
                      media={{url: item?.image_url || firstArticleImages?.[i], media_type: "image", ...item?.content?.general?.image}}
                      link={`/group/${item?.id}`}
                      description={getTranslation(item?.description, currentLanguage)}/>              
                    })}
                  </VListWrapper>
                </>
              )}
            </>
          ) : (
            <VListWrapper>
              <VListPlaceholderItem count={3}/>
            </VListWrapper>
          )}

        </BlockWrapper>
      </PageTransition>
    </>
  );
}

export default OwnerView;
