import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

interface BlockTitleProps {
  title: string,
  level?: "h1" | "h2" | "h3" | "anchor", 
  actionTitle?: string,
  actionLink?: string,
  count?: number,
  id?: string
}

const BlockContainer = styled.div`
  margin-bottom: 20px;
  padding: 0 25px;
  float: left;
  width: 100%;
`;

const Counter = styled.span`
  font-weight: normal;
  opacity: .8;
`;

const H1Element = styled.h1`
  font-weight: bold;
  font-size: 1.5em;
  float: left;
  margin: 30px 0 0;
  padding: 0;
`;

const H2Element = styled.h2`
  font-weight: bold;
  font-size: 1em;
  float: left;
  margin: 0;
  padding: 0;
`;

const AnchorElement = styled.h2`
  padding: 15px 0 0;
  font-size: 1.2em;
  font-weight: bold;
  float: left;
  margin: 0;
`

const H3Element = styled.h2`
  font-weight: normal;
  font-size: 1em;
  float: left;
  margin: 0;
  padding: 0;
`;

const ActionElement = styled.a`
  float: right;
  font-size: .8em;
  font-weight: 700;
  opacity: .8;
  cursor: pointer;
  color: ${p => p.theme.darkAccentColor};
  text-decoration: none;
  position: relative;

  &:active {
    opacity: .8;
  }

  &&::before {
    content: "";
    position: absolute;
    padding: 15px 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    border-radius: 5px;
  }
`;

/**
 * Render a block title
 * @returns {JSX.Element} Component template
 */
const BlockTitle: FC<BlockTitleProps> = ({id, title, level, count, actionTitle, actionLink}) => {
  const [counterText, setCounterText] = useState<any>();

  useEffect(() => {
    if (count && count > 0) {
      setCounterText(<Counter>{` (${count})`}</Counter>);
    } else {
      setCounterText("");
    }
  }, [count, setCounterText]);

  return (
    <BlockContainer id={id || undefined}>
      {(level === "h1" || !level) && (<H1Element>{title}{counterText}</H1Element>)}
      {level === "h2" && (<H2Element>{title}{counterText}</H2Element>)}
      {level === "h3" && (<H3Element>{title}{counterText}</H3Element>)}
      {level === "anchor" && (<AnchorElement>{title}{counterText}</AnchorElement>)}
      {actionTitle && actionLink && (
        <ActionElement href={actionLink}>{actionTitle}</ActionElement>
      )}
    </BlockContainer>
  );
}

export default BlockTitle;