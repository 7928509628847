import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Overlay from '../components/Overlay';
import PageTransition from '../components/PageTransition';
import Header from '../framework/Header';
import Api from '../services/Api';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70vh;
  max-height: calc(100vh - 200px);
  user-select: none;

  @media screen and (max-height: 700px) {
    transform: scale(0.9);
    margin-top: -20px;
  }

  @media screen and (max-height: 650px) {
    transform: scale(0.7);
  }
`;

const Instruction = styled.p`
  font-weight: bold;
  text-align: center;
  margin: 30px 50px 0 50px;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  justify-content: center;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto auto;
  margin: 0 50px 10px 50px;
`;

const InputField = styled.div`
  text-align: center;
  font-size: 1.4em;
  height: 50px;
  border-bottom: 1px solid #000;
  width: 50px;
  padding: 10px;
`;

const DialWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: 10px 0 20px;
`;

const DialButton = styled.button`
  display: grid;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 37.5px;
  font-size: 1.9em;
  text-align: center;
  background-color: ${p => p.theme.separatorColor};
  cursor: pointer;
  border: none;
  color: #000;

  &:active {
    background-color: ${p => p.theme.weakAccentColor};
    color: #fff;
  }

  &.zero {
    grid-row: 4;
    grid-column: 2;
  }

  &.delete {
    font-size: 1.5em;
    grid-row: 4;
    grid-column: 3;
    width: 30px;
    height: 40px;
    border-radius: 0;
    position: relative;
    padding: 0 8px 0 0;
    transform: translateX(10px);
    
    &::before {
      content: "";
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid ${p => p.theme.separatorColor};
      position: absolute; left: -20px; top: 0;
    }

    &:active::before {
      border-right: 20px solid ${p => p.theme.accentColor};
    }
  }
`;

/**
 * Render the code page
 * @returns {JSX.Element} Component template
 */
const CodeView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const availableDigits: number = 5;
  const currentCodeRef = useRef<HTMLDivElement>(null);
  const [currentCode, setCurrentCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  // Update title
  useEffect(() => {
    document.title = `${t("tabs.code")} - KulturPunkt`;
  }, [t]);

  /**
   * Reset interface after code typing
   */
  const clearInterface = useCallback((found: boolean) => {
    if (found) {
      setIsLoading(true);
    } else {
      setIsNotFound(true);
      setIsLoading(false);
    }
    setTimeout(() => {
      setIsNotFound(false);
      setCurrentCode("");
    }, 2500);
  }, []);

  /**
   * Submit and process code
   * @param code Code to submit
   */
   const submitCode = useCallback((code: string) => {
    console.log(`Entered code: ${code}`);
    setIsLoading(true);

    // Look for code
    Api.getDocument(code).then(data => {
      // Found!
      clearInterface(true);
      navigate(`/article/${code}`)
    }).catch(e1 => {
      // Look for legacy ID
      Api.getDocumentList({ old_id: code }).then(legacyData => {
        if (legacyData?.items?.length > 0) {
          clearInterface(true);
          navigate(`/article/${legacyData?.items?.[0]?.id}`);
        } else {
          clearInterface(false);
        }
      }).catch(e2 => {
        setIsNotFound(true);
        clearInterface(false);
      });
    });
  }, [navigate, clearInterface]);

  // Update input fields when a new digit is added
  useEffect(() => {
    if (currentCodeRef?.current) {
      const inputFields = Array.from(currentCodeRef?.current?.children);
      inputFields?.forEach((inputField, i) => {
        inputField.innerHTML = String(currentCode).charAt(i);
      });
    }

    if (currentCode.length === availableDigits) {
      submitCode(currentCode);
    }
  }, [currentCode, submitCode]);

  /**
   * Add digit to state on click
   * @param {string} digit Digit to insert
   */
  const addDigit = useCallback((digit: string) => {
    if (currentCode.length < availableDigits) {
      setCurrentCode(currentCode + digit);
    }
  }, [currentCode]);

  /**
   * Remove last digit from state
   */
  const removeLastDigit = useCallback(() => {
    if (currentCode.length > 0) {
      setCurrentCode(currentCode.substring(0, currentCode.length - 1));
    }
  }, [currentCode]);

  return (
    <>
      <Header/>
      {isLoading && (<Overlay visibility={isLoading} messageType="loading"/>)}
      {isNotFound && (<Overlay visibility={isNotFound} message={t("code.notFound")} messageType="warning"/>)}
      <PageTransition scroll={false}>
        <Wrapper>
          <Instruction>{t("code.instruction")}</Instruction>
          <InputWrapper ref={currentCodeRef}>
            {Array.from(Array(availableDigits), (item, i) => {
              return <InputField key={`digit${i}`}/>
            })}
          </InputWrapper>
          <DialWrapper>
            <DialButton onClick={() => {addDigit("1")}}>1</DialButton>
            <DialButton onClick={() => {addDigit("2")}}>2</DialButton>
            <DialButton onClick={() => {addDigit("3")}}>3</DialButton>
            <DialButton onClick={() => {addDigit("4")}}>4</DialButton>
            <DialButton onClick={() => {addDigit("5")}}>5</DialButton>
            <DialButton onClick={() => {addDigit("6")}}>6</DialButton>
            <DialButton onClick={() => {addDigit("7")}}>7</DialButton>
            <DialButton onClick={() => {addDigit("8")}}>8</DialButton>
            <DialButton onClick={() => {addDigit("9")}}>9</DialButton>
            <DialButton onClick={() => {addDigit("0")}} className="zero">0</DialButton>
            <DialButton onClick={() => {removeLastDigit()}} className="delete">x</DialButton>
          </DialWrapper>
        </Wrapper>
      </PageTransition>
    </>
  );
}

export default React.memo(CodeView);
