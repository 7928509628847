import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import PageTransition from '../components/PageTransition';
import Header from '../framework/Header';
import { QrReader } from 'react-qr-reader';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as SpinnerIcon } from '../assets/icon_spinner.svg';
import { ReactComponent as WarningIcon } from '../assets/icon_warning.svg';
import { useLocation } from 'react-router-dom';

const Overlay = styled.div`
  position: absolute;
  width: 250px;
  height: 250px;
  border: 2px solid #fff;
  border-radius: 6px;
  box-shadow 0 0 0 1000px rgba(0, 0, 0, .5);
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 19;
  user-select: none;
`;

const Message = styled.span`
  position: absolute;
  top: 130%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
`;

const SpinAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Icon = styled.div`
  text-align: center;
  padding: 10px;

  &.spin svg {
    animation: ${SpinAnimation} 2s infinite linear;
  }
`;

const videoContainerStyle = {
  position: "absolute",
  zIndex: 10,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: "100%",
  height: "100%"
}

const videoStyle = {
  objectFit: "cover"
}

/**
 * Render the QR scanner
 * @returns {JSX.Element} Component template
 */
const ScanView: FC = () => {
  const { t } = useTranslation();
  const currentRoute = useLocation()?.pathname;
  const [currentStatus, setCurrentStatus] = useState("scan");
  const [isActivePage, setIsActivePage] = useState<boolean>(false);

  // Update title
  useEffect(() => {
    document.title = `${t("tabs.scan")} - KulturPunkt`;
  }, [t]);

  /**
   * Is the current page active?
   */
  useEffect(() => {
    setIsActivePage(currentRoute === "/scan");
  }, [currentRoute]);

  /**
   * Execute when the scanner finds a QR code
   * @param result 
   * @param error 
   */
  const onScan = useCallback((result: any, error: any) => {
    if (result) {
      const qrCode = String(Object(result).text);
    
      if (!qrCode.startsWith("http")) {
        setCurrentStatus("wrongFormat");
        setTimeout(() => { setCurrentStatus("scan"); }, 2000);
        return;
      }
    
      if (!qrCode.startsWith("https://kulturpunkt.org")) {
        setCurrentStatus("wrongScope");
        setTimeout(() => { setCurrentStatus("scan"); }, 2000);
        return;
      }
    
      setCurrentStatus("found");
      setTimeout(() => {
        window.location.href = qrCode;
      }, 2000);
    }
  }, []);

  return (
    <>
      <Header layout="transparent"/>
      <PageTransition scroll={false}>
        <Overlay>
          {currentStatus === "scan" && (
            <Message role="alert">
              <Icon className="spin"><SpinnerIcon/></Icon>{t("scan.pointCameraAtQrCode")}
            </Message>
          )}
          {currentStatus === "found" && (
            <Message role="alert">
              <Icon className="spin"><SpinnerIcon/></Icon>{t("scan.qrCodeFound")}
            </Message>
          )}
          {currentStatus === "wrongScope" && (
            <Message role="alert">
              <Icon><WarningIcon/></Icon>{t("scan.wrongScope")}
            </Message>
          )}
          {currentStatus === "wrongFormat" && (
            <Message role="alert">
              <Icon><WarningIcon/></Icon>{t("scan.wrongFormat")}
            </Message>
          )}
        </Overlay>
        <>
          {isActivePage && (
            <QrReader constraints={{ facingMode: 'environment' }} onResult={onScan} videoContainerStyle={videoContainerStyle} videoStyle={videoStyle}/>
          )}
        </>
      </PageTransition>
    </>
  );
}

export default React.memo(ScanView);
