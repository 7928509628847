import React, { FC, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import ArticleText from '../components/BlockText';
import ArticleTitle from '../components/BlockTitle';
import HeroArticle from '../components/HeroArticle';
import PageTransition from '../components/PageTransition';
import Header from '../framework/Header';

/**
 * Render the about page
 * @returns {JSX.Element} Component template
 */
const AboutView: FC = () => {
  const { t } = useTranslation();

  // Update title
  useEffect(() => {
    document.title = `${t("about.title")} - KulturPunkt`;
  }, [t]);

  return (
    <>
      <Header/>
      <PageTransition>
        <HeroArticle media={{url: "/img/kp_dump.jpg"}}/>
        <ArticleTitle level="h1" title={t("about.title")}/>
        <ArticleText text={t("about.body")}/>
      </PageTransition>
    </>
  );
}

export default React.memo(AboutView);
