import styled from "styled-components";

export const PlaceholderText = styled.div.attrs((p: any) => ({
  width: p.width || 100,
  height: p.height || "1em",
}))`
  aspect-ratio: 16 / 1;
  background: ${p => p.theme.placeholderColor};
  display: inline-block;
  width: -webkit-fill-available;
  height: 13px;
  border-radius: 2px;
  width: ${p => p.width}%;
  height: ${p => p.height};
`;