import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';

interface RedirectProps {
  url: string,
  languageCode: string
}

/**
 * Redirect URL and set language code. Used by router.
 * @returns {JSX.Element} Component template
 */
const Redirect: FC<RedirectProps> = ({url, languageCode}) => {
  const navigate = useNavigate();
  const { setCurrentLanguage } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      setCurrentLanguage(languageCode);
      navigate(url);
    }, 500);
  }, [url, languageCode, navigate, setCurrentLanguage]);

  return null;
}

export default Redirect;