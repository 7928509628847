import { FC, useContext } from 'react';
import styled from 'styled-components';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import useFetchMedia from '../hooks/useFetchMedia';

interface BlockDocumentItemProps {
  media: any
}

export const BlockDocumentsWrapper = styled.ul`
  margin: 0 0 30px 0;
  padding-left: 25px;
  float: left;
  width: 100%;
  display: block;
  list-style: none;

  &:empty {
    display: none;
  }
`;

const BlockDocument = styled.li`
  padding: 6px 0;

  & a {
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 30px;
    background-image: url('/img/icon_pdf.png');
    padding: 5px 0 5px 40px;
    word-break: break-all;
    display: block;
    min-height: 30px;
  }
`;

/**
 * Render a link block
 * @returns {JSX.Element} Component template
 */
 export const BlockDocumentItem: FC<BlockDocumentItemProps> = ({media}) => {
  const { currentLanguage } = useContext(AppContext);
  const [mediaObject] = useFetchMedia(media);

  return (
    <BlockDocument>
      <a href={`${mediaObject?.src}?filename=${mediaObject?.filename}`} target="_blank" rel="noreferrer" >{getTranslation(mediaObject?.title, currentLanguage) || mediaObject?.filename}</a>
    </BlockDocument>
  );
}