import { FC } from 'react';
import styled from 'styled-components';
import Logo from '../components/Logo'
import SearchBar from '../components/SearchBar';
import Menu from './Menu';
import FloatingAudioPlayer from '../components/FloatingAudioPlayer';
import { ReactComponent as AppStoreBadge } from "../assets/badge_appstore.svg";
import { ReactComponent as GooglePlayBadge } from "../assets/badge_googleplay.svg";

const MenuContainer = styled.nav`
  position: fixed;
  width: 320px;
  float: left;
  height: 100%;
  background: #fff;
  border-right: 1px solid ${p => p.theme.separatorColor};
  padding: 0 20px;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: .9em;
  display: flex;
  flex-direction: column;
`;

const LogoElement = styled(Logo)`
  width: 120px;
  height: 50px;
  margin: 30px 25px 10px;
`;

const Badges = styled.div`
  margin: 25px;
  & a {
    float: left;
    clear: both;
    margin-bottom: 5px;
  }
`;

/**
 * Render a wrapper for desktop displays, containing the menu
 * @returns {JSX.Element} Component template
 */
const DesktopMenu: FC = () => {

  return (
    <>
      <MenuContainer className="previewdisable">
        <LogoElement/>
        <SearchBar/>
        <Menu/>
 
        <Badges>
          <a tabIndex={-1} href="https://apps.apple.com/no/app/kulturpunkt/id1231337138" rel="noreferrer" target="_blank" aria-label="App Store"><AppStoreBadge/></a>
          <a tabIndex={-1} href="https://play.google.com/store/apps/details?id=com.kulturpunkt.kulturpunktapp" rel="noreferrer" target="_blank" aria-label="Google Play"><GooglePlayBadge/></a>
        </Badges>

        <FloatingAudioPlayer/>
      </MenuContainer>
    </>
  );
}

export default DesktopMenu;