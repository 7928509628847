import { FC } from 'react';
import { useTranslation } from "react-i18next";
import styled, { keyframes } from 'styled-components';
import packageJson from '../../package.json'
import Logo from '../components/Logo';
import Menu from './Menu';
import SearchBar from '../components/SearchBar';
import { ReactComponent as CloseSvg } from "../assets/icon_close_small.svg";

export interface SidebarProps {
  id?: string,
  isOpen: boolean,
  close: () => void,
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 34;
  background-color: rgba(0, 0, 0, .5);
`;

const WrapperEnterence = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0%); }
`;

const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 310px;
  background-color: #fff;
  color: #000;
  z-index: 35;
  animation: ${WrapperEnterence} 0.2s linear;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: -8px -1px 52px -4px rgba(0, 0, 0, .3);
`;

const LogoElement = styled(Logo)`
  margin: 25px 25px 30px;
  height: 20px;
  width: 100px;
  float: left;

  & path {
    fill: #000 !important;
  }
`

const CloseIcon = styled.button`
  cursor: pointer;
  float: right;
  margin: 20px 25px;
  position: relative;
  background: transparent;
  border: none;
  padding: 0;

  &&::before {
    content: "";
    position: absolute;
    padding: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & path {
    fill: #000 !important;
  }
`;

const VersionFooter = styled.span`
  font-size: .8em;
  position: fixed;
  right: 0;
  bottom: 0;
  border-top: 0.5px solid #EEE;
  text-align: right;
  padding: 15px 25px;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  width: 310px;
`

/**
 * Render the sidebar menu for mobile displays
 * @returns {JSX.Element} Component template
 */
const Sidebar: FC<SidebarProps> = ({id, isOpen, close}) => {
  const { t } = useTranslation();

  return (
    <>
      {isOpen && (
        <>
          <Backdrop className={isOpen ? "open" : "closed"} onClick={close}></Backdrop>
          <Wrapper id={id}>
            <LogoElement/><CloseIcon onClick={close} aria-label={t("presentation.close")}><CloseSvg/></CloseIcon>
            <SearchBar closeMenu={close}/>
            <Menu close={close}/>
            <VersionFooter>{`${t("menu.version")} ${packageJson.version}`}</VersionFooter>
          </Wrapper>
        </>
      )}
    </>
  )
}
export default Sidebar;