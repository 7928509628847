import { FC, useEffect, useState } from 'react';
import { Link as BreadcrumbLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ChevronRightIcon } from "../assets/chevron_right.svg";

interface BreadcrumbProps {
  path?: {link: string, title: string}[]
}

const BreadcrumbContainer = styled.div`
  width: 100%;
  margin: -5px 0;
  padding-top: 20px;
  width: 100%;
`;

const Link = styled(BreadcrumbLink)`
  font-size: .8em;
  color: #000;
  text-decoration: none;
  padding: 5px 0;
  cursor: pointer;

  &:visited {
    color: #000;
  }

  &:active {
    color: ${p => p.theme.accentColor};
  }
`;

const ChevronRight = styled(ChevronRightIcon)`
  width: 20px;
  height: 15px;
  margin-bottom: -3px;
`

/**
 * Render a breadcrumb path as a part of the header
 * @returns {JSX.Element} Component template
 */
const Breadcrumb: FC<BreadcrumbProps> = ({path}) => {
  const [breadcrumbText, setBreadcrumbText] = useState<any>();

  /**
   * Generate bredcrumb based on path
   */
  useEffect(() => {
    if (path) {
      const elements = path.map((page, i) => {
        return <Link to={page?.link} key={`link${i}`}>{page?.title}{i < path.length - 1 && <ChevronRight/>}</Link>
      });
      setBreadcrumbText(elements);
    }
  }, [path]);

  if (!path) { return null; }

  return (
    <BreadcrumbContainer>
      {breadcrumbText}
    </BreadcrumbContainer>
  );
}

export default Breadcrumb;