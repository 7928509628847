import { FC } from 'react';
import styled from 'styled-components';
import QuoteIcon from "../assets/icon_quote.svg";

interface BlockQuoteProps {
  quote?: string,
  byline?: string
}

const BlockContainer = styled.div`
  margin-bottom: 30px;
  padding: 0 25px;
  float: left;
  width: 100%;
  display: block;
`;

const Quote = styled.span`
  background-image: url(${QuoteIcon});
  background-repeat: no-repeat;
  background-position: 0px 5px;
  padding-left: 45px;
  min-height: 35px;
  display: block;
  font-style: italic;
`;

const Byline = styled.span`
  float: right;
`;

/**
 * Render a quote block
 * @returns {JSX.Element} Component template
 */
const BlockQuote: FC<BlockQuoteProps> = ({quote, byline}) => {

  return (
    <BlockContainer>
      <Quote>{quote}</Quote>
      {byline && byline.length > 0 && (<Byline>– {byline}</Byline>)}
    </BlockContainer>
  );
}

export default BlockQuote;