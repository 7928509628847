import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { parseCredits } from '../common/Media';
import { Gallery, Item } from 'react-photoswipe-gallery';
import PopupVideo from './LightboxModal_Video';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import 'photoswipe/dist/photoswipe.css'

interface LightboxWrapperProps {
  children: JSX.Element | JSX.Element[]
}

interface LightboxItemProps {
  media?: any,
  caption?: string,
  credits?: string,
  children: JSX.Element
}

/**
 * Render a lightbox wrapper
 * @returns {JSX.Element} Component template
 */
export const LightboxWrapper: FC<LightboxWrapperProps> = ({children}) => { 
  return (
    <Gallery withCaption>
      {children}
    </Gallery>
  );
}

/**
 * Render a lightbox item
 * @returns {JSX.Element} Component template
 */
export const LightboxItem: FC<LightboxItemProps> = ({media, children, credits, caption}) => {
  const { currentLanguage } = useContext(AppContext)
  const [mediaDimensions, setMediaDimensions] = useState<any>();
  const [mediaObject, setMediaObject] = useState<any>();

  // Put media in state to prevent rerenders
  useEffect(() => {
    if (!mediaObject) {
      setMediaObject(media);
    }
  }, [media, mediaObject]);

  /**
   * Get dimension of image
   * @param {string} src Image url
   * @returns {object} Width and height of image
   */
  const getImageDimensions = useCallback((src: string) => {
    return new Promise((resolve, reject) => {
      //resolve({ width: 600, height: 300 })
      const img = new Image()
      img.onload = () => resolve({width: img.width * 3, height: img.height * 3})
      img.onerror = reject
      img.src = src + "?dimension=167x167"
    })
  }, []);

  /**
   * Close popup by simulating click on close button
   */
   const closeModal = useCallback(() => {
    const closeButton = document.querySelector(".pswp__button--close") as HTMLButtonElement;
    closeButton && closeButton.click();
  }, []);

  // Get media dimensions due to demands from PhotoSwipe
  useEffect(() => {
    let isSubscribed = true;

    if (mediaObject?.url || mediaObject?.src) {
      getImageDimensions(mediaObject?.url || mediaObject?.src).then(object => {
        isSubscribed && setMediaDimensions(object);
      }).catch(() => {});
    }
    return () => { isSubscribed = false };
  }, [mediaObject, getImageDimensions]);

  if (!children || !currentLanguage) { return null; }

  // Render lightbox item
  switch(mediaObject?.media_type) {
    case "video":
      return (
        <Item content={<PopupVideo item={mediaObject} close={closeModal}/>}>
          {({ ref, open }) => (
            <>
              {React.cloneElement(children, { ref: ref as React.RefObject<HTMLLIElement>, onClick: open, onKeyDown: (e: any) => { e?.code === "Enter" && open(e); }})}
            </>
          )}
        </Item>
      );

    case "image":
    case "dm":
      return (
        <Item original={(mediaObject?.url || mediaObject?.src) + "?dimension=max"} thumbnail={(mediaObject?.url || mediaObject?.src) + "?dimension=167x167"} width={mediaDimensions?.width * 3|| 0} height={mediaDimensions?.height * 3|| 0} caption={`<strong>${getTranslation(mediaObject?.title, currentLanguage)}</strong><br/>${getTranslation(mediaObject?.description, currentLanguage)}<br/><br/>${credits || parseCredits(mediaObject?.credits)}`}>
          {({ ref, open }) => (
            <>
              {React.cloneElement(children, { ref: ref as React.RefObject<HTMLLIElement>, onClick: open, onKeyDown: (e: any) => { e?.code === "Enter" && open(e); }})}
            </>
          )}
        </Item>
      );

      case "youtube":
      case "vimeo":
      case "sketchfab":
        return (
          <Item html={`<iframe width="80%" height="80%" style="max-height: 80%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); border: none;" src="${mediaObject?.src}" poster="${mediaObject?.thumbUrl}"/>`} caption={`<strong>${getTranslation(mediaObject?.title, currentLanguage)}</strong><br/>${parseCredits(mediaObject?.credits)}`}>
            {({ ref, open }) => (
              <>
                {React.cloneElement(children, { ref: ref as React.RefObject<HTMLLIElement>, onClick: open, onKeyDown: (e: any) => { e?.code === "Enter" && open(e); }})}
              </>
            )}
          </Item>
        );

    default:
      return (
        <>
          {children}
        </>
      );
  }
}