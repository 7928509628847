import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { getTranslation } from '../common/Translation';
import { ReactComponent as PlayIconSvg } from "../assets/icon_play.svg";
import { ReactComponent as StopIcon } from "../assets/icon_stop.svg";
import useFetchMedia from '../hooks/useFetchMedia';
import { useTranslation } from 'react-i18next';

interface BlockAudioWrapperProps {
  children?: JSX.Element | JSX.Element[]
}

interface BlockAudioItemProps {
  media?: any,
  layout?: "wide" | "block",
  children?: JSX.Element | JSX.Element[]
}

const BlockContainer = styled.ul`
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0 25px;
  float: left;
  width: 100%;
  display: block;
  gap: 15px;
  display: flex;
  flex-direction: column;

  &:empty {
    display: none;
  }
`;

const AudioItem = styled.li`
  float: left;
  margin: 0;
  padding: 20px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #E8EAED;
  background-color: ${p => p.theme.backgroundColor};

  &.block {
    width: 150px;
    min-width: 150px;
    height: 150px;
    justify-content: center;
    position: relative;

    & button::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 70px;
    }

    & span, & div {
      display: none;
    }
  }
`;

const Caption = styled.span`
  --max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;


const Button = styled.button`
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 17.5px;
  background-color: ${p => p.theme.accentColor};
  border: none;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:active {
    opacity: .8;
  }
`;

const PlayIcon = styled(PlayIconSvg)`
  margin-left: 3px;
  height: 15px;
  width: 15px;
`

const Control = styled.div`
  width: 100%;
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: #B9BDC3;
  height: 4px;
  border-radius: 2px;
  margin-top: 8px;
  position: relative;

  &::before {
    width: 100%;
    content: "";
    position: absolute;
    padding: 30px 0;
    transform: translateY(-50%);
  }
`;

const Progress = styled.div`
  width: 0;
  height: 4px;
  border-radius: 2px;
  background-color: ${p => p.theme.accentColor};
  transition: width .1s;
  pointer-events: none;
`;

/**
 * Render an audio block container
 * @returns {JSX.Element} Component template
 */
export const BlockAudioWrapper: FC<BlockAudioWrapperProps> = ({children}) => {
  return (
    <BlockContainer>{children}</BlockContainer>
  );
}



/**
 * Render an audio block item
 * @returns {JSX.Element} Component template
 */
export const BlockAudioItem: FC<BlockAudioItemProps> = ({media, layout, children}) => {
  const { audioPlayerStats, setAudioPlayerStats, isDesktop, currentLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const [playbackPosition, setPlaybackPosition] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioElement = document.getElementsByTagName("audio")[0];
  const [mediaObject] = useFetchMedia(media);

  /**
   * Toggle audio placback
   */
  const togglePlay = useCallback(() => {
    if (isPlaying) {
      setAudioPlayerStats({
        active: false,
        url: mediaObject?.url,
        caption: getTranslation(mediaObject?.title, currentLanguage)
      });      
    } else {
      setAudioPlayerStats({
        active: true,
        url: mediaObject?.url,
        caption: getTranslation(mediaObject?.title, currentLanguage)
      });
    
      // Ugly hack to avoid Safari playback restrictions on iOS
      audioElement?.setAttribute("src", String(mediaObject?.url));
      audioElement?.play();

    }
  }, [isPlaying, mediaObject, setAudioPlayerStats, audioElement, currentLanguage]);

  // Set as being active if the current URL is being played
  useEffect(() => {
    if (audioPlayerStats?.url === mediaObject?.url) {
      setIsPlaying(Boolean(audioPlayerStats?.active));
    } else {
      setIsPlaying(false);
    }
  }, [audioPlayerStats, mediaObject?.url]);

  // Listen for audio player time change
  useEffect(() => {
    audioElement?.addEventListener("timeupdate", e => {
      setPlaybackPosition(Number(audioElement?.currentTime / audioElement?.duration) * 100);
    })
  }, [audioElement]);

  const updatePosition = (e) => {
    if (isPlaying) {
      const boundingRect = e.target.getBoundingClientRect();
      const currentTime = Number(((e?.clientX - boundingRect?.left) / e?.target?.offsetWidth) * 100);
      document.getElementsByTagName("audio")[0].currentTime = audioElement?.duration * currentTime / 100; 
    }
  }

  return (
    <AudioItem className={`${isDesktop ? "desktop" : "mobile"} ${layout && layout}`}>
      <Button onClick={togglePlay} aria-label={isPlaying? t("media.play") : t("media.stop")}>{isPlaying ? (<StopIcon/>) : (<PlayIcon/>)}</Button>
      <Control>
        {mediaObject?.title && (<Caption>{getTranslation(mediaObject?.title, currentLanguage)}</Caption>)}
        <ProgressBar onClick={updatePosition}><Progress style={{width: `${playbackPosition}%`}}/></ProgressBar>
      </Control>
      {children}
    </AudioItem>
  );
}