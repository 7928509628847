import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import nb_noCommon from "./nb_no/common.json";
/*import nn_noCommon from "./nn_no/common.json";*/
import enCommon from "./en/common.json";
import frCommon from "./fr/common.json";
import esCommon from "./es/common.json";
import deCommon from "./de/common.json";
import svCommon from "./sv/common.json";
import plCommon from "./pl/common.json";
import LanguageDetector from "i18next-browser-languagedetector";

/**
 * I18n language configuration settings
 */
export const resources = {
  no: { common: nb_noCommon },
  /**
  nb_no: { common: nb_noCommon },
  nn_no: { common: nn_noCommon },
   */
  sv: { common: svCommon },
  en: { common: enCommon },
  de: { common: deCommon },
  fr: { common: frCommon },
  es: { common: esCommon },
  pl: { common: plCommon }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    react: {
      useSuspense: false,
    },
    fallbackLng: {
      'default': ['no']
    },
    ns: ["common", "db"],
    defaultNS: "common",
    lowerCaseLng: true,
    cleanCode: true,
    resources,
  });

export default i18n;
