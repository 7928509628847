import { useEffect, useState } from "react"
import Api from "../services/Api"

/**
 * Fetch owner from the API
 * @param {number|string|undefined} id Owner ID 
 * @param {object} previewData Preview object
 * @returns {object} Owner object
 */
export default function useFetchOwner(id: number|string|undefined, previewData?: any) {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isSubscribed = true;
    setIsLoading(true);
    setData({});

    Api.getOwner(String(id)).then(document => {
      if (isSubscribed) {
        setData(document);
        setIsLoading(false);
      }
    }).catch(e => { 
      setError(e); 
    });

    return () => { isSubscribed = false }
  }, [id]);

  if (previewData) { 
    return [{content: previewData}, null]; 
  }

  if (!id) {
    return [null, null];
  }

  return [ data, isLoading, error ]
}