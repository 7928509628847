import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DistanceIcon } from "../assets/icon_distance_black.svg";
import { calculateDistance } from '../common/Location';
import { LocationContext } from '../contexts/LocationContext';

interface LabelDistanceProps {
  layout?: "light" | "grey",
  location?: {lat: number, lng: number},
  onClick?: () => void,
  children: JSX.Element | JSX.Element[],
  className?: string
}

const Container = styled.button`
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 40px;
  height: 36px;
  border: 2px solid ${p => p.theme.backgroundColor};
  display: inline-flex;
  align-items: center;
  margin: -10px 25px 10px;
  padding: 0;
  cursor: pointer;
  font-size: 1em;

  &:active {
    opacity: .9;
  }

  &.light {
    border-color: #fff;
    background-color: #F0F2F5;

    & > div {
      border-color: #fff;
      background-color: #E3E7EC;
    }
  }
`;

const MarkerCircle = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  border: 2px solid ${p => p.theme.backgroundColor};
`;

const Marker = styled(DistanceIcon)`
  width: 12px;
  height: 14px;

  & path {
    fill: ${p => p.theme.accentColor};
  }
`

const Text = styled.span`
  font-size: .9em;
  padding: 7.5px 15px 7.5px 5px;
  color: #000;
`;

const Distance = styled.span`
  padding-right: 10px;
  border-right: 1px solid #ccc;
  margin-right: 10px;
`;

/**
 * Render a distance element
 * @returns {JSX.Element} Component template
 */
const Button: FC<LabelDistanceProps> = ({children, layout, location, onClick, className}) => {
  const { geoLocation } = useContext(LocationContext);
  const [distance, setDistance] = useState<string|null>(null);

  // Calculate distance - if needed
  useEffect(() => {
    if (geoLocation && location) {
      const calculatedDistance = calculateDistance([geoLocation?.lat, geoLocation?.lng], [location?.lat, location?.lng])
      setDistance(calculatedDistance);
    }
  }, [geoLocation, location]);

  return (
    <Container onClick={onClick} role="button" tabIndex={0} className={`${layout} ${className ? className : ""}`} aria-label={String(children)}>
      <MarkerCircle><Marker/></MarkerCircle>
      <Text>
        {distance && (<Distance>{distance}</Distance>)}
        {children}
      </Text>
    </Container>
  );
}

export default Button;