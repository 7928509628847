import { FC } from 'react';
import styled from 'styled-components';
import Markdown from 'marked-react';
import WarningIcon from '../assets/icon_warning.svg';

interface BlockErrorProps {
  text: string
}

const BlockContainer = styled.div`
  margin: 0 25px 30px;
  padding: 15px 0 15px 55px;
  float: left;
  width: calc(100% - 50px);
  display: block;
  color: #fff;
  background-color: #ff3333;
  background-image: url(${WarningIcon});
  background-position: 20px 50%;
  background-repeat: no-repeat;
  border-radius: 5px;
`;

/**
 * Render an error message. Supports markdown
 * @returns {JSX.Element} Component template
 */
const BlockError: FC<BlockErrorProps> = ({text}) => {  
  return (
    <BlockContainer><Markdown>{text}</Markdown></BlockContainer>
  );
}

export default BlockError;