import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useId } from "@reach/auto-id";
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';
import Sidebar from './MobileSidebar';
import Logo from '../components/Logo';
import Breadcrumb from '../components/Breadcrumb';
import { ReactComponent as BackIcon } from "../assets/icon_back.svg";
import { ReactComponent as MenuIcon } from "../assets/icon_searchmenu.svg";
import { useTranslation } from 'react-i18next';

export interface HeaderProps {
  layout?: "full" | "margin" | "transparent",
  showProgress?: boolean,
  breadcrumb?: {link: string, title: string}[]
}

const HeaderElement = styled.header`
  background-color: ${p => p.theme.headerBackgroundColor};
  width: 100%;
  position: relative;
  z-index: 20;
  padding: 15px 25px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: inset 0px -0.5px 0px #CCCCCC;

  &.full {
    min-height: 110px;
  }

  &.margin {
    margin-bottom: 30px;
  }

  &.transparent {
    position: absolute;
    background-color: transparent;
    box-shadow: none;

    & .progress {
      top: 0;
    }

    & svg path {
      fill: #fff;
    }
  }
`;

const LogoElement = styled(Logo)`
  margin: 4px 0 0;
  height: 22px;
  width: 106px;
`

const Button = styled.button`
  cursor: pointer;
  position: absolute;
  top: 22px;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 5px;

  &.left {
    left: 25px;
  }

  &.right {
    right: 25px;
  }

  &&::before {
    content: "";
    position: absolute;
    padding: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
`

const progressAnimation = keyframes`
  from { left: -30%; }
  to { left: 100%; }
`

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;

  &.desktop {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  &::before {
    position: absolute;
    content: "";
    width: 30%;
    height: 4px;
    background-color: ${p => p.theme.accentColor};
    border-radius: 2px;
    animation: ${progressAnimation} 1s infinite;
  }
`

/**
 * Render the app header
 * @returns {JSX.Element} Component template
 */
const Header: FC<HeaderProps> = ({layout, showProgress, breadcrumb}) => {
  const navigate = useNavigate();
  const { isDesktop } = useContext(AppContext);
  const { t } = useTranslation();
  const menuId = useId()
  const [menuOpen, setMenuOpen] = useState(false);

  /**
   * Go back in history
   */
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  /**
   * Returns true if current page is the home page
   */
  const showBackButton = useMemo(() => {
    return (window.location?.pathname === "/") && document?.referrer !== window?.origin;
  }, []);

  if (isDesktop) { return (
    <>{showProgress && (<ProgressBar role="progressbar" className="progress desktop" aria-label={t("progress.loading")}/>)}</>
  )}

  return (
    <>
      <HeaderElement role="banner" className={`${layout || ""} previewdisable`}>
        <LogoElement/>
        {!showBackButton && (<Button className="left" aria-label={t("navigation.goBack")} aria-expanded={menuOpen ? "true" : "false"} onClick={goBack}><BackIcon/></Button>)}
        <Button className="right" aria-haspopup="true" aria-expanded={menuOpen ? "true" : "false"} aria-controls={menuId} aria-label={t("navigation.showMenu")} onClick={() => {setMenuOpen(true)}}><MenuIcon/></Button>
        <Breadcrumb path={breadcrumb} />
        {showProgress && (<ProgressBar role="progressbar" className="progress"/>)}
      </HeaderElement>
      <Sidebar id={menuId} isOpen={menuOpen} close={() => {setMenuOpen(false)}}/>
    </>
  );
}

export default Header;