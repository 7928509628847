import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Marker } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet'
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppContext';
import { LocationContext } from '../contexts/LocationContext';
import { trackOwner } from '../common/Analytics';
import Api from '../services/Api';
import ErrorMessage from '../components/ErrorMessage';
import Map from '../components/Map';
import { SCHEMA_PRESENTATION } from '../Settings';

interface MapProps {
  active: boolean,
  setIsLoading?: any
}

// Define icons
const ownerMarker = (id) => L.divIcon({ className: `museumMarker m${id}`, iconSize: [40, 50], iconAnchor: [20, 50] });
const kpMarker = (id) => L.divIcon({ className: `kpMarker a${id}`, iconSize: [40, 50], iconAnchor: [20, 50] });

/**
 * Render a map
 * @returns {JSX.Element} Component template
 */
const ExploreMap: FC<MapProps> = ({active, setIsLoading}) => {
  const startPosition: LatLngExpression = useMemo(() => { return [65, 17] }, []);
  const { t } = useTranslation();
  const { search } = useLocation();
  const { ownerFilter } = useContext(AppContext);
  const { geoLocation } = useContext(LocationContext);
  const [map, setMap] = useState<any>(null);
  const [markersAreLoaded, setMarkersAreLoaded] = useState<any>(null);
  const [mapIsCentered, setMapIsCentered] = useState<boolean>(false);
  const [mapIsLoaded, setMapIsLoaded] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<{id: number, type: "article" | "museum"}|undefined>();
  const [activeMarkerId, setActiveMarkerId] = useState<string>();
  const [articleList, setArticleList] = useState<any>();
  const [ownerList, setOwnerList] = useState<any>();
  const [error, setError] = useState<any>();

  /**
   * Fetch articles from API
   */
  const loadArticles = useCallback(() => {
    let isSubscribed = true;
    if (active && !articleList) {
      setArticleList({});
      setIsLoading(true);
      Api.getMapDocumentList({ location: geoLocation, owner_id: ownerFilter?.id, referenced_from_schema_id: SCHEMA_PRESENTATION }).then(docList => {
        if (isSubscribed) {
          setArticleList(docList?.items);
        }
      }).catch(e => {
        setError(e);
      });
    }
    return () => { isSubscribed = false; }
  }, [geoLocation, articleList, setIsLoading, active, ownerFilter?.id]);

  /**
   * Fetch owners from API
   */
  const loadOwners = useCallback(() => {
    let isSubscribed = true;
    if (active && !ownerList) {
      setOwnerList({});
      Api.getMapOwnerList({ location: geoLocation }).then(docList => {
        if (isSubscribed) {
          setTimeout(() => { isSubscribed && setOwnerList(docList?.items); }, 1000, docList, isSubscribed);
        }
      }).catch(e => {
        setError(e);
      });
    }
    return () => { isSubscribed = false; }
  }, [geoLocation, ownerList, active]);

  // Get data from the API
  useEffect(() => {
    if (geoLocation && active) {
      loadArticles();
      loadOwners();
    }

    // Add "map" class to #viewport element to make sure the map is displayed in full screen on desktop browsers
    if (active) {
      document.getElementById("viewport")?.classList.add("map");
    } else {
      document.getElementById("viewport")?.classList.remove("map");
    }
  }, [geoLocation, active, loadArticles, loadOwners]);

  // Pan to specified coordinates from querystring - if specified
  useEffect(() => {
    const params = new URLSearchParams(search);
    const pos = params.get('pos')?.split(",");
    const zoom = params.get('zoom');
    const markerId = params.get('id');
    
    // Position and marker id is retrieved from querystring
    if (typeof pos === "object" && pos.length === 2 && zoom) {
      setMapIsCentered(true);
      setActiveMarkerId(markerId || undefined);
      map?.setView([Number(pos?.[0]) || startPosition[0], Number(pos?.[1]) || startPosition[1]], Number(zoom) || 5);
    }
  }, [map, search, startPosition]);

  // Set marker as active - if set in querystring
  useEffect(() => {
    if (activeMarkerId && markersAreLoaded) {
      document.querySelector(`.${activeMarkerId}`)?.classList.add("active");

      const popupId = Number(activeMarkerId?.replace(/\D/g,''));
      if (activeMarkerId.charAt(0) === "m") {
        setPopupData({id: popupId, type: "museum"});
      } else {
        setPopupData({id: popupId, type: "article"});
      }
    }
  }, [activeMarkerId, markersAreLoaded, setPopupData]);

  /**
  * User clicks a marker
  * @returns {JSX.Element} Component template
  */
  const onMarkerClick = useCallback((e: any, id: number, markerType: "article" | "museum") => {
    // Is the event a keyboard event? Only listen for enter and space keys
    if (e?.type === "keydown") {
      if (!(e?.originalEvent?.key === "Enter" || e?.originalEvent?.key === " ")) {
        return;
      }
    }
    
    // Unfocus all markers
    const markerList = document.querySelectorAll(".leaflet-marker-icon");
    markerList.forEach(item => {
      item?.classList?.remove("active");
    });
    setPopupData(undefined);

    // Focus selected marker and display popup
    e?.target?._icon?.classList?.add("active");
    setPopupData({id: id, type: markerType});
  }, []);

  useEffect(() => {
    if (markersAreLoaded) {
      setIsLoading(false);
    }
  }, [markersAreLoaded, setIsLoading]);

  /**
  * Render article markers
  * @returns {JSX.Element} Component template
  */
   const renderArticleMarkers = useMemo(() => {
    if (!mapIsLoaded || !articleList) { return; }

    // Article markers
    if (Symbol.iterator in Object(articleList)) {
      const markerList = articleList?.map((marker: any, i: number) => {
        return <Marker key={`marker${i}`} position={marker?.location} title={marker?.name} icon={kpMarker(marker?.id)} eventHandlers={{ click: e => { onMarkerClick(e, marker?.id, "article"); }, keydown: e => { onMarkerClick(e, marker?.id, "article"); }}}/>
      })
      setMarkersAreLoaded(true); 
      return markerList;
    }

    return null;
  }, [articleList, mapIsLoaded, onMarkerClick]);

  /**
  * Render owner markers
  * @returns {JSX.Element} Component template
  */
   const renderOwnerMarkers = useMemo(() => {
    if (!mapIsLoaded || !ownerList) { return; }

    // Owner markers
    if (Symbol.iterator in Object(ownerList)) {
      const markerList = ownerList?.map((marker: any, i: number) => {
        return <Marker key={`marker${i}`} position={marker?.location} title={marker?.name} icon={ownerMarker(marker?.id)} eventHandlers={{ click: e => { onMarkerClick(e, marker?.id, "museum"); }, keydown: e => { onMarkerClick(e, marker?.id, "museum"); }}}/>
      })
      return markerList;
    }
    return null;
  }, [ownerList, mapIsLoaded, onMarkerClick]);

  // Analytics tracker
  useEffect(() => {
    trackOwner(ownerFilter?.analyticsId);
  }, [ownerFilter?.analyticsId]);

  // Display error - if data could not be fetched
  if (error && active) {
    setIsLoading(false);
    return <ErrorMessage message={t(error?.message)} tryAgain={true}/>;
  }

  if (!active && !articleList) {
    return null;
  }

  return (
    <>
    <Map active={active} cluster={true} popupData={popupData} setPopupData={setPopupData} setMapIsLoaded={setMapIsLoaded} map={map} setMap={setMap} mapIsCentered={mapIsCentered} setMapIsCentered={setMapIsCentered} panCurrentLocation={true}>
      <>
        {renderArticleMarkers}
        {renderOwnerMarkers}
      </>
    </Map>
    </>
  );
}
export default ExploreMap;