import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import FastAverageColor from 'fast-average-color';
import { AppContext } from '../contexts/AppContext';
import { LocationContext } from '../contexts/LocationContext';
import { LightboxWrapper, LightboxItem } from './LightboxModal'
import { ReactComponent as FullscreenIcon} from "../assets/icon_fullscreen.svg";
import { ReactComponent as ShareIcon } from "../assets/icon_share.svg";
import { calculateDistance } from '../common/Location';
import Image from './Image';
import LanguageNotice from './LanguageNotice';
import DistanceIcon from "../assets/icon_distance_white.svg";
import useFetchMedia from '../hooks/useFetchMedia';
import { useTranslation } from 'react-i18next';

interface ArticleHeaderProps {
  layout?: "normal" | "transparent" | "noMargin",
  media?: any,
  title?: any,
  location?: {lat: number, lng: number},
  avatar?: string,
  fullscreenButton?: boolean,
  shareButton?: boolean,
  languageNotice?: boolean,
}

const HeaderWrapper = styled.div`
  &.noMargin {
    margin-bottom: 0 !important;
  }

  &.desktop {
    padding: 10px;
    background-color: ${p => p.theme.placeholderColor};
    margin-bottom: 20px;
    transition: background-color .2s;

    & > div {
      width: 450px;
      height: 300px;
      background-color: inherit;
      border-radius: 4px;
    }

    & figure {
      border-radius: 4px;
      overflow: hidden;
    }
  }
`;

const Header = styled.div.attrs((p: any) => ({
  moveDown: p.moveDown || false,
  hasAvatar: p.hasAvatar || false
}))`
  width: 100%;
  display: block;
  height: 330px;
  position: relative;
  background-color: #333;
  margin: 0 auto 50px;
  margin-bottom: ${p => p.moveDown ? "50px" : p.hasAvatar ? "0" : "0"}; // 20px?
  transition: margin 0.5s;
  max-width: 1200px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 60%,rgba(0,0,0,0.4) 100%);
  }

  &.transparent::after {
    background: linear-gradient(rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 60%,rgba(0,0,0,0.4) 100%);
  }

  & > div {
    bottom: ${p => p.moveDown ? "-75px" : "20px"};
  }
`;

const Img = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Metadata = styled.div`
  position: absolute;
  z-index: 30;
  bottom: 20px;
  padding: 0 25px;
  width: 100%;
  transition: bottom 0.5s;
  pointer-events: none;
`;

const Distance = styled.span.attrs((p: any) => ({
  languageNotice: p.languageNotice || false,
}))`
  color: #fff;
  font-size: 0.9em;
  font-weight: bold;
  position: absolute;
  bottom: ${p => p.languageNotice ? "125px" : "0"};

  background-image: url(${DistanceIcon});
  background-position: 0 50%;
  background-repeat: no-repeat;
  border-radius: 3px;
  padding: 5px 10px 5px 25px;
  display: block;
  float: left;
`;

const ToolbarButton = styled.button`
  background: transparent;
  width: 35px;
  height: 30px;
  float: right;
  border: none;
  margin-left: 10px;
  -webkit-tap-highlight-color: ${p => p.theme.accentColor}20;
  cursor: pointer;
  position: relative;
  pointer-events: all;
  border-radius: 5px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 20px;
  }
`;

const Avatar = styled(Image)`
  & img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    border: 3px solid #fff;
    position: absolute;
    overflow: hidden;
  }
`;

/**
 * Render an article header
 * @returns {JSX.Element} Component template
 */
const ArticleHeader: FC<ArticleHeaderProps> = ({layout, media, title, location, avatar, fullscreenButton, shareButton, languageNotice}) => {  
  const { isDesktop } = useContext(AppContext);
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { geoLocation } = useContext(LocationContext);
  const [languageNoticeOpen, setLanguageNoticeOpen] = useState<boolean>(languageNotice || false)
  const [mediaObject] = useFetchMedia(media);

  /**
   * Share URL
   */
   const shareUrl = useCallback(async () => {
    const shareData = {
      title: "KulturPunkt",
      text: document.title,
      url: window.location.href
    }

    try {
      await navigator.share(shareData);
    } catch(err) {
      console.error("Web share error");
    }
  }, []);

  /**
   * Check if Web Share API is available
   */
   const canShare = useMemo(() => {
    return Boolean(navigator && navigator.share);
  }, []);

  // Should the missing translation notice be displayed? 
  useEffect(() => {
    setLanguageNoticeOpen(Boolean(languageNotice));
  }, [languageNotice]);

  // Set average color background
  useEffect(() => {
    if (!isDesktop || !mediaObject?.src) { return; }

    const fac = new FastAverageColor();
    fac.getColorAsync(`${mediaObject?.src}?dimension=167x167`).then(color => {
      if (wrapperRef.current) {
        wrapperRef.current.style.backgroundColor = color.rgba;
      }
    });
  }, [isDesktop, mediaObject]);

  return (
    <HeaderWrapper className={(isDesktop ? "desktop" : "mobile") + " " + layout} ref={wrapperRef}>
      <Header moveDown={languageNoticeOpen} hasAvatar={Boolean(avatar)}>
        <Img media={mediaObject} alt={title || undefined} usePlaceholder={true}/>
        <Metadata role="toolbar">
          {(geoLocation?.lat && location?.lat) && (<Distance languageNotice={languageNoticeOpen}>{calculateDistance([geoLocation?.lat, geoLocation?.lng], [location?.lat, location?.lng])}</Distance>)}
          {avatar && (
            <Avatar media={avatar}/>
          )}
          {fullscreenButton && mediaObject?.url && (
            <LightboxWrapper>
              <LightboxItem media={mediaObject}>
                <ToolbarButton aria-label={t("navigation.fullscreen")}><FullscreenIcon/></ToolbarButton>
              </LightboxItem>
            </LightboxWrapper>
          )}
          {shareButton && canShare && (<ToolbarButton onClick={shareUrl} aria-label={t("navigation.share")}><ShareIcon/></ToolbarButton>)}
          {languageNoticeOpen && (<LanguageNotice setIsOpen={setLanguageNoticeOpen}/>)}
        </Metadata>
      </Header>
    </HeaderWrapper>
  );
}

export default ArticleHeader;