import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SearchIcon from "../assets/input_search.svg";

interface SearchBarProps {
  inputRef?: any,
  className?: string,
  closeMenu?: () => void
}

const SearchBarElement = styled.input`
  padding: 8px 20px;
  width: calc(100% - 50px);
  border: none;
  border-radius: 6px;
  margin: 10px 25px 20px;
  font-size: .9em;
  background: #F4F4F4 url(${SearchIcon}) calc(100% - 20px) 50% no-repeat;
  -webkit-user-select: text;

  &::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration { display: none; }
  &::-ms-clear, &::-ms-reveal { display: none; width : 0; height: 0; }
`

/**
 * Render a search bar
 * @returns {JSX.Element} Component template
 */
const SearchBar: FC<SearchBarProps> = ({inputRef, className, closeMenu}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**
   * Handle submittion of search form
   * @param {object} e Event
   */
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    navigate(`/search?q=${e?.target?.elements?.q?.value}`);

    if (closeMenu) { closeMenu(); }
  }, [navigate, closeMenu]);

  return (
    <form method="GET" onSubmit={handleSubmit} role="search">
      <SearchBarElement ref={inputRef} type="search" name="q" className={className || undefined} placeholder={`${t("search.placeholder")}..`} spellCheck="false"/>
    </form>
  );
}

export default SearchBar;