import i18n from "i18next";
import { FC, useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter, useLocation } from 'react-router-dom';
import styled, { ThemeProvider } from "styled-components";
import packageJson from '../package.json';
import { track } from "./common/Analytics";
import { deleteCookie, getCookie, setCookie } from "./common/Cookies";
import { initGPS } from "./common/Location";
import CookiePopup from "./components/CookiePopup";
import FilterHeader from "./components/FilterHeader";
import { AppContext } from "./contexts/AppContext";
import { LocationContext } from "./contexts/LocationContext";
import DesktopMenu from "./framework/DesktopMenu";
import MobileTabbar from "./framework/MobileTabbar";
import AppRoutes from "./Routes";
import { KULTURIO_ENV } from "./Settings";


const ContentWrapper = styled.div`
  @media (min-width: 1000px) {
    width: calc(100% - 320px);
    min-height: 100vh;
    float: right;
  }
`;

const Content = styled.div`
  @media (min-width: 1000px) {
    max-width: 1280px;
    min-height: 100vh;
    margin: 0 auto;
    position: relative;
    padding: 50px;
    overflow-x: hidden;
    background-color: ${p => p.theme.backgroundColor};

    &.noScroll {
      overflow: hidden;
    }

    &.map {
      max-width: 100%;
    }
  }
`;

/**
 * Adjust application height every time the window is resized. Necessary on iOS and Android.
 */
const adjustAppHeight = () => {
  document?.documentElement?.style?.setProperty('--app-height', `${window?.innerHeight}px`);
}

/**
 * App
 * @returns {JSX.Element} Component template
 */
const App = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });

  // AppContext states
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n?.resolvedLanguage);
  const [currentFontSize, setCurrentFontSize] = useState<number>(1);
  const [previewIsActive, setPreviewIsActive] = useState<boolean>(false);
  const [geoLocation, setGeoLocation] = useState();
  const [ownerFilter, setOwnerFilter] = useState<{filter: boolean, id: number, name: string, analyticsId: string}>();
  const [presentationCache, setPresentationCache] = useState();
  const [audioPlayerStats, setAudioPlayerStats] = useState<{ active?: boolean, url?: string, caption?: string }>();
  const [theme, ] = useState({
    backgroundColor: "#f0f2f5",
    headerBackgroundColor: "#ffffff",
    textColor: "#000000",
    textSecondaryColor: "#767676",
    darkAccentColor: "#10518F",
    accentColor: "#397AB5",
    weakAccentColor: "#bad4f3",
    separatorColor: "#e8eaed",
    placeholderColor: "#dddddd",
    switchBackgroundColor: "#dfe3e9"
  });

  // Update i18n language on user update
  useEffect(() => {
    if (currentLanguage !== i18n?.resolvedLanguage) {
      setCookie("language", currentLanguage);
      i18n?.changeLanguage(currentLanguage);
    }
  }, [currentLanguage]);

  // Setup theme colors on startup
  useEffect(() => {
    document.body.style.backgroundColor = theme.backgroundColor;
    document.body.style.color = theme.textColor;
  }, [theme]);

  // Catch data from cookies on startup
  useEffect(() => {
    const languageCookie = getCookie("language");
    if (languageCookie) { setCurrentLanguage(languageCookie); }

    const fontSizeCookie = getCookie("fontsize");
    if (fontSizeCookie) { setCurrentFontSize(Number(fontSizeCookie)); }

    try {
      if (getCookie("checkedin")) {
        const checkedIn = JSON.parse(String(getCookie("checkedin")) || "null");
        if (checkedIn) { setOwnerFilter(checkedIn || undefined); }
      }
    } catch(e) {
        console.error("Could not parse owner filter from cookie.");
    }
  }, []);

  // Update text size
  useEffect(() => {
    if (currentFontSize === 1) {
      document.body.style.fontSize = "1em";
    } else {
      document.body.style.fontSize = "1.2em";
    }

    setCookie("fontsize", String(currentFontSize) || "1");
  }, [currentFontSize]);

  // Ask for GPS location data on startup
  useEffect(() => {
    initGPS(setGeoLocation);
  }, []);

  // Force height adjustments on browser resize
  useEffect(() => {
    window?.addEventListener('resize', adjustAppHeight)
    adjustAppHeight();
  }, []);

  useEffect(() => {
    if (previewIsActive) {
      document.body.classList.add("preview");
    }
  }, [previewIsActive]);

  // Save checked in status to cookie
  useEffect(() => {
    if (ownerFilter) {
      setCookie('checkedin', JSON.stringify(ownerFilter));
    } else {
      deleteCookie('checkedin');
    }
  }, [ownerFilter]);

  // Display app version in browser console on startup
  useEffect(() => {
    console.log(`%c _____       _  _              _____            _    _   \n|  |  / _ _ | || |_  _ _  ___ |  _  | _ _  ___ | |_ | |_ \n|    -|| | || ||  _|| | ||  _||   __|| | ||   || '_||  _|\n|__|__||___||_||_|  |___||_|  |__|   |___||_|_||_,_||_|  \n                                                         \n%cKulturPunkt v${packageJson?.version}\n%cDeveloped by KulturIT AS\nEnvironment: ${KULTURIO_ENV}`, `color: blue; font-size: .7em; font-weight: bold;`, `font-weight: bold`, ``);
  }, []);

  return (
    <AppContext.Provider value={{ theme, currentLanguage, currentFontSize, isDesktop, ownerFilter, presentationCache, audioPlayerStats, setCurrentLanguage, setCurrentFontSize, previewIsActive, setPreviewIsActive, setOwnerFilter, setPresentationCache, setAudioPlayerStats }}>
      <LocationContext.Provider value={{ geoLocation, setGeoLocation }}>
        <ThemeProvider theme={theme}>  
          <BrowserRouter>
            <FilterHeader/>
            {isDesktop && (<DesktopMenu/>)}
            <ContentWrapper>
              <Content id="viewport">
                <AppRoutes/>
              </Content>
            </ContentWrapper>
            {!isDesktop && (<MobileTabbar/>)}
            <Analytics/>
            <CookiePopup/>
          </BrowserRouter>
        </ThemeProvider>
      </LocationContext.Provider>
    </AppContext.Provider>
  );
}

export default App;

/**
 * Track every page view using
 */
const Analytics: FC = () => {
  const location = useLocation();

  // Google Analytics - track every page
  useEffect(() => {
    track();
  }, [location]);
  return null;
}