import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { AppContext } from '../contexts/AppContext';
import { getFallbackLanguage, getTranslation, isSiblingLanguage } from '../common/Translation';
import BlockTitle from '../components/BlockTitle';
import BlockPreface from '../components/BlockPreface';
import BlockPublisher from '../components/BlockPublisher';
import HeroArticle from '../components/HeroArticle';
import PageTransition from '../components/PageTransition';
import Separator from '../components/Separator';
import Header from '../framework/Header';
import ErrorMessage from '../components/ErrorMessage';
import ButtonDistance from '../components/ButtonDistance';
import BlockOwner from '../components/BlockOwner';
import styled from 'styled-components';
import { VListItem, VListPlaceholderItem, VListWrapper } from '../components/ListVertical';
import { HListItem, HListWrapper } from '../components/ListHorizontal';
import useFetchDocument from '../hooks/useFetchDocument';
import useFetchNearbyArticles from '../hooks/useFetchNearbyArticles';
import { Helmet } from 'react-helmet-async';
import { trackOwner } from '../common/Analytics';
import useFetchOwner from '../hooks/useFetchOwner';
import { PlaceholderText } from '../components/Placeholders';
import Blocks from '../components/Blocks';
import PresentationHeader from '../components/PresentationHeader';
import PresentationNavigation from '../components/PresentationNavigation';
import Api from '../services/Api';
import useOnScreen from '../hooks/useOnScreen';
import PresentationReferences from '../components/PresentationReferences';
import AnchorNavigation from '../components/AnchorNavigation';
import { SCHEMA_PRESENTATION } from '../Settings';

interface ArticleViewProps {
  previewData?: any
}

interface PresentationRowProps {
  presentationId?: string
}

const ArticleWrapper = styled.main.attrs((p: any) => ({
  visible: p.visible || false,
  layout: p.layout || "article"
}))`
  background-color: #fff;
  float: left;
  width: 100%;
  margin-bottom: 25px;
  opacity: ${p => p.visible ? "1" : "0"};
  transition: opacity .2s;
  box-shadow: 0px 3.55px 16px 2.66px #0000000D;
  overflow: hidden;
  min-height: 100vh;
  padding-bottom: 10px;

  @media (min-width: 1000px) {
    border-radius: 6px 6px 0 0;
  }

  &.presentation {
    margin: 0 10px 25px;
    border-radius: 4px;
    width: calc(100% - 20px);
  }
`;

const ArticleTitle = styled.h1`
  margin: 0 0 25px 0;
  text-align: center;
  font-size: 1.5em;
  line-height: 1.35em;
  padding: 0 10px;
`

const DistanceWrapper = styled.div`
  margin: 0 10px 25px;
  text-align: center;
`;

const BlockWrapper = styled.section`
  max-width: 800px;
  margin: 0 auto;
`;

const NearbyWrapper = styled.section`
  display: block;
  min-height: 1px;
  width: 100%;
  float: left;
`;

/**
 * Render the article page
 * @returns {JSX.Element} Component template
 */
const ArticleView: FC<ArticleViewProps> = ({previewData}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentLanguage, presentationCache } = useContext(AppContext);
  const { articleId } = useParams<{articleId: string}>();
  const { presentationId } = useParams<{presentationId: string}>();
  const nearbyRef = useRef<HTMLDivElement>(null);
  const [metaDescription, setMetaDescription] = useState<string>();
  const [availableLanguages, setAvailableLanguages] = useState<string[]>();
  const [articleLanguage, setArticleLanguage] = useState<string>(currentLanguage || "no");
  const [showMissingLanguageNotice, setShowMissingLanguageNotice] = useState<boolean>(false);
  const [data, isLoading, error] = useFetchDocument(articleId, previewData);
  const [ownerData] = useFetchOwner(data?.owner_id);
  const [nearbyInViewPort] = useOnScreen(nearbyRef, "0px 0px");
  const [nearbyArticles] = useFetchNearbyArticles(nearbyInViewPort, data?.location, 6, Number(articleId));

  /**
   * User clicks "show on map"
   */
  const showOnMap = useCallback(() => {
    const mapUrl = `/explore/map/?pos=${data?.location?.lat},${data?.location?.lng}&zoom=18&id=a${data?.id}`;
    navigate(mapUrl);
  }, [data, navigate]);

  // Identify which language to be displayed
  useEffect(() => {
    let languageList: string[] = [];

    // Get list of available languages
    if (data?.content?.content?.blocks) {
      Object.entries(data?.content?.content?.blocks).forEach((block, i) => {
        const language = block[0];
        if (data?.content?.content?.blocks?.[language].length > 0) {
          languageList.push(language);
        }
      });
    }

    setAvailableLanguages(languageList);
    setArticleLanguage(getFallbackLanguage((currentLanguage || "no"), languageList));

  }, [data, currentLanguage, articleLanguage]);

  // Should the missing language notice be shown? 
  useEffect(() => {
    if (currentLanguage && availableLanguages && availableLanguages?.length > 0) {
      // Only display notice if fallback language is not a sibling language. Example: Bokmål (nb_no) is a sibling language of nynorsk (nn_nb).
      if (!isSiblingLanguage(currentLanguage, articleLanguage)) {
        setShowMissingLanguageNotice(!availableLanguages?.includes(currentLanguage)); 
      }
    }
  }, [articleLanguage, availableLanguages, currentLanguage, setShowMissingLanguageNotice]);

  // Update title
  useEffect(() => {
    document.title = `${getTranslation(data?.content?.general?.title, articleLanguage) || t("list.articles")} - KulturPunkt`;
  }, [t, articleLanguage, data?.content?.general?.title]);

  // Update meta description
  useEffect(() => {
    if (data?.content?.general?.preface) {
      setMetaDescription(getTranslation(data?.content?.general?.preface, articleLanguage));
    }
  }, [setMetaDescription, data, articleLanguage]);

  // Analytics tracker
  useEffect(() => {
    trackOwner(ownerData?.google_analytics_code);
  }, [ownerData]);

  // Display error - if data could not be fetched
  if (error) {
    return (
      <>
        <Header/>
        <ErrorMessage message={t(error?.message)} tryAgain={true}/>
      </>
    );
  }

  // Is the article published?
  if (data?.status && !data?.status?.includes("published")) {
    return (
      <>
        <Header/>
        <ErrorMessage message={t("error.notPublished.body")}/>
      </>
    );
  }

  // Render component
  return (
    <>
      <Helmet htmlAttributes={{lang: currentLanguage}}>
        {metaDescription && (
          <>
            <meta name="description" content={metaDescription} />
            <meta property="og:description" content={metaDescription} />
            <meta property="twitter:description" content={metaDescription} />
          </>
        )}
      </Helmet>

      {presentationId ? (
        <>
          <Header showProgress={isLoading}/>
          <PresentationHeader presentationId={presentationId} articleId={articleId} visible={true} button={presentationCache?.displayType === "route" ? "route" : "map"}/>
        </>
      ) : (
        <Header layout="transparent" showProgress={isLoading}/>
      )}
      <PageTransition className="previewdisable">
        <ArticleWrapper lang={articleLanguage} visible={Boolean(data)} className={presentationId ? "presentation" : "article"}>
          <HeroArticle layout="transparent" media={data?.content?.general?.image} location={data?.content?.metadata?.location} fullscreenButton={true} shareButton={true} languageNotice={showMissingLanguageNotice} title={getTranslation(data?.content?.general?.title, articleLanguage)}/>
          
          {presentationId ? (
            <PresentationNavigation presentationId={presentationId} articleId={articleId} visible={true}/>
          ) : (
            <BlockOwner id={data?.owner_id} name={data?.owner_name || <PlaceholderText width="20"/>}/>
          )}
          <ArticleTitle>{data?.content ? getTranslation(data?.content?.general?.title, articleLanguage) : <PlaceholderText width="30" height="1.1em"/> }</ArticleTitle>
          
          {(data?.content?.metadata?.location?.lat && data?.status === "published") && (
            <DistanceWrapper>
              <ButtonDistance layout="light" onClick={() => showOnMap()}>{t("article.showOnMap")}</ButtonDistance>
            </DistanceWrapper>
          )}

          <BlockWrapper>
            <BlockPreface text={data?.content ? getTranslation(data?.content?.general?.preface, articleLanguage) : <><PlaceholderText /><PlaceholderText /><PlaceholderText /></>}/>

            <AnchorNavigation blocks={data?.content?.content?.blocks?.[articleLanguage]}/>

            { /** Blocks */}
            <Blocks blocks={data?.content?.content?.blocks?.[articleLanguage]} />

            {presentationId && (
              <PresentationNavigation presentationId={presentationId} articleId={articleId} visible={true}/>
            )}

            <PresentationReferences presentationId={presentationId} articleId={articleId}/>

            {ownerData && (
              <>
                <Separator width="content"/>
                <BlockPublisher owner={ownerData}/>
              </>
            )}

            {data?.referenced_from?.filter((item: any) => item?.from_schema_id === SCHEMA_PRESENTATION)?.length > 0 && (
              <>
                <Separator width="content"/>
                <BlockTitle title={t("article.isPartOf")} level="h2"/>
                <VListWrapper>
                  {Symbol.iterator in Object(data?.referenced_from) && data?.referenced_from?.map((item: any, i: number) => {
                    if (item?.from_schema_id === SCHEMA_PRESENTATION) {
                      return <PresentationRow key={`presentation${i}`} presentationId={item?.from_document_id}/>
                    } else {
                      return null;
                    }
                  })}
                </VListWrapper>
              </>
            )}
          </BlockWrapper>

        </ArticleWrapper>

        <NearbyWrapper ref={nearbyRef}>
          {(data?.content && nearbyArticles) && (
            <>
              <BlockTitle title={t("article.nearBy")} level="h2"/>
              <HListWrapper count={nearbyArticles?.length}>
                {Symbol.iterator in Object(nearbyArticles) && nearbyArticles?.map((item: any, i: number) => {
                  return <HListItem key={`article${i}`} media={{url: item?.image_url, media_type: "image", ...item?.content?.general?.image}} title={getTranslation(item?.title, currentLanguage)} location={item?.location} owner={item?.owner_name} link={presentationId ? `/group/${presentationId}/article/${item?.id}` : `/article/${item?.id}`}/>
                })}
              </HListWrapper>
            </>
          )}
        </NearbyWrapper>
      </PageTransition>
    </>
  );
}

/**
 * Fetch and render article row based on article id
 * @returns {JSX.Element} Component template
 */
 const PresentationRow: FC<PresentationRowProps> = ({presentationId}) => {
  const { currentLanguage } = useContext(AppContext);
  const [presentation, setPresentation] = useState<any>();
  const [imageUrl, setImageUrl] = useState<string>();
  
  // Get presentation metadata
  useEffect(() => {
    let isSubscribed = true;
    Api.getDocument(String(presentationId)).then((presentationData) => {
      if (isSubscribed) {
        setPresentation(presentationData);
      }
    });
    return () => { isSubscribed = false; }
  }, [presentationId]);

  // Get image of first article
  useEffect(() => {
    const articleId = presentation?.content?.articles?.list?.[0]?.to_document_id;
    if (!articleId || !presentation?.content || presentation?.content?.general?.image?.mediaId) { return; }

    Api.getDocumentHeadList([articleId]).then((articleData: any) => {
      setImageUrl(articleData?.[0]?.image_src);
    });
  }, [presentation]);

  if (presentation && !presentation?.title) { return null; }

  if (presentation && presentation?.status !== "published") { return null }

  if (presentation) {
    return <VListItem
      media={presentation?.content?.general?.image?.mediaId ? presentation?.content?.general?.image : {url: imageUrl}} 
      title={getTranslation(presentation?.content?.general?.title, currentLanguage)} 
      location={presentation?.content?.metadata?.location}
      link={`/group/${presentationId}/`}
      description={getTranslation(presentation?.content?.general?.description, currentLanguage)}
      />
  } else {
    return <VListPlaceholderItem count={1}/>
  }
}

export default ArticleView;