import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Marker, FeatureGroup, Polyline } from 'react-leaflet'
import L, { LatLngExpression } from 'leaflet'
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppContext';
import { LocationContext } from '../contexts/LocationContext';
import { trackOwner } from '../common/Analytics';
import Api from '../services/Api';
import ErrorMessage from '../components/ErrorMessage';
import Map from '../components/Map';
import PresentationHeader from '../components/PresentationHeader';
import Header from '../framework/Header';

// Define icons
const kpMarker = (id) => L.divIcon({ className: `kpMarker a${id}`, iconSize: [40, 50], iconAnchor: [20, 50] });

/**
 * Render a selection map
 * @returns {JSX.Element} Component template
 */
const SelectionMap: FC = () => {
  const groupRef = useRef<any>(null);
  const { t } = useTranslation();
  const { search } = useLocation();
  const { ownerFilter, presentationCache } = useContext(AppContext);
  const { geoLocation } = useContext(LocationContext);
  const { presentationId } = useParams<{presentationId: string}>();
  const { selectionId } = useParams<{selectionId: string}>();
  const [map, setMap] = useState<any>(null);
  const [markersAreLoaded, setMarkersAreLoaded] = useState<any>(null);
  const [mapIsCentered, setMapIsCentered] = useState<boolean>(false);
  const [mapIsLoaded, setMapIsLoaded] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<{id: number, type: "article" | "museum", presentationId?: string}|undefined>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [articleList, setArticleList] = useState<any>();
  const [polylines, setPolylines] = useState<LatLngExpression[]>([]);
  const [selectionData, setSelectionData] = useState<any>();

  /**
   * Fetch articles from API
   */
  const loadSelectionMarkers = useCallback(() => {
    let isSubscribed = true;
    if (!articleList) {
      setArticleList({});
      setIsLoading(true);
      Api.getMapDocumentList({ location: geoLocation, owner_id: presentationCache?.ownerId }).then(docList => {
        if (isSubscribed) {
          setIsLoading(false);
          setArticleList(docList?.items);
        }
      }).catch(e => {
        setError(e);
      });
    }
    return () => { isSubscribed = false; }
  }, [geoLocation, articleList, setIsLoading, presentationCache]);

  // Get data from the API on load
  useEffect(() => {
    if (geoLocation && presentationCache) {
      loadSelectionMarkers();
    }
  }, [geoLocation, loadSelectionMarkers, presentationCache]);

  /**
  * User clicks a marker
  * @returns {JSX.Element} Component template
  */
   const onMarkerClick = useCallback((e: any, id: number, markerType: "article" | "museum") => {
    // Unfocus all markers
    const markerList = document.querySelectorAll(".leaflet-marker-icon");
    markerList.forEach(item => {
      item?.classList?.remove("active");
    });
    setPopupData(undefined);

    // Focus selected marker and display popup
    e?.target?._icon?.classList?.add("active");
    setPopupData({id: id, type: markerType, presentationId: presentationId});
  }, [presentationId]);

  /**
  * Render article markers
  * @returns {JSX.Element} Component template
  */
   const renderSelectionMarkers = useMemo(() => {
    if (!mapIsLoaded || !articleList) { return; }
    let polylineCache: any[] = [];
    const selection = presentationCache?.selections?.filter((record: any, i: number) => Number(selectionId) === i)?.[0] || {};
    const selectionIds = selection?.articles?.map(article => article.document_id) || [];
    setSelectionData(selection);

    // Article markers
    if (Symbol.iterator in Object(articleList)) {
      const markerList = articleList?.map((marker, i) => {
        if (selectionIds?.includes(marker?.id)) {
          if (marker?.location?.lat && marker?.location?.lng) {
            polylineCache.push([marker?.location?.lat, marker?.location?.lng]);
          }
          return <Marker key={`marker${i}`} position={marker?.location} icon={kpMarker(marker?.id)} eventHandlers={{ click: e => { onMarkerClick(e, marker?.id, "article"); }}}/>
        } else {
          return null;
        }
      })
      setPolylines(polylineCache);
      setMarkersAreLoaded(true);
      return markerList;
    }

    return null;
  }, [articleList, mapIsLoaded, onMarkerClick, presentationCache, selectionId]);

  // Set marker as active - if set in querystring
  useEffect(() => {
    const params = new URLSearchParams(search);
    const markerId = params.get('id');

    if (markerId && markersAreLoaded) {
      const popupId = Number(markerId?.replace(/\D/g,''));
      if (markerId.charAt(0) === "m") {
        setPopupData({id: popupId, type: "museum"});
      } else {
        setPopupData({id: popupId, type: "article"});
      }

      setTimeout(() => {
        document?.querySelector(`.a${markerId}`)?.classList?.add("active");
      }, 2500);
    }
  }, [markersAreLoaded, setPopupData, search]);

  // Hide loading animation when data is loaded
  useEffect(() => {
    if (markersAreLoaded) {
      setIsLoading(false);
      try {
        map?.fitBounds(groupRef?.current?.getBounds());
      } catch(e) {}
    }
  }, [markersAreLoaded, setIsLoading, map]);

  // Analytics tracker
  useEffect(() => {
    trackOwner(ownerFilter?.analyticsId);
  }, [ownerFilter?.analyticsId]);

  // Display error - if data could not be fetched
  if (error) {
    setIsLoading(false);
    return <ErrorMessage message={t(error?.message)} tryAgain={true}/>;
  }

  return (
    <>
      <Header showProgress={isLoading}/>
      <PresentationHeader visible={true} presentationId={presentationId} selectionId={selectionId} button="list" layout="map"/>
      <Map active={true} cluster={true} popupData={popupData} setPopupData={setPopupData} setMapIsLoaded={setMapIsLoaded} map={map} setMap={setMap} mapIsCentered={mapIsCentered} setMapIsCentered={setMapIsCentered}>
        {markersAreLoaded ? (
          <FeatureGroup ref={groupRef}>
            {renderSelectionMarkers}
            {selectionData?.type === "tour" && (
              <Polyline pathOptions={{ color: '#3E7FBB' }} positions={polylines} />
            )}
          </FeatureGroup>
        ) : (
          <>{renderSelectionMarkers}</>
        )}
      </Map>
    </>
  );
}
export default SelectionMap;