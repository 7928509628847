  /**
   * Is the current browser Internet Explorer?
   */
  export function isIE() {
    let isIE = false;
    const ua = window.navigator.userAgent;
    const old_ie = ua.indexOf('MSIE ');
    const new_ie = ua.indexOf('Trident/');

    if ((old_ie > -1) || (new_ie > -1)) {
      isIE = true;
    }

    return isIE;
  }