import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ErrorMessageProps {
  title?: string,
  message: string,
  redirect?: boolean,
  tryAgain?: boolean
}

const Wrapper = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Circle = styled.div`
  margin: 80px auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${p => p.theme?.accentColor};
  color: #fff;
  width: 125px;
  height: 125px;
  border-radius: 125px;
  font-size: 70px;
  font-weight: bold;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
`

const Message = styled.p`
  text-align: center;
`

const Button = styled.p`
  text-align: center;
  background: #fff;
  color: #000;
  padding: 10px 25px;
  border-radius: 25px;
  display: table;
  margin: 40px auto;
`

/**
 * Render a full screen error message
 * @returns {JSX.Element} Component template
 */
const ErrorMessage: FC<ErrorMessageProps> = ({title, message, redirect, tryAgain}) => {
  const { t } = useTranslation();

  /**
   * Redirect to front page
   * @param deviceId Device ID
   */
  const goToFrontPage = useCallback(() => {
    window.location.href = `/`;
  }, []);

  /**
   * Reload page
   * @param deviceId Device ID
   */
   const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Wrapper>
      <Circle>!</Circle>
      <Title>{title || t("error")}</Title>
      <Message>{message}</Message>

      {redirect && (<Button onClick={goToFrontPage}>{t("error.redirectToFrontpage")}</Button>)}
      {tryAgain && (<Button onClick={reloadPage}>{t("error.tryAgain")}</Button>)}
    </Wrapper>
  );
}

export default ErrorMessage;