import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { ReactComponent as NextIcon } from "../assets/icon_next.svg";
import { ReactComponent as PrevIcon } from "../assets/icon_prev.svg";

interface ListScrollerProps {
  children: JSX.Element | JSX.Element[],
  count?: number,
  backgroundColor?: string
}

const Wrapper = styled.div`
  position: relative;
  float: left;
  width: 100%;
`;

const Scroll = styled.div.attrs((p: any) => ({
  scrollSnap: p.scrollSnap || false,
}))`
  overflow: auto;
  scroll-snap-type: ${p => p.scrollSnap ? "x mandatory" : "unset"};
  scroll-behavior: smooth;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Gradient = styled.div.attrs((p: any) => ({
  backgroundColor: p.backgroundColor || p.theme.backgroundColor,
}))`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  width: 50px;

  &.prev {
    left: -1px;
    background: linear-gradient(90deg, ${p => p.backgroundColor}, rgba(255, 255, 255, 0));
  }

  &.next {
    right: -1px;
    background: linear-gradient(270deg, ${p => p.backgroundColor}, rgba(255, 255, 255, 0));
  }
`;

const ScrollButton = styled.button`
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  outline: none;
  border: none;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
`;

/**
 * Render a horizontal slider wrapper. Includes navigation arrows if desktop mode is active
 * @returns {JSX.Element} Component template
 */
export const HListScroller: FC<ListScrollerProps> = ({children, count, backgroundColor}) => {
  const { isDesktop } = useContext(AppContext);
  const listRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

  /**
   * Make horizontal list scroll on button clicks
   * @param {number} pixels Amount of pixels to scroll
   */
  const scroll = useCallback((pixels: number) => {
    if (listRef.current) {
      listRef.current.scrollLeft += pixels;
    }
  }, []);

  const setArrowVisibility = useCallback(() => {
    if (listRef.current) {
      // Should left navigation button be available?
      if (listRef.current.scrollLeft > 10) {
        setShowLeftArrow(true);
      } else {
        setShowLeftArrow(false);
      }

      // Should right navigation button be available?
      if (listRef.current.offsetWidth + listRef.current.scrollLeft < listRef.current.scrollWidth - 10) {
        setShowRightArrow(true);
      } else {
        setShowRightArrow(false);
      }
    }
  }, []);

  // Set arrow visibility on scroll
  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener("scroll", (e) => {
        setArrowVisibility();
      })
    }
  }, [setArrowVisibility]);

  // Set arrow visibility on load
  useEffect(() => {
    if (children && (count && count > 0)) {
      setArrowVisibility();
    }
  }, [children, setArrowVisibility, count]);

  return (
    <>
      {isDesktop ? (
        <Wrapper>
          {showLeftArrow && (<Gradient className="prev" backgroundColor={backgroundColor}><ScrollButton onClick={() => { scroll(-200) }}><PrevIcon/></ScrollButton></Gradient>)}
            <Scroll ref={listRef} scrollSnap={true}>
              {children}
            </Scroll>
          {showRightArrow && (<Gradient className="next" backgroundColor={backgroundColor}><ScrollButton onClick={() => { scroll(200) }}><NextIcon/></ScrollButton></Gradient>)}
        </Wrapper>
      ) : (
        <Wrapper>
          <Scroll ref={listRef}>
            {children}
          </Scroll>
        </Wrapper>
      )}
    </>
  );
}