import { FC } from 'react';
import styled from 'styled-components';

interface BlockPrefaceProps {
  text: string
}

const BlockContainer = styled.div`
  margin: 0 0 30px;
  padding: 0 25px;
  float: left;
  width: 100%;
  font-size: 1.125em;
  line-height: 24px;
`;

/**
 * Render an article preface
 * @returns {JSX.Element} Component template
 */
const BlockPreface: FC<BlockPrefaceProps> = ({text}) => {

  if (text && (text.length > 0 || typeof text === "object")) { 
    return (
      <BlockContainer>{text}</BlockContainer>
    );
  }

  return null;
}

export default BlockPreface;