import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CloseIconSvg from '../assets/icon_close_small.svg'

interface LanguageNoticeProps {
  setIsOpen?: any
}

const BlockContainer = styled.div`
  margin: 20px 0 30px;
  padding: 15px;
  float: left;
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 2px 0px 0px rgb(0 0 0 / 20%);
  clear: both;
  display: flex;
`;

const Circle = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  background-color: ${p => p.theme?.separatorColor};
  color: ${p => p.theme.accentColor};
  width: 45px;
  height: 45px;
  border-radius: 22.5px;
  font-size: 30px;
  font-weight: bold;
  float: left;
`;

const Text = styled.span`
  display: block;
  margin-right: -5px;
  text-align: center;
  width: 80%;
  padding: 0 5px;
`;

const CloseIcon = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url(${CloseIconSvg});
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
  pointer-events: all;
  border: none;
`;

/**
 * Render an article language notice to be displayed when the content is not available in the users chosen language
 * @returns {JSX.Element} Component template
 */
const LanguageNotice: FC<LanguageNoticeProps> = ({setIsOpen}) => {
  const { t } = useTranslation();

  const closeNotice = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <BlockContainer role="contentinfo">
      <Circle>!</Circle>
      <Text>{t("article.notAvailableInLanguage")}</Text>
      <CloseIcon onClick={closeNotice} aria-label={t("presentation.close")}/>
    </BlockContainer>
  );
}

export default LanguageNotice;