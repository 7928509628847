import { useCallback, useEffect, useState } from "react"
import Api from "../services/Api"

/**
 * Fetch profile details from the museum API, based on the museum identifier
 * @param {any} ownerData Owner data object
 * @returns {object} Owner object
 */
export default function useFetchOwnerProfile(ownerData: any) {
  const [mainImage, setMainImage] = useState<any>(null);
  const [avatarImage, setAvatarImage] = useState<any>(null);

  // Verify image URL due to unstable output from the museum API
  const verifyImageUrl = useCallback((url: string) => {
    if (url?.length === 0) { return null; }
    if (url?.includes("http")) { 
      return {url: url, media_type: "image"}; 
    } else {
      return {url: `https://${url}`, media_type: "image"};
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    if (ownerData?.main_image?.mediaId) {
      // Does main_image exist?
      setMainImage(ownerData?.main_image);
      setAvatarImage(ownerData?.logo);
    } else if (ownerData?.image_url || !ownerData?.identifier) {
      // Does image_url exist?
      setMainImage({url: ownerData?.image_url, media_type: "image"});
      setAvatarImage(ownerData?.logo);
    } else {
      // Get profile from the museum API
      Api.getMuseumApiContent(ownerData?.identifier).then(data => {
        if (isSubscribed) {
          setMainImage(verifyImageUrl(data?.museum?.media));
          setAvatarImage(verifyImageUrl(data?.museum?.profile));
        }
      }).catch(e => { 
        //console.error(`Could not load images from the museum API`);
      });
    }

    return () => { isSubscribed = false }
  }, [ownerData, verifyImageUrl]);

  return [mainImage, avatarImage]
}