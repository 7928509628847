import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as StopIcon } from "../assets/icon_stop.svg";
import { AppContext } from '../contexts/AppContext';

const AudioPlayer = styled.div`
  position: fixed;
  display: flex;
  z-index: 19;
  width: 100%;
  height: 55px;
  bottom: 0;
  box-shadow: 0 -1px 0px rgb(0 0 0 / 20%);
  background-color: #fff;
  padding: 10px 20px;
  transition: bottom .2s;
  visibility: hidden;

  &.open {
    bottom: 70px;
    visibility: visible;
  }

  &.desktop {
    width: 300px;
    box-shadow: none;
    border-radius: 6px;
    background-color: #f4f4f4;
    bottom: 25px;
    box-shadow: 0 0 0 10px #fff;
    display: none;
  }

  &.desktop.closed {
    display: none;
  }
`;

const Button = styled.button`
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 17.5px;
  background-color: ${p => p.theme.accentColor};
  border: none;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    opacity: .8;
  }
`;

const Control = styled.div`
  margin-left: 25px;
  float: left;
  width: 100%;
`;

const Title = styled.span`
  --max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: #B9BDC3;
  height: 4px;
  border-radius: 2px;
  margin-top: 8px;
  position: relative;

  &::before {
    width: 100%;
    content: "";
    position: absolute;
    padding: 30px 0;
    transform: translateY(-50%);
  }
`;

const Progress = styled.div`
  width: 0;
  height: 4px;
  border-radius: 2px;
  background-color: ${p => p.theme.accentColor};
  transition: width .1s;
  pointer-events: none;
`;

/**
 * Render a floating audio player
 * @returns {JSX.Element} Component template
 */
const FloatingAudioPlayer: FC = () => {
  const { audioPlayerStats, setAudioPlayerStats, isDesktop } = useContext(AppContext);
  const { t } = useTranslation();
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [playbackPosition, setPlaybackPosition] = useState<number>(0);

  /**
   * Stop audio
   */
  const stopAudio = useCallback(() => {
    setAudioPlayerStats({
      active: false,
      url: audioPlayerStats?.url
    });
  }, [audioPlayerStats, setAudioPlayerStats]);

  // Should the player be visible?
  useEffect(() => {
    if (audioPlayerStats?.active) {
      setIsOpen(true);
      if (audioRef.current) {
        // Playback is called within BlockAudio.tsx because of restrictions in Safari on iOS
        //audioRef.current.src = String(audioPlayerStats?.url);
        //audioRef.current.play();
      }
    } else {
      setIsOpen(false);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [audioPlayerStats]);

  // Publish playing position to context
  const updatePosition = useCallback((e: any) => {
    if (audioRef.current) {
      setPlaybackPosition(Number(audioRef.current.currentTime / audioRef.current.duration) * 100);
    }
  }, []);

  /**
   * Set position of playback on click
   */
  const setPosition = useCallback((e) => {
    const boundingRect = e.target.getBoundingClientRect();
    const currentTime = Number(((e?.clientX - boundingRect?.left) / e?.target?.offsetWidth) * 100);
    setPlaybackPosition(currentTime);
    if (audioRef.current) {
      audioRef.current.currentTime = audioRef.current.duration * currentTime / 100;
    }
  }, []);

  return (
    <AudioPlayer className={`${isOpen ? "open" : "closed"} ${isDesktop ? "desktop" : "mobile"}`}>
      <audio style={{display: "none"}} ref={audioRef} onTimeUpdate={updatePosition} onEnded={stopAudio}/>
      <Button onClick={stopAudio} aria-label={isOpen? t("media.play") : t("media.stop")} aria-hidden={isOpen ? "false": "true"}><StopIcon/></Button>
      <Control>
        <Title>{audioPlayerStats?.caption}</Title>
        <ProgressBar onClick={setPosition}>
          <Progress style={{width: `${playbackPosition}%`}}/>
        </ProgressBar>
      </Control>
    </AudioPlayer>
  );
}

export default FloatingAudioPlayer;