import { FC, useCallback, useEffect, useRef,  useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import FastAverageColor from 'fast-average-color';
import Image from './Image';
import BlockTaskQuiz from './BlockTask_Quiz';
import BlockTaskWordGame from './BlockTask_WordGame';
import Button from './Button';
import { ReactComponent as PrizeIcon } from "../assets/icon_prize.svg";
import { ReactComponent as DisappointmentIcon } from "../assets/icon_sad.svg";

interface BlockTaskProps {
  taskList?: any
}

const BlockContainer = styled.div`
  margin: 0 0 30px 0;
  padding: 0 25px;
  float: left;
  width: 100%;
  display: block;
  user-select: none;
`;

const TaskWrapper = styled.div`
  margin: -30px 20px 20px;
  padding: 20px;
  position: relative;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0,0,0,.2);
`;

const TaskContainer = styled.div`
  background-color: ${p => p.theme.backgroundColor};
  border-radius: 5px;
  overflow: hidden;
`;

const TaskHeader = styled.div`
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
`;

const ImageWrapper = styled.div`
  height: 260px;
  background-color: #3E7FBB;
  overflow: hidden;
  padding: 20px;
`;

const Title = styled.span`
  font-weight: bold;
  text-align: center;
  width: 100%;
  display: block;
`;

const HeroImage = styled(Image)`
  width: 220px;
  height: 220px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  & img {
    border-radius: 50%;
  }
`;

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, .8);
  transition: opacity .5s;
`;

const ResultPopup = styled.div`
  position: fixed;
  width: 300px;
  min-height: 250px;
  max-height: 80%;
  background-color: #fff;
  border-radius: 5px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  align-items: center;
  justify-content: center;
  align-content: center;
  transition: .3s top;
  visibility: hidden;
  padding: 0 0 25px;
  overflow: hidden;

  &.active {
    top: 50%;
    visibility: visible;
  }
`;

const Icon = styled.div`
  text-align: center;
  padding-top: 10px;

  & svg {
    margin: 0 auto;
    width: 80px;
    height: 80px;
  }
`;

const PopupTitle = styled.h1`
  text-transform: uppercase;
  font-size: 1.5em;
  text-align: center;
`;

const ResultFeedback = styled.div`
  padding: 0 25px;
  max-height: 350px;
  overflow: auto;
`;

const TaskButton = styled(Button)`
  margin: 20px auto 0;
`

/**
 * Render a task block
 * @returns {JSX.Element} Component template
 */
const BlockTask: FC<BlockTaskProps> = ({taskList}) => {
  const { t } = useTranslation();
  const popupRef = useRef<HTMLDivElement>(null)
  const backgroundRef = useRef<HTMLDivElement>(null);
  const [mediaObject, setMediaObject] = useState<any>();
  const [currentTaskNumber, setCurrentTaskNumber] = useState<number>(0);
  const [currentTask, setCurrentTask] = useState<any>(null);
  const [tasksAreFinished, setTasksAreFinished] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);
  const [resultPopup, setResultPopup] = useState<any>(false);

  // Set current task
  useEffect(() => {
    setCurrentTask(taskList?.[currentTaskNumber]);
  }, [taskList, currentTaskNumber]);

  // Set average color background
  useEffect(() => {
    if (!(mediaObject?.src || mediaObject?.url)) { return; }

    if (backgroundRef.current) {
      const fac = new FastAverageColor();
      fac.getColorAsync(`${mediaObject?.url || mediaObject?.src}?dimension=167x167`).then(color => {
        if (backgroundRef.current) {
          backgroundRef.current.style.backgroundColor = color?.rgba || "#3E7FBB";
        }
      }); 
    }
  }, [mediaObject]);

  // Create slide-in animation on popup enterence
  useEffect(() => {
    if (resultPopup && popupRef.current) {
      popupRef.current.classList.add("active");
    }
  }, [resultPopup]);

  /**
   * Callback to be run when a task has finished
   * @param {boolean} wasCorrect Was the task solved correctly?
   */
  const taskIsFinished = useCallback((wasCorrect: boolean) => {
    if (wasCorrect) {
      setScore(score + 1);
    }
    setResultPopup({wasCorrect});
  }, [score]);

  /**
   * Close popup
   */
  const closePopup = useCallback(() => {
    setResultPopup(null);
    if (currentTaskNumber < taskList?.length - 1) {
      setCurrentTaskNumber(currentTaskNumber + 1);
    } else {
      setTasksAreFinished(true);
    }
  }, [currentTaskNumber, taskList?.length]);

  /**
   * Restart tasks
   */
  const restartTasks = useCallback(() => {
    setScore(0);
    setCurrentTaskNumber(0);
    setTasksAreFinished(false);
  }, []);

  if (taskList?.length === 0) { return null; }

  return (
    <>
      {resultPopup && (
        <OverlayWrapper>
          <ResultPopup ref={popupRef} role="alertdialog" aria-modal="true" aria-labelledby="popuptitle">
            {resultPopup?.wasCorrect ? (<><Icon><PrizeIcon/></Icon><PopupTitle id="popuptitle">{t("task.correct")}</PopupTitle></>) : (<><Icon><DisappointmentIcon/></Icon><PopupTitle id="popuptitle">{t("task.wrong")}</PopupTitle></>)}
            {currentTask?.resultFeedback?.length > 0 && (<ResultFeedback>{currentTask?.resultFeedback}</ResultFeedback>)}
            <TaskButton onClick={closePopup}>{(currentTaskNumber < taskList?.length - 1) ? (<>{t("task.next")}</>) : (<>{t("task.close")}</>)}</TaskButton>
          </ResultPopup>
        </OverlayWrapper>
      )}
      <BlockContainer>
        <TaskContainer>
          {!tasksAreFinished ? (
            <>
              <TaskHeader>{taskList?.length > 1 ? `${t("task.task")} ${currentTaskNumber + 1} ${t("task.of")} ${taskList?.length}` : t("task.task")}</TaskHeader>
              <ImageWrapper ref={backgroundRef}>
                {currentTask?.cover?.mediaId ? (<HeroImage media={currentTask?.cover} setMedia={setMediaObject}/>) : (<HeroImage media={{url: "/img/placeholder_task.png", media_type: "image"}} setMedia={setMediaObject}/>)}
              </ImageWrapper>
              <TaskWrapper>
                {currentTask?.taskType === "quiz" && (
                  <BlockTaskQuiz taskData={currentTask} completeCallback={taskIsFinished}/>
                )}
                {currentTask?.taskType === "wordgame" && (
                  <BlockTaskWordGame taskData={currentTask} completeCallback={taskIsFinished}/>
                )}
              </TaskWrapper>
            </>
          ) : (
            <>
            <ImageWrapper ref={backgroundRef}>
              <HeroImage media={{url: "/img/placeholder_task.png", media_type: "image"}}/>
            </ImageWrapper>
              <TaskWrapper>
                {(taskList?.length > 1) ? (
                  <Title>{t("task.youAnsweredCorrectOnXTasks").replace("{correctAnswers}", String(score)).replace("{totalTasks}", taskList?.length)}</Title>
                ) : (
                  <Title>{score > 0 ? (<>{t("task.yourAnswerIsCorrect")}</>) : <>{t("task.yourAnswerIsWrong")}</>}</Title>
                )}
                <TaskButton onClick={restartTasks}>{t("task.retry")}</TaskButton>
              </TaskWrapper>
            </>
          )}
        </TaskContainer>
      </BlockContainer>
    </>
  );
}

export default BlockTask;