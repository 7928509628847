import { setCookie, getCookie } from './common/Cookies';

// Kulturio backend
let apiUrl = process.env.REACT_APP_KULTURIO_API_URL || "";
let apiKey = process.env.REACT_APP_KULTURIO_API_KEY || "";
let articleSchema = parseInt(process.env.REACT_APP_SCHEMA_ARTICLE || "");
let presentationSchema = parseInt(process.env.REACT_APP_SCHEMA_PRESENTATION || "");

// Environment
export const KULTURIO_ENV = process.env.REACT_APP_ENVIRONMENT || "development";
export const KULTURIO_BASE_URL = process.env.PUBLIC_URL || "https://kulturpunkt.org";

// PWA
export const IS_PWA = false;

// ---- Testing purposes start ----

const urlParams = new URLSearchParams(window.location.search);
const env = urlParams.get('env');
if (env) { setCookie("env", env); }

// Schema IDs - dev-KP
if (getCookie("env") === "dev") {
  apiUrl = "https://dev-admin.kulturio.org/portal/api/";
  apiKey = "9bc113be-d253-4beb-955e-94a80c59a374";
  articleSchema = 2;
  presentationSchema = 6;
}

// Schema IDs - testinstance
if (getCookie("env") === "test") {
  apiUrl = "https://dev-admin.kulturio.org/portal/api/";
  apiKey = "f17ee8eb-ada1-442e-8147-cb339f9463f4";
  articleSchema = 5;
  presentationSchema = 12;
}

// Schema IDs - production
if (getCookie("env") === "prod") {
  apiUrl = "https://admin.kulturio.org/portal/api/";
  apiKey = "06610301-1032-4b19-9197-6af77e8aebd6";
  articleSchema = 2;
  presentationSchema = 12;
}

export const KULTURIO_API_KEY = apiKey;
export const SCHEMA_ARTICLE = articleSchema;
export const SCHEMA_PRESENTATION = presentationSchema;
export const KULTURIO_API_URL = apiUrl;

// ---- Testing purposes end ----

// Languages
export const LANGUAGES = [
  /**
  { key: "nb_no", name: "Norsk bokmål", siblings: ["no", "nn_no"], fallback: ["no", "nn_no", "sv", "en"] },
  { key: "nn_no", name: "Norsk nynorsk", siblings: ["no", "nb_no"], fallback: ["no", "nb_nb", "sv", "en"] },
  */
  { key: "no", name: "Norsk", siblings: ["nb_no", "nb", "nn_no", "nn"], fallback: ["nb_no", "nn_no", "sv", "en"] },
  { key: "sv", name: "Svenska", fallback: ["no", "en"] },
  { key: "en", name: "English", fallback: ["no", "sv"] },
  { key: "de", name: "Deutsch", fallback: ["en", "no", "sv"] },
  { key: "fr", name: "Français", fallback: ["en", "no", "sv"] },
  { key: "es", name: "Español", fallback: ["en", "no", "sv"] },
  /*{ key: "pl", name: "Polski", fallback: ["en", "no", "sv"] }*/
];

// Available font sizes
export const FONTSIZES = [
  { size: 1, name: "menu.normalfontsize" },
  { size: 1.2, name: "menu.largefontsize" },
];

// Credits
export const CREDITS = {
  1: "credit.photographer",
  2: "credit.artist",
  3: "credit.author",
  4: "credit.director",
  5: "credit.voice",
  6: "credit.owner",
  7: "credit.rightsmanager"
}

// DM
export const DM_DMS_IMAGE_URL = "https://mm.dimu.org/image/{id}";
export const DM_SEARCH_URL = "https://api.dimu.org/api/solr/";
export const DM_OBJECT_URL = "https://api.dimu.org/api/artifact?unique_id={id}&mapping=simple_json";
export const DM_API_KEY = "BAFLAwH5QE6FkhfUwFNksg";
export const DM_DISPLAY_URL = "https://digitaltmuseum.org/{id}";

// Museum API
export const MUSEUM_API_URL = "https://apigateway.dimu.org/museum-api/museums"

// Minne API
export const MINNE_API_URL_NO = "https://api.minner.no/api/";
export const MINNE_API_URL_SV = "https://api.minnen.se/api/";

// Timers (in seconds)
export const DISTANCE_UPDATE_TIMER = 10;

// Google Analytics for tracking of all pages
export const ANALYTICS_ACTIVE = (process.env.REACT_APP_ANALYTICS_ACTIVE === "true");
export const ANALYTICS_CODE = "UA-29787204-4";

// External links
export const PRIVACY_POLICY_URL = "https://kulturit.org/personvernerklaring";