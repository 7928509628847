import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../services/Api';
import Overlay from '../components/Overlay';
import NotFoundView from './NotFoundView';

/**
 * Render the QR code forwarding scanner
 * @returns {JSX.Element} Component template
 */
const QrCodeForwardView: FC = () => {
  const { code } = useParams<{code: string}>();
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    Api.getQrCode(code || "").then(data => {
      const presentationId = data?.context_document_id;
      const articleId = data?.document_id;

      // Redirect if articleId has been found
      if (articleId && presentationId) {
        window.location.href = `/group/${presentationId}/article/${articleId}`;
      } else if (articleId) {
        window.location.href = `/article/${articleId}`;
      } else {
        setNotFound(true);
      }
    }).catch(() => {
      setNotFound(true);
    });
  }, [code]);

  if (notFound) {
    return <NotFoundView/>;
  } else {
    return <Overlay visibility={true} messageType="loading"/>;
  }
}

export default QrCodeForwardView;
