import { useEffect, useRef, useState } from "react";

/**
 * Simple hook to determine if element is in viewport
 * @param {Element} ref DOM element to observe
 * @param {string} rootMargin Offset of observer
 * @param {boolean} Returns true if element is visible
 */
export default function useOnScreen(ref, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const hasIntersected = useRef(false);
  const [isIntersecting, setIntersecting] = useState(false);
  const [refCache, setRefCache] = useState<any>(null);

  useEffect(() => {
    setRefCache(ref?.current);

    if (!('IntersectionObserver' in window)) {
      setIntersecting(true);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          hasIntersected.current = true;
        }
        // Update our state when observer callback fires
        setIntersecting(hasIntersected.current);
      },
      {
        rootMargin
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      setIntersecting(false);
      if (refCache) {
        observer.unobserve(refCache);
      }
    };
  }, [ref, rootMargin, refCache]); // Empty array ensures that effect is only run on mount and unmount

  return [isIntersecting];
}