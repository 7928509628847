import { KULTURIO_API_KEY, KULTURIO_API_URL, MINNE_API_URL_NO, MINNE_API_URL_SV, MUSEUM_API_URL } from "../Settings";

/**
 * API mockup based on a limited set of test data, available in testdata.json
 */
abstract class Api {

  /**
   * 
   * @param url Fetch URL
   * @returns 
   */
  static async fetchKulturioData(url) {
    return fetch(url, {
      method: "GET",
      headers: {
        "x-api-key": KULTURIO_API_KEY
      }
    }).then(response => {
      if (response?.status !== 200) {
        return Promise.reject({message: "error.fetchError", error: response?.status});
      }
      return response?.json()
    }
    ).then(data => {
      return data;
    }).catch(e => {
      if (!navigator.onLine) {
        return Promise.reject({message: "error.noNetwork", error: e});
      } else {
        return Promise.reject({message: "error.fetchError", error: e});
      }
    });
  }

  /**
   * Get document
   * @param id Document ID
   * @returns {object} Full document
   */
  public static async getDocument(id: string) {
    if (id === "undefined" || typeof id === "undefined") { return null; }
    const url = `${KULTURIO_API_URL}documents/${id}`;

    return this.fetchKulturioData(url);
  }

  /**
   * Get document metadata
   * @param {number[]} ids Document ID
   * @returns {object} Full document
   */
   public static async getDocumentHeadList(ids: number[]) {
    if (typeof ids === "undefined") { return null; }
    if (ids.length === 0) { return {} }
    const url = `${KULTURIO_API_URL}documents/fields?d=${ids?.join('&d=')}`;

    return this.fetchKulturioData(url);
  }

  /**
   * Get media object
   * @param {number} id Media ID
   * @returns {object} Media metadata
   */
  public static async getMedia(id: string) {
    if (id === "undefined" || typeof id === "undefined" || id === "null" || id === "Null") { return null; }
    const url = `${KULTURIO_API_URL}media/${id}`;

    return this.fetchKulturioData(url);
  }

  /**
   * Get document list
   * @param {object} options Options
   * @returns {object} Document list
   */
  public static async getDocumentList(options: any) {
    const params = new URLSearchParams();
    options?.location?.lat && params.set('location', `${options.location?.lat},${options.location?.lng}`);
    options?.page_size && params.set('page_size', options.page_size);
    options?.page && params.set('page', options.page);
    options?.search && params.set('search', options.search);
    options?.schema_ids && params.set('schema_ids', options.schema_ids);
    options?.path_value && params.set('path_value', options.path_value);
    options?.old_id && params.set('old_id', String(Number(options.old_id)));
    options?.owner_id && params.set('owner_id', options.owner_id);
    options?.referenced_from_schema_id && params.set('referenced_from_schema_id', options.referenced_from_schema_id);
    options?.sort && params.set('sort', options.sort);
    options?.order_asc && params.set('order_asc', options.order_asc);
    options?.include_no_location ? params.set('include_no_location', options.include_no_location) : params.set('include_no_location', "false");
    params.set('status', 'published');
   
    const url = `${KULTURIO_API_URL}documents/?${params.toString()}`;

    return this.fetchKulturioData(url);
  }


  /**
   * Get document reversed relations
   * @param {object} documentId Document id
   * @returns {object} Document list
   */
   public static async getReverseRelations(documentId: string) {
    const url = `${KULTURIO_API_URL}documents/${documentId}/reverse_references`;

    return this.fetchKulturioData(url);
  }

  /**
   * Get owner
   * @param id Document ID
   * @returns {object} Full document
   */
   public static async getOwner(id: string) {
    if (id === "undefined" || typeof id === "undefined") { return null; }

    const url = `${KULTURIO_API_URL}owners/${id}`;

    return this.fetchKulturioData(url);
  }

  /**
   * Get owner list
   * @param {object} options Options
   * @returns {object} Owner list
   */
   public static async getOwnerList(options: any) {
    const params = new URLSearchParams();
    options?.location?.lat && params.set('location', `${options.location?.lat},${options.location?.lng}`);
    options?.page_size && params.set('page_size', options.page_size);
    options?.page && params.set('page', options.page);
    options?.search && params.set('search', options.search);

    const url = `${KULTURIO_API_URL}owners/?${params.toString()}`;
    
    return this.fetchKulturioData(url);
  }

  /**
   * Get compressed document list for map
   * @param {object} options Options
   * @returns {object} Owner list
   */
   public static async getMapDocumentList(options: any) {
    const params = new URLSearchParams();
    options?.location?.lat && params.set('location', `${options.location?.lat},${options.location?.lng}`);
    options?.page_size && params.set('page_size', options.page_size);
    options?.page && params.set('page', options.page);
    options?.owner_id && params.set('owner_id', options.owner_id);
    options?.referenced_from_schema_id && params.set('referenced_from_schema_id', options.referenced_from_schema_id);
    params.set('status', 'published');

    const url = `${KULTURIO_API_URL}documents/mapdata?${params.toString()}`;
    
    return this.fetchKulturioData(url);
  }

  /**
   * Get compressed owner list for map
   * @param {object} options Options
   * @returns {object} Owner list
   */
   public static async getMapOwnerList(options: any) {
    const params = new URLSearchParams();
    options?.location?.lat && params.set('location', `${options.location?.lat},${options.location?.lng}`);
    options?.page_size && params.set('page_size', options.page_size);
    options?.page && params.set('page', options.page);

    const url = `${KULTURIO_API_URL}owners/mapdata?${params.toString()}`;
    
    return this.fetchKulturioData(url);
  }

  /**
   * Get values from QR code
   * @param {string} code QR code
   */
   public static async getQrCode(code: string) {
    const url = `${KULTURIO_API_URL}qr-codes/${code}/code`;
    return this.fetchKulturioData(url);
  }

  /**
   * Query DM
   * @param {string} url URL to query 
   */
   public static async getDMContent(url: string) {
    return fetch(url, {
      method: "GET",
    }).then(response => response?.json()
    ).then(data => {
      return data;
    }).catch(e => {
      if (!navigator.onLine) {
        return Promise.reject({message: "error.noNetwork", error: e});
      } else {
        return Promise.reject({message: "error.fetchError", error: e});
      }
    });
  }

  /**
   * Query museum API
   * @param {string} identifier Museum identifier
   */
   public static async getMuseumApiContent(identifier: string) {
    return fetch(`${MUSEUM_API_URL}?identifier=kp-${identifier.toLowerCase()}`, {
      method: "GET"
    }).then(response => response?.json()
    ).then(data => {
      return data;
    }).catch(e => {
      if (!navigator.onLine) {
        return Promise.reject({message: "error.noNetwork", error: e});
      } else {
        return Promise.reject({message: "error.fetchError", error: e});
      }
    });
  }

  /**
   * Query Minne API
   * @param {string} url URL to query
   * @param {string} country Defines if norwegian (no) or swedish (sv) API should be used  
   */
  public static async getMinneContent(url: string, country: string) {
    return fetch(`${country === "no" ? MINNE_API_URL_NO : MINNE_API_URL_SV}${url}`, {
      method: "GET"
    }).then(response => response?.json()
    ).then(data => {
      return data;
    }).catch(e => {
      if (!navigator.onLine) {
        return Promise.reject({message: "error.noNetwork", error: e});
      } else {
        return Promise.reject({message: "error.fetchError", error: e});
      }
    });
  }
}

export default Api;