import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface TextCropProps {
  text: string,
  length?: number
}

const ReadMoreLink = styled.button`
  cursor: pointer;
  color: ${p => p.theme.darkAccentColor};
  text-decoration: underline;
  padding: 0;
  border: none;
  font-size: 1em;
  background-color: transparent;
`;

/**
 * Render a markdown text block
 * @returns {JSX.Element} Component template
 */
const TextCrop: FC<TextCropProps> = ({text, length}) => {
  const { t } = useTranslation();
  const [croppedText, setCroppedText] = useState<string>();
  const [cropActive, setCropActive] = useState<boolean>(true);

  useEffect(() => {
    if (text?.length > (length || 150)) {
      setCroppedText(text?.substring(0, (length || 150))?.trim() + "..");
      setCropActive(true);
    } else {
      setCropActive(false);
    }
  }, [text, length]);

  if (cropActive) {
    return <>{croppedText} <ReadMoreLink onClick={() => setCropActive(false)}>{t("article.readMore")}</ReadMoreLink></>;
  } else {
    return <>{text}</>;
  }
}

export default TextCrop;