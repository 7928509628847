import { FC, useCallback, useEffect, useState } from 'react';
import { BlockMediaItem, BlockMediaWrapper } from './BlockMedia';
import Api from '../services/Api';
import { DM_OBJECT_URL, DM_API_KEY } from '../Settings';
import useFetchMedia from '../hooks/useFetchMedia';
import BlockText from './BlockText';
import { parseCredits } from '../common/Media';

interface BlockDMProps {
  element?: any
}

/**
 * Render a single DigitaltMuseum element block
 * @returns {JSX.Element} Component template
 */
const BlockDMSingle: FC<BlockDMProps> = ({element}) => {
  const [dmMetadata, setDmMetadata] = useState<any>();
  const [parsedMetadata, setParsedMetadata] = useState<any>([]);
  const [mediaObject] = useFetchMedia({mediaId: element?.mediaId});

  // Remove "?dimensions" part of image URLs
  const cleanImageUrl = useCallback((imageUrl: string) => {
    return imageUrl.replace(/[?]dimension\=[^.]+&?/, ""); //eslint-disable-line
  }, []);

  // Get object metadata from DM
  useEffect(() => {
    let isSubscribed = true;

    if (!mediaObject?.identifier) { return; }
      Api.getDMContent(`${DM_OBJECT_URL.replace("{id}", mediaObject?.identifier)}&api.key=${DM_API_KEY}`).then(data => {
        isSubscribed && setDmMetadata(data);
    }).catch(e => {
      console.error(`Could not fetch data from DM`);
    });

    return () => { isSubscribed = false };
  }, [mediaObject]);

  // Extract DM metadata
  useEffect(() => {
    let fields: any[] = [];

    if (element?.includeFields) {
      element?.includeFields?.forEach((element: any) => {
        switch(element?.field) {
          case "title":
            fields.push({type: "text", text: dmMetadata?.title || undefined});
            break;
          case "motif":
            fields.push({type: "text", text: dmMetadata?.motif?.description || undefined});
            break;
          case "history":
            fields.push({type: "text", text: dmMetadata?.history?.description || undefined});
            break;
          case "description":
            fields.push({type: "text", text: dmMetadata?.description || undefined});
            break;
          case "owner":
            fields.push({type: "text", text: dmMetadata?.partOfCollection?.owner?.name || undefined});
            break;
          case "images":
            let images: any[] = [];
            element?.imageIds?.forEach((imageId: any) => {
              dmMetadata?.images && dmMetadata?.images.forEach((image: any, i: number) => {
                if (imageId === image?.mediaId || imageId === i) {
                  images.push(image);
                }
              });
            });
            fields.push({type: "images", media: images, owner: dmMetadata?.partOfCollection?.owner?.name || undefined});
            break;
        }
      });
    }
    setParsedMetadata(fields);
  }, [dmMetadata, element]);

  return (
    <>
      { parsedMetadata?.map((field: any, i: number) => {
        switch(field?.type) { 
          case "text":
            if (!field?.text) { return null; }
            return <BlockText key={`block${i}`} text={field?.text}/>
          case "images":
            if (!field?.media) { return null; }
            return (
              <BlockMediaWrapper key={`block${i}`} layout={field?.media?.length > 1 ? "slide" : "full"} lightbox={true}>
                {field?.media?.map((image: any, j: number) => {
                  const credit = [
                    {credit_type_id: 1, name: image?.photographer},
                    {credit_type_id: 6, name: field?.owner}
                  ]

                  return <BlockMediaItem key={`dmimage${j}`} media={{ url: cleanImageUrl(image?.url), media_type: "dm" }} lightbox={true} credits={parseCredits(credit)}/>
                })}
              </BlockMediaWrapper>)
          default:
            return null;
        }
      })}
    </>
  );
}

export default BlockDMSingle;