import { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage } from 'react-compare-slider';
import { AppContext } from '../contexts/AppContext';
import { getTranslation } from '../common/Translation';
import { getMediaObject } from '../common/Media';

interface BlockImageCompareProps {
  elements?: any
}

const Wrapper = styled.div`
  list-style: none;
  margin: 0 auto 20px;
  padding: 0 25px;
  float: left;
  width: 100%;
  display: block;
  position: relative;
  border-radius: 4px;

  & > div {
    max-height: 70vh;
  }

  & img {
    max-height: 70vh;
  }
`;

// Define component handle style
const CompareHandle = () => (
  <ReactCompareSliderHandle buttonStyle={{ 
    alignItems: "center", 
    backgroundColor: "#3E7FBB",
    width: "42px",
    height: "42px",
    gap: "4px",
    border: "none"
  }}/>
)

/**
 * Render an error message. Supports markdown
 * @returns {JSX.Element} Component template
 */
const BlockImageCompare: FC<BlockImageCompareProps> = ({elements}) => {
  const { currentLanguage } = useContext(AppContext);
  const [imageList, setImageList] = useState<any>([]);

  // Get media objects from API
  useEffect(() => {
    let isSubscribed = true;
    let imageArray: any[] = [];

    (async() => {
      await Promise.all(elements?.map(async (element: any, i: number) => {
        if (isSubscribed) {
          imageArray[i] = await getMediaObject(element);
          if (imageArray[i]?.media_type === "video") { 
            imageArray[i].url = imageArray[i]?.thumbnail_src
          }
        }
      }));

      setImageList(imageArray);
    })();

    return () => { isSubscribed = false };
  }, [elements]);

  // Render list of compare sliders
  const renderComparableImages = useMemo(() => {
    let compareList: JSX.Element[] = [];
    for (let i = 0; i < imageList.length; i+=2) {
      if (imageList?.[i+1]) {
        compareList.push(
          <Wrapper key={`compare${i}`}>
            <ReactCompareSlider handle={<CompareHandle/>}
              itemOne={<ReactCompareSliderImage src={(imageList?.[i]?.url || imageList?.[i]?.src) + "?dimension=1200x1200"} alt={getTranslation(imageList?.[i]?.title, currentLanguage)} />}
              itemTwo={<ReactCompareSliderImage src={(imageList?.[i+1]?.url || imageList?.[i+1]?.src) + "?dimension=1200x1200"} alt={getTranslation(imageList?.[i+1]?.title, currentLanguage)} />} />
          </Wrapper>
        );
      }
    }

    return compareList;
  }, [imageList, currentLanguage]);

  if (!imageList) {
    return null;
  }

  return (
    <>
      {renderComparableImages}
    </>
  );
}

export default BlockImageCompare;