import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { getFallbackLanguage, getTranslation, isSiblingLanguage } from '../common/Translation';
import BlockTitle from '../components/BlockTitle';
import ErrorMessage from '../components/ErrorMessage';
import HeroPresentation from '../components/HeroPresentation';
import { VListItem, VListPlaceholderItem, VListWrapper } from '../components/ListVertical';
import PageTransition from '../components/PageTransition';
import Separator from '../components/Separator';
import { AppContext } from '../contexts/AppContext';
import Header from '../framework/Header';
import Api from '../services/Api';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../components/Button';
import { ReactComponent as ShowAllIcon } from "../assets/icon_plus.svg";
import PresentationHeader from '../components/PresentationHeader';
import PresentationNavigation from '../components/PresentationNavigation';
import HeroArticle from '../components/HeroArticle';
import useFetchDocument from '../hooks/useFetchDocument';
import BlockPreface from '../components/BlockPreface';
import Blocks from '../components/Blocks';
import { PlaceholderText } from '../components/Placeholders';
import ButtonDistance from '../components/ButtonDistance';
import PresentationReferences from '../components/PresentationReferences';
import { trackOwner } from '../common/Analytics';

interface PresentationViewProps {
  previewData?: any
}

interface ArticleRowProps {
  article: any,
  presentationId: number|string|undefined
}

const PresentationWrapper = styled.div.attrs((p: any) => ({
  visible: p.visible || true
}))`
  background-color: #fff;
  float: left;
  width: 100%;
  margin-bottom: 25px;
  opacity: ${p => p.visible ? "1" : "0"};
  transition: opacity .2s;
  padding: 20px 0 0;
  margin: 0 10px 25px;
  border-radius: 4px;
  width: calc(100% - 20px);

  @media (min-width: 1000px) {
    border-radius: 8px;
  }
`;

const ArticleWrapper = styled.div.attrs((p: any) => ({
  visible: p.visible || false,
  layout: p.layout || "article",
  isSingle: p.isSingle || false
}))`
  background-color: #fff;
  float: left;
  opacity: ${p => p.visible ? "1" : "0"};
  transition: opacity .2s;
  box-shadow: 0px 3.55px 16px 2.66px #0000000D;
  overflow: hidden;
  /*min-height: 100vh;*/
  margin: 0 10px 25px;
  border-radius: 4px;
  width: calc(100% - 20px);

  @media (min-width: 1000px) {
    border-radius: ${p => p.isSingle ? "6px" : "6px 6px 0 0"};
  }
`;

const BlockWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const DistanceWrapper = styled.div`
  margin: 0 10px 25px;
  text-align: center;
`;

const ArticleTitle = styled.h1`
  margin: 0 0 25px 0;
  text-align: center;
  font-size: 1.5em;
  line-height: 1.35em;
  padding: 0 10px;
`

const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  bottom: 5px;
  background: linear-gradient(360deg, #ffffff 18.84%, rgba(255, 255, 255, 0.873037) 50.08%, rgba(255, 255, 255, 0) 94.93%);
  padding: 25px;
`;

/**
 * Render the presentation page
 * @returns {JSX.Element} Component template
 */
const PresentationView: FC<PresentationViewProps> = ({previewData}) => {
  const navigate = useNavigate();
  const { currentLanguage, presentationCache } = useContext(AppContext);
  const [availableLanguages, setAvailableLanguages] = useState<string[]>();
  const { presentationId } = useParams<{presentationId: string}>();
  const [articleLanguage, setArticleLanguage] = useState<string>(currentLanguage || "no");
  const [showMissingLanguageNotice, setShowMissingLanguageNotice] = useState<boolean>(false);
  const { t } = useTranslation();
  const [showAllArticles, setShowAllArticles] = useState<boolean>(false);
  const [showAllPresentations, setShowAllPresentations] = useState<boolean>(false);
  const [presentationData, setPresentationData] = useState<any>({});
  const [articleData] = useFetchDocument(presentationData?.startItem === "first" ? (presentationData?.articles?.list?.[0]?.document_id || presentationData?.articles?.list?.[0]?.to_document_id) : null, null);

  /**
   * User clicks "show on map"
   */
   const showOnMap = useCallback(() => {
    const mapUrl = `/explore/map/?pos=${articleData?.location?.lat},${articleData?.location?.lng}&zoom=18&id=a${articleData?.id}`;
    navigate(mapUrl);
  }, [articleData, navigate]);

  // Should preview or API data be displayed?
  useEffect(() => {
    if (previewData) {
      setPresentationData({
        id: previewData?.id,
        title: previewData?.general?.title,
        image: previewData?.general?.image,
        description: previewData?.general?.description,
        startItem: previewData?.general?.startItem || "first",
        displayType: previewData?.general?.displayType || "default",
        articles: previewData?.articles || { list: [] },
        selections: previewData?.selections || []
      });
    } else {
      setPresentationData(presentationCache);
    }
  }, [setPresentationData, previewData, presentationCache]);

  // Identify which language to be displayed
  useEffect(() => {
    let languageList: string[] = [];

    // Get list of available languages
    setAvailableLanguages(languageList);
    setArticleLanguage(getFallbackLanguage((currentLanguage || "no"), languageList));
  }, [currentLanguage, articleLanguage]);

  // Update title
  useEffect(() => {
    document.title = `${getTranslation(presentationData?.title, articleLanguage)} - KulturPunkt`;
  }, [t, articleLanguage, presentationData?.title]);

  // Identify which language to be displayed
  useEffect(() => {
    let languageList: string[] = [];

    // Get list of available languages
    if (articleData?.content?.blocks) {
      Object.entries(articleData?.content?.blocks).forEach((block: any) => {
        const language = block[0];
        if (articleData?.content?.blocks?.[language].length > 0) {
          languageList.push(language);
        }
      });
    }

    setAvailableLanguages(languageList);
    setArticleLanguage(getFallbackLanguage((currentLanguage || "no"), languageList));

  }, [articleData, currentLanguage, articleLanguage]);

  // Analytics tracker
  useEffect(() => {
    presentationCache?.google_analytics_code && trackOwner(presentationCache?.google_analytics_code);
  }, [presentationCache]);

  // Should the missing language notice be shown? 
  useEffect(() => {
    if (currentLanguage && availableLanguages && availableLanguages?.length > 0) {

      // Only display notice if fallback language is not a sibling language. Example: Bokmål (nb_no) is a sibling language of nynorsk (nn_nb).
      if (!isSiblingLanguage(currentLanguage, articleLanguage)) {
        setShowMissingLanguageNotice(!availableLanguages?.includes(currentLanguage));
      }
    }
  }, [articleLanguage, availableLanguages, currentLanguage, setShowMissingLanguageNotice]);

  // Display error - if data could not be fetched
  if (presentationData?.error) {
    return (
      <>
        <Header/>
        <ErrorMessage message={t("error.notPublished.body")} tryAgain={true}/>
      </>
    );
  }

  // Is the presentation published?
  if (presentationData?.status && !presentationData?.status?.includes("published")) {
    return (
      <>
        <Header/>
        <ErrorMessage message={t("error.notPublished.body")}/>
      </>
    );
  }

  return (
    <>
      <Header/>
      <PresentationHeader presentationId={presentationId} visible={true} button={presentationData?.displayType === "route" ? "route" : "map"}/>
      <PageTransition className="previewdisable">
        <HeroPresentation title={getTranslation(presentationData?.title, articleLanguage)} ownerId={presentationData?.ownerId} ownerName={presentationData?.ownerName} preface={getTranslation(presentationData?.description, articleLanguage)} articleCount={presentationData?.articles?.list?.filter(record => record?.status === "published")?.length || 0} presentationCount={presentationData?.selections?.filter((selection: any) => selection?.status === "published")?.length || 0}/>
        {presentationData?.title && (
          <>
            {(presentationData?.startItem === "first" && articleData?.status?.includes("published")) ? (
              <ArticleWrapper visible={true}>  
                <HeroArticle layout="normal" media={presentationData?.image?.mediaId ? presentationData?.image : articleData?.content?.general?.image} location={articleData?.content?.metadata?.location} fullscreenButton={true} shareButton={true} languageNotice={showMissingLanguageNotice} title={getTranslation(articleData?.content?.general?.title, articleLanguage)}/>
                <PresentationNavigation presentationId={presentationId} articleId={articleData?.id} visible={true}/>
                <ArticleTitle>{articleData?.content ? getTranslation(articleData?.content?.general?.title, articleLanguage) : <PlaceholderText width="30" height="1.1em"/> }</ArticleTitle>
                {articleData?.content?.metadata?.location?.lat && (
                  <DistanceWrapper>
                    <ButtonDistance layout="light" onClick={() => showOnMap()}>{t("article.showOnMap")}</ButtonDistance>
                  </DistanceWrapper>
                )}
                <BlockWrapper>
                  <BlockPreface text={articleData?.content ? getTranslation(articleData?.content?.general?.preface, articleLanguage) : <><PlaceholderText /><PlaceholderText /><PlaceholderText /></>}/>
                  <Blocks blocks={articleData?.content?.content?.blocks?.[articleLanguage]} />

                  <PresentationReferences presentationId={presentationId} articleId={articleData?.id}/>
                </BlockWrapper>
              </ArticleWrapper>
            ) : (
              <>
                {presentationData && (
                  <ArticleWrapper visible={true} isSingle={true}>
                    <HeroArticle layout="noMargin" media={presentationData?.image} fullscreenButton={true} shareButton={true} languageNotice={showMissingLanguageNotice} title={getTranslation(presentationData?.content?.general?.title, articleLanguage)}/>
                  </ArticleWrapper>
                )}
              </>
            )}

            <PresentationWrapper>
              {presentationData?.articles?.list?.length > 0 && (
                <>
                  <BlockTitle title={t("list.articles")} level="h2" id="articles" count={presentationData?.recordCount} actionTitle={presentationData?.displayType === "route" ? t("presentation.showRoute") : t("article.showOnMap")} actionLink={previewData ? `#` :`/group/${presentationId}/map`}/>
                  <VListWrapper>
                    {Symbol.iterator in Object(presentationData?.articles?.list) && presentationData?.articles?.list?.map((item: any, i: number) => {
                      if (showAllArticles || i < 5) {
                        return <ArticleRow key={`article${i}`} article={item} presentationId={presentationId}/>
                      } else {
                        return null;
                      }
                    })}
                    {!showAllArticles && presentationData?.articles?.list?.length > 5 && (
                      <ButtonContainer>
                        <Button onClick={() => { setShowAllArticles(true); }}><ShowAllIcon/>{t("home.showAll")}</Button>
                      </ButtonContainer>
                    )}
                  </VListWrapper>
                </>
              )}
  
              {(presentationData?.articles?.list?.length > 0 && presentationData?.presentations?.length > 0) && (
                <Separator/>
              )}

              {presentationData?.selections?.length > 0 && (
                <>
                  <BlockTitle title={t("list.presentations")} level="h2" id="presentations" count={presentationData?.selections?.filter((selection: any) => selection?.status === "published")?.length || 0}/>
                  <VListWrapper>
                    {Symbol.iterator in Object(presentationData?.selections) && presentationData?.selections?.map((selection: any, i: number) => {
                      if (selection?.status !== "published") { return null; }
                      if (showAllPresentations || i < 5) {
                        switch(selection?.type) {
                          case "map":
                            return <VListItem key={`selection${i}`} media={{url: "/img/placeholder_selection_map.png"}} title={getTranslation(selection?.title, currentLanguage)} description={getTranslation(selection?.description, currentLanguage)} link={`/group/${presentationId}/selection/${i}/map`} />
                          case "tour":
                            return <VListItem key={`selection${i}`} media={{url: "/img/placeholder_selection_route.png"}} title={getTranslation(selection?.title, currentLanguage)} description={getTranslation(selection?.description, currentLanguage)} link={`/group/${presentationId}/selection/${i}/map`} />
                          default:
                            return <VListItem key={`selection${i}`} media={{url: "/img/placeholder_selection_list.png"}} title={getTranslation(selection?.title, currentLanguage)} description={getTranslation(selection?.description, currentLanguage)} link={`/group/${presentationId}/selection/${i}/list`} />
                        }
                      } else {
                        return null;
                      }
                    })}
                    {!showAllPresentations && presentationData?.selections?.length > 5 && (
                      <ButtonContainer>
                        <Button onClick={() => { setShowAllPresentations(true); }}><ShowAllIcon/>{t("home.showAll")}</Button>
                      </ButtonContainer>
                    )}
                  </VListWrapper>
                </>
              )}
            </PresentationWrapper>
          </>
        )}
      </PageTransition>
    </>
  );
}

export default PresentationView;

/**
 * Fetch and render article row based on article id
 * @param {object} article
 * @param {number} presentationId
 * @returns {JSX.Element} Component template
 */
const ArticleRow: FC<ArticleRowProps> = ({article, presentationId}) => {
  const { currentLanguage } = useContext(AppContext);
  const [item, setItem] = useState<any>();

  // Get whole article - if needed
  const fetchArticle = useCallback(() => {
    let isSubscribed = true;
    Api.getDocument(String(article?.to_document_id)).then((articleData) => {
      if (isSubscribed) {
        setItem(articleData);
      }
    });
    return () => { isSubscribed = false; }
  }, [article?.to_document_id]);

  // Determine to fetch whole article or use cached data
  useEffect(() => {
    if (article?.to_document_id) {
      fetchArticle();
    } else {
      setItem(article);
    }
  }, [article, fetchArticle])

  if (!item?.status?.includes("published")) { return null; }

  if (item) {
    return <VListItem
      media={{url: item?.image_src || item?.image_url, media_type: "image", ...item?.content?.general?.image}} 
      title={getTranslation(item?.title, currentLanguage)} 
      location={item?.location}
      link={`/group/${presentationId}/article/${item?.document_id || item?.id}`}
      description={getTranslation(item?.description, currentLanguage)}
      />
  } else {
    return <VListPlaceholderItem count={1}/>
  }
}