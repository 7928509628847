import { useCallback, useEffect, useState } from "react"
import Api from "../services/Api"
import { SCHEMA_ARTICLE, SCHEMA_PRESENTATION } from "../Settings";

/**
 * Fetch nearby article list from the API.
 * @param {object} position Position
 * @param {number} page_size Count
 * @param {boolean} active Should the hook be active?
 * @returns {object} Article list object
 */
export default function useFetchNearbyArticles(active: boolean, location: {lat: number, lng: number}, page_size: number, excludeId?: number) {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<any>();

  /**
   * Fetch data
   */
  const fetchData = useCallback(() => {
    // Return if no locations are received
    if (!location) { return [{}, null, null] }

    // Parse options
    const query = { location, page_size, schema_ids: SCHEMA_ARTICLE, referenced_from_schema_id: SCHEMA_PRESENTATION }

    setIsLoading(true);

    Api.getDocumentList(query).then(list => {
      if (excludeId) {
        setData(list?.items?.filter((item: any) => item.id !== excludeId));
      } else {
        setData(list?.items);
      }

      setIsLoading(false);
    }).catch(e => {
      setError(e); 
    }); 
  }, [setData, setIsLoading, location, page_size, excludeId]);

  // Fetch data when options has changed
  useEffect(() => {
    if (location && active) {
      fetchData();
    }
  }, [fetchData, location, active]);

  return [data, isLoading, error]
}