import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { generateCSSFilters, showImageWhenLoaded } from '../common/Media';
import PlaceholderImage from '../assets/placeholder_kp.svg';
import useFetchMedia from '../hooks/useFetchMedia';
import useOnScreen from '../hooks/useOnScreen';

interface MediaProps {
  media?: any,
  alt?: string,
  dimensions?: string,
  className?: string,
  style?: any,
  onClick?: any,
  usePlaceholder?: boolean,
  setMedia?: any
}

const ImageElement = styled.img.attrs((p: any) => ({
  focalX: p.focalX,
  focalY: p.focalY,
  filters: p.filters || null
}))`
  object-fit: cover;
  object-position: ${p => p.focalX || 50}% ${p => p.focalY || 50}%;
  opacity: 0;
  transition: opacity .5s;
  display: block;
  width: 100%;
  height: 100%;
  visibility: hidden;
  filter: ${p => p.filters};
`;

const ImagePlaceholder = styled.img`
  width: 100%;
  height: 100%;
  visibility: hidden;
`;

const ImageWrapper = styled.picture.attrs((p: any) => ({
  usePlaceholder: p.usePlaceholder || false
}))`
  background-color: #333;
  background-image: ${p => p.usePlaceholder ? "url(" + PlaceholderImage+ ");" : "none"};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 25px;
  background-blend-mode: overlay;
  transition: background-color .5s;
  margin: 0;
  display: block;
  border-radius: 3px;
  position: relative;

  &.link {
    cursor: pointer;
  }
`;

/**
 * Render an image. Used by most item components.
 * @returns {JSX.Element} Component template
 */
const Image: FC<MediaProps> = ({ media, alt, className, dimensions, style, onClick, setMedia, usePlaceholder}) => {
  const imageRef = useRef<HTMLElement>(null);
  const [scaledUrl, setScaledUrl] = useState<string|undefined>();
  const [inViewPort] = useOnScreen(imageRef, "0px 0px");
  const [mediaObject] = useFetchMedia(media, !inViewPort);
  const [filterCss, setFilterCss] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (setMedia && mediaObject) { setMedia(mediaObject); }
  }, [mediaObject, setMedia]);

  // Parse CSS filters from media object
  useEffect(() => {
    if (mediaObject?.filters) {
      setFilterCss(generateCSSFilters(mediaObject?.filters));
    }
  }, [mediaObject]);

  // Calculate image size based on container width and device pixel ratio
  useEffect(() => {
    const pixelRatio = window?.devicePixelRatio || 1;
    const elementWidth = pixelRatio >= 2 ? (imageRef?.current?.offsetWidth || 600) * 2 : (imageRef?.current?.offsetWidth || 600);
    let imgUrl = String(mediaObject?.thumbUrl || mediaObject?.url || mediaObject?.src).replace(/\?.*$/, '');
    if (imgUrl === "undefined") { return; }

    // Make sure https is used on all urls
    imgUrl = imgUrl.replace("http://", "https://");

    if (elementWidth < 168) {
      setScaledUrl(`${imgUrl}?dimension=167x167`);
    } else if (elementWidth < 251) {
      setScaledUrl(`${imgUrl}?dimension=250x250`);
    } else if (elementWidth < 401) {
      setScaledUrl(`${imgUrl}?dimension=400x400`);
    } else if (elementWidth < 601) {
      setScaledUrl(`${imgUrl}?dimension=600x600`);
    } else if (elementWidth < 801) {
      setScaledUrl(`${imgUrl}?dimension=800x800`);
    } else if (elementWidth < 1201) {
      setScaledUrl(`${imgUrl}?dimension=1200x1200`);
    } else {
      setScaledUrl(`${imgUrl}?dimension=max`);
    }
  }, [mediaObject]);

  return (
    <ImageWrapper ref={imageRef} onClick={onClick} style={style} className={className} usePlaceholder={usePlaceholder}>
      {mediaObject?.thumbUrl || mediaObject?.url || mediaObject?.src ? (<ImageElement alt={alt || ""} className={`loading`} onLoad={showImageWhenLoaded} src={scaledUrl} focalX={mediaObject?.focalPoint?.x} focalY={mediaObject?.focalPoint?.y} filters={filterCss}/>) : (<ImagePlaceholder/>)}
    </ImageWrapper>
  )
}

export default Image;