import { FC, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { VListWrapper, VListItem, VListPlaceholderItem } from '../components/ListVertical';
import styled, { keyframes } from 'styled-components';
import ErrorMessage from '../components/ErrorMessage';
import useFetchOwnerList from '../hooks/useFetchOwnerList';
import useIntersectObserver from '../hooks/useIntersectObserver';
import SpinnerIcon from '../assets/icon_spinner_tiny.svg';

interface exploreListProps {
  active?: boolean,
  setIsLoading?: any
}

const ListView = styled.div.attrs((p: any) => ({
  active: p.active || false
}))`
  display: ${p => p.active ? "inline" : "none"};
  margin-top: 20px;
`;

const SpinAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Loader = styled.div.attrs((p: any) => ({
  showSpinner: p.showSpinner || false
}))`
  width: 40px;
  height: 40px;
  background-image: url('${SpinnerIcon}');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  animation: ${SpinAnimation} 2s infinite linear;
  opacity: ${p => p.showSpinner ? "1" : "0"};
  clear: both;
  margin: 0 auto;
  transition: opacity .2s;
`;

/**
 * List owners
 * @returns {JSX.Element} Component template
 */
const ExploreOwnerList: FC<exploreListProps> = ({active, setIsLoading}) => {
  const { t } = useTranslation();
  const intersectRef = useRef<HTMLDivElement>(null);
  const [ownerPageNumber, incrementPageCount] = useReducer((state) => { return state + 1; }, 0);
  const [ownerList, isLoading, error] = useFetchOwnerList({ location: true, page_size: 20, page: ownerPageNumber }, { updateDistance: true, increment: true }, active);
  useIntersectObserver(!isLoading, intersectRef, incrementPageCount);

  // Is data being loaded?
  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  // Display error - if data could not be fetched
  if (error && active) {
    setIsLoading(false);
    return (
      <ListView active={active}>
        <ErrorMessage message={t(error?.message)} tryAgain={true}/>
      </ListView>
    );
  }

  return (
    <>
      <ListView active={active}>
        <VListWrapper>
          {Symbol.iterator in Object(ownerList?.items) && ownerList?.items?.map((item, i) => {
            return <VListItem key={`article${i}`} profile={item} title={item?.name} location={item?.location} owner={item?.owner_name} link={item?.id && `/owner/${item?.id}`}/>
          })}
          {!ownerList?.items && (
            <VListPlaceholderItem count={10}/>
          )}
        </VListWrapper>

        {ownerList?.items && (
          <Loader ref={intersectRef} showSpinner={isLoading}/>
        )}
      </ListView>
    </>
  );
}

export default ExploreOwnerList;