import { FC, useCallback } from 'react';
import styled from 'styled-components';

interface BlockWidgetProps {
  html: string
}

const BlockContainer = styled.div`
  margin: 0 25px 30px 25px;
  float: left;
  width: 100%;
  display: block;

  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    height: 100%;
    border: 1px solid #333;
  }
`;

/**
 * Render a widget block, used for embedding third party functionality. Strips all tags except of <iframe/>
 * @returns {JSX.Element} Component template
 */
const BlockWidget: FC<BlockWidgetProps> = ({html}) => {

  /**
   * Strip all tags except from <iframe/>
   * @param {string} html HTML to parse
   */
  const stripTags = useCallback((html: string) => {
    return html?.replace(/<(?!iframe\s*\/?)[^>]+>/g, "");
  }, []);

  if (!html) { return null; }

  return (
    <BlockContainer dangerouslySetInnerHTML={{__html: stripTags(html)}}/>
  );
}

export default BlockWidget;