import { useCallback, useEffect, useRef, useState } from "react";
import Api from "../services/Api"
import { SCHEMA_PRESENTATION } from "../Settings";

/**
 * Fetch presentation list from the API. Supports lazyload when options.increment = true and options.page increments
 * @param {object} query Fetch query options
 * @param {object} options Options
 * @returns {object} Article list object
 */
export default function useFetchArticleList(query: any, options?: {updateDistance?: boolean, increment?: boolean}, active: boolean = true) {
  const cache = useRef({});
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<any>();

  /**
   * Fetch data
   */
  const fetchData = useCallback((query: any, options: any) => {
    // Abort if querying data that exceeds result count
    if (cache.current["data"]) {
      if ((query?.page_size * query?.page) > Number(cache.current["data"]?.total_count)) {
        return;
      }
    }

    // Store options in cache
    cache.current["query"] = JSON.stringify(query);

    // Parse options
    query.schema_ids = SCHEMA_PRESENTATION;
    const parsedQuery = query;

    // Store data in cache
    setIsLoading(true);

    Api.getDocumentList(parsedQuery).then(list => {
      let returnData = {};

      // Should new data be added to previous fetched data?
      if (options?.increment) {
        const joinedLists = [...(cache?.current?.["data"]?.items || []), ...(list?.items || [])]
        returnData = {...list, ...{items: joinedLists }}
      } else {
        returnData = list;
      }

      // Store data in cache and state
      cache.current["data"] = returnData;
      setData(returnData);
      setIsLoading(false);
    }).catch(e => {
      setError(e); 
    }); 
  }, []);

  // Fetch data when options has changed
  useEffect(() => {
    if (active && cache.current["query"] !== JSON.stringify(query)) {
      fetchData(query, options);
    }
  }, [active, fetchData, query, options]);

  return [data, isLoading, error]
}