import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronLeftIcon } from "../assets/chevron_left.svg";
import { ReactComponent as ChevronRightIcon } from "../assets/chevron_right.svg";

interface PaginationProps {
  currentPage: number,
  itemCount: number,
  pageSize?: number,
  setCurrentPage: any
}

const PaginationWrapper = styled.ul`
  margin: 30px 0;
  padding: 2px 25px;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: auto;
  align-items: flex-start;
  
  &::-webkit-scrollbar {
    display: none;
  }

  &.manyItems li:not(.priority):not(.active) {
    display none;
  }

  @media (max-width: 500px) {
    &.manyItems {
      /* padding-left: 50px; */
    }
  }
`

const PaginationItem = styled.li`
  width: 40px;
  height: 40px;
  margin: 0 1px;
`;

const PaginationLink = styled.button`
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  text-align: center;
  flex: 0 0 auto;
  border: none;

  &.active {
    background-color: ${p => p.theme.accentColor};
    color: #fff;
  }

  &:active {
    background-color: ${p => p.theme.accentColor}80;
  }
`

const DummyItem = styled.li`
  width: 40px;
  height: 40px
`;

/**
 * Render a pagination component
 * @returns {JSX.Element} Component template
 */
const Pagination: FC<PaginationProps> = ({currentPage, itemCount, pageSize, setCurrentPage}) => {

  console.log(currentPage, itemCount, pageSize);

  /**
   * Render pagination items
   */
  const paginationItems = useMemo(() => {
    let items:JSX.Element[] = [];
    let pageCount: number = Math.ceil(itemCount/(pageSize || 10) + 1);

    for (let i = 1; i < pageCount; i++) {
      let classList:string[] = [];
      if (i === currentPage) { classList.push("active"); }
      if (i === currentPage - 1) { classList.push("priority"); }
      if (i === currentPage + 1) { classList.push("priority"); }
      if (i === 1) { classList.push("priority"); }
      if (i === pageCount - 1) { classList.push("priority"); }
      items.push(<PaginationItem className={classList?.join(" ") || undefined} key={`pagination${i}`} onClick={() => setCurrentPage(i)}><PaginationLink className={classList?.join(" ") || undefined} aria-current={classList.includes("active") ? "true" : "false"}>{i}</PaginationLink></PaginationItem>);
    }

    return items;
  }, [itemCount, pageSize, currentPage, setCurrentPage]);

  // Do not render if only one page is neccesary
  if (itemCount <= (pageSize || 10)) { return null; }

  return (
    <nav role="navigation">
      <PaginationWrapper className={itemCount/(pageSize || 10) > 7 ? "manyItems": "fewItems"}>
        {currentPage > 1 ? (<PaginationItem className="priority"><PaginationLink onClick={() => setCurrentPage(currentPage - 1)}><ChevronLeftIcon/></PaginationLink></PaginationItem>) : <DummyItem/>}
        {paginationItems}
        {currentPage < (itemCount / (pageSize || 10)) ? (<PaginationItem className="priority"><PaginationLink onClick={() => setCurrentPage(currentPage + 1)}><ChevronRightIcon/></PaginationLink></PaginationItem>) : <DummyItem/>}
      </PaginationWrapper>
    </nav>
  );
}

export default Pagination;