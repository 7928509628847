import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface BlockOwnerProps {
  id: number,
  name: string
}

const OwnerElement = styled(Link)`
  margin: 25px 0 10px;
  padding: 0 25px;
  float: left;
  width: 100%;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
`;

/**
 * Render a block title
 * @returns {JSX.Element} Component template
 */
const BlockOwner: FC<BlockOwnerProps> = ({id, name}) => {
  return (
    <OwnerElement to={`/owner/${id}`}>{name}</OwnerElement>
  );
}

export default BlockOwner;