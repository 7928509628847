import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as LogoSvg } from "../assets/logo.svg";

interface LogoProps {
  className?: string
}

const LogoElement = styled(LogoSvg)`
  cursor: pointer;
`

/**
 * Render an error message. Supports markdown
 * @returns {JSX.Element} Component template
 */
const Logo: FC<LogoProps> = ({className}) => {
  const navigate = useNavigate();

  const goToFrontPage = useCallback(() => {
    navigate("/")
  }, [navigate]);

  return (
    <LogoElement className={className} onClick={goToFrontPage}/>
  );
}

export default Logo;