import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Markdown from 'marked-react';

interface ArticleTextProps {
  text: string
}

const BlockContainer = styled.div`
  margin: 0 0 30px 0;
  padding: 0 25px;
  float: left;
  width: 100%;
  display: block;
  font-weight: 400;
  line-height: 24px;

  & h1, & h2, & h3, & h4, & h5, & h6 {
    font-size: 1em;
    margin-top: 25px;
  }

  & a, & a:visited { 
    color: ${p => p.theme.darkAccentColor};
  }
`;

/**
 * Render a HTML/markdown text block
 * @returns {JSX.Element} Component template
 */
const BlockText: FC<ArticleTextProps> = ({text}) => {
  const [isHTML, setISHTML] = useState<boolean>(false);
  const [parsedString, setParsedString] = useState<string>("");

  /**
   * Unescape html characters from string
   * @param {string} string String to parse
   * @returns {string} Parsed string
   */
   const unescapeHtml = useCallback((htmlString: string) => {
    if (typeof htmlString !== "string") { return ""; }
    return htmlString
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#039;/g, "'");
  }, []);

  // Does the string contain an HTML tag?
  useEffect(() => {
    const testRegex = /<[^>]+>/i;
    setISHTML(testRegex.test(unescapeHtml(text)));
  }, [text, unescapeHtml]);

  // Parse string as HTML or markdown
  useEffect(() => {
    if (typeof text !== "string") { return; }

    if (isHTML) {
      // HTML: Only include whitelisted tags

      const htmlStripRegex = /<(?!\/?(p|br|h1|h2|h3|h4|h5|h6|b|i|u|strong|em|strike|sub|sup|ul|ol|li|a|span)\b[\s]*[/]?)[^>]+>/gi;
      setParsedString(unescapeHtml(text).replace(htmlStripRegex, ""));
    } else {
      // Markdown: Clean ordered list items
      setParsedString(text); //setParsedString(text.replace(/(\d*)\. (.*)/g, "\$1\\. $2\n"));
    }
  }, [text, isHTML, unescapeHtml]);

  return (
    <>
      {isHTML ? (
        <BlockContainer dangerouslySetInnerHTML={{__html: parsedString}}/>
      ) : (
        <BlockContainer>
          <Markdown gfm={true} breaks={true}>{parsedString}</Markdown>
        </BlockContainer>
      )}
    </>
  );
}

export default BlockText;