import React from 'react';
import { FC } from 'react';
import BlockTitle from '../components/BlockTitle';
import BlockQuote from '../components/BlockQuote';
import BlockText from '../components/BlockText';
import BlockDMSearch from '../components/BlockDM_Search';
import BlockDMSingle from '../components/BlockDM_Single';
import BlockWidget from '../components/BlockWidget';
import { BlockMediaItem, BlockMediaWrapper } from './BlockMedia';
import { BlockAudioItem, BlockAudioWrapper } from './BlockAudio';
import { BlockLinkItem, BlockLinksWrapper } from './BlockLinks';
import BlockError from './BlockError';
import { BlockDocumentItem, BlockDocumentsWrapper } from './BlockDocuments';
import BlockAnchor from './BlockAnchor';
import BlockTasks from './BlockTasks';
import BlockImageCompare from './BlockMedia_Compare';
import { BlockByline } from './BlockByline';
import { BlockMinne } from './BlockMinne';
//import BlockImageSlideshow from './BlockMedia_Slideshow';

interface BlocksProps {
  blocks?: any
}

/**
 * Render article blocks
 * @returns {JSX.Element} Component template
 */
const Blocks: FC<BlocksProps> = ({blocks}) => {

  return (
    <>
    { blocks?.map((block: any, i: number) => {
      switch(block?.blockType) {
        case "text":
          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)}  
              <BlockText text={block?.body}/>
            </React.Fragment>
          )

        case "media":
          let mediaBlockLayout = block?.layout;
          if (!mediaBlockLayout || mediaBlockLayout === "default") { 
            if (block?.mediaItems?.length > 1) {
              mediaBlockLayout = "slide";
            } else {
              mediaBlockLayout = "full";
            }
          }

          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)}
              {(() => { 
                switch(mediaBlockLayout) {
                  case "compare":
                    return <BlockImageCompare elements={block?.mediaItems}/>;
                  //case "slide":
                  //  return <BlockImageSlideshow elements={block?.mediaItems}/>
                  default:
                    return <BlockMediaWrapper layout={mediaBlockLayout} lightbox={true}>
                    {block?.mediaItems?.map((item: any, j: number) => {
                      return <BlockMediaItem key={`media${j}`} media={item} lightbox={true}/>  
                    })}
                  </BlockMediaWrapper>
                }
              })()}
            </React.Fragment>
          )

        case "3d":            
          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)}  
              <BlockMediaWrapper layout="full" lightbox={true}>
                {block?.["3dItems"]?.map((item, j) => {
                  return <BlockMediaItem key={`media${j}`} media={item} lightbox={true}/>  
                })}
              </BlockMediaWrapper>
            </React.Fragment>
          )

        case "audio":
          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)}  
              <BlockAudioWrapper>
                {block?.audioItems?.map((item, j) => {
                  return <BlockAudioItem key={`media${j}`} media={item} />  
                })}
              </BlockAudioWrapper>
            </React.Fragment>
          )

        case "links":
          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)}  
              <BlockLinksWrapper>
                {block?.links?.map((link, j) => {
                  return <BlockLinkItem key={`link${j}`} url={String(link?.url)} title={String(link?.title)} />  
                })}
              </BlockLinksWrapper>
            </React.Fragment>
          )

        case "documents":
          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)}  
              <BlockDocumentsWrapper>
                {block?.documentItems?.map((doc, j) => {
                  return <BlockDocumentItem key={`link${j}`} media={{mediaId: doc?.mediaId}} />  
                })}
              </BlockDocumentsWrapper>
            </React.Fragment>
          )

        case "dm":
          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)}
              {block?.dmBlockType === "search" ? (
                <BlockDMSearch search={block?.dmSearch}/>
              ) : (
                <>
                  {Symbol.iterator in Object(block?.dmBlocks) && block?.dmBlocks?.map((dmItem: any, j: number) => {
                    return <BlockDMSingle key={`dmblock${j}`} element={dmItem}/>
                  })}
                </>
              )}
            </React.Fragment>
          )

        case "minne":
          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)}
              <BlockMinne topicId={block?.topicId}/>
            </React.Fragment>
          )

        case "widget":
          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)}  
              <BlockWidget html={block?.code}/>
            </React.Fragment>
          )

        case "quote":
          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)}  
              <BlockQuote quote={block?.quote} byline={block?.byline}/>
            </React.Fragment>
          )

        case "tasks":
          return (
            <React.Fragment key={`block${i}`}>
              {block?.showTitle && (<BlockTitle title={block?.title} level="h2"/>)} 
              <BlockTasks taskList={block?.taskItems}/> 
            </React.Fragment>
          )

        case "byline":
          return (
            <React.Fragment key={`block${i}`}>
              <BlockByline data={block?.byline}/> 
            </React.Fragment>
          )

        case "anchor":
          return (
            <React.Fragment key={`block${i}`}>
              <BlockAnchor id={`anchor${i}`}/>
              {block?.showTitle && (<BlockTitle title={block?.title} level="anchor"/>)}  
            </React.Fragment>
          )

        default:
          return <BlockError key={`block${i}`} text={`Unknown block: ${block?.blockType}`}/>
      }})}
    </>
  )
}

export default Blocks;