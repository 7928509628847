import i18next from "i18next";
import Api from "../services/Api";
import { CREDITS } from "../Settings";

/**
 * Get media object URL
 * @param {string} dmsId DMS ID
 * @param {string} dimension Dimension. Eg 1200x1200.
 * @param {string} property Property to be returned. Default: src
 * @returns {string | null} URL
 */
 export const getMediaUrl = async (mediaId?: string, dimension?: string, property?: string) => {
  if (!mediaId) { 
    return null; 
  }

  const metadata = await Api.getMedia(mediaId);
  if (!metadata) {
    return null;
  }

  let urlTemplate = "";
  if (property) {
    // Return specific property and remove querystring if found
    urlTemplate = metadata[property].split("?")[0];
  } else {
    // Return default media source
    urlTemplate = metadata?.src;
  }

  if (dimension) {
    urlTemplate += `?dimension=${dimension}`;
  }

  return urlTemplate;
}

/**
 * Merge media object with media URL from the API
 * @param mediaObject {object} Media object. Eg { mediaId: 123, focalPoint: { x: 12, y: 23 }}
 * @param {string} dimension Dimension. Eg 1200x1200.
 * @returns {object} Merged media object with URL
 */
export const getMediaObject = async (mediaObject: any, dimension?: string) => {
  const metadata = await Api.getMedia(mediaObject?.mediaId);
  let url = {};

  // Get URLs
  switch (metadata?.media_type) {
    case "image":
      url = { url: `${metadata?.src}${dimension ? '?dimension='+dimension : ''}`, thumbUrl: metadata?.thumbnail_src }
      break;
    case "video":
      let thumbOverrideUrl;
      if (metadata?.thumbnail_override) {
        thumbOverrideUrl = await getMediaUrl(metadata?.thumbnail_override?.mediaId);
      }
      url = { url: metadata?.src, thumbUrl: thumbOverrideUrl || metadata?.thumbnail_src }
      break;
    case "audio":
      url = { url: metadata?.src }
      break;
    case "youtube":
    case "vimeo":
    case "sketchfab":
      url = { thumbUrl: metadata?.thumbnail_src }
      break;
  }
  
  return {...mediaObject, ...url, ...metadata}
}

/**
 * Parse credits to be used below media objects and in lightbox modals
 * @param {object} credits Credit list from the media API
 * @returns {string} Stringifies list
 */
export const parseCredits = (credits: any) => {
  const creditTypes = CREDITS;

  let creditList: any = [];
  credits?.forEach((credit, i) => {
    if (credit?.name) {
      creditList.push(`${i18next.t(creditTypes[credit?.credit_type_id])}: ${credit?.name}`);
    }
  });
  return creditList?.join(". ");
}

/**
 * Fade in image when loaded
 * @param e ChangeEvent
 */
 export const showImageWhenLoaded = (e: React.ChangeEvent<HTMLImageElement>) => {
  e.target.classList.remove("loading");
  e.target.style.opacity = "1";
  e.target.style.visibility = "visible";
  if (e.target.parentElement) {
    e.target.parentElement.style.backgroundColor = "transparent";
    e.target.parentElement.classList.remove("loading");
  }
}

/**
 * Generate CSS filters from object
 * @param filters Filters. Example: { contrast: 1, brightness: 0.5 }
 * @returns {string} Filter CSS. Example: "contrast(1) brightness(0.5)"
 */
export const generateCSSFilters = (filters: any) => {
  if (!filters) { return undefined; }
  
  let cssFilter: string[] = [];

  for (const prop in filters) {
    if (filters?.[prop]) {
      cssFilter?.push(`${prop}(${filters?.[prop]})`);
    }
  }

  return cssFilter?.join(" ");
}