import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

interface AnchorNavigationProps {
  blocks: any
}

const AnchorWrapper = styled.nav`
  display: flex;
  justify-content: flex-start;
  list-style: none;
  white-space: nowrap;
  overflow: scroll;
  margin: 0 0 15px;
  padding: 0 25px 15px;
  float: left;
  width: 100%;
  user-select: none;

  @media (min-width: 1000px) {
    /* display: block;

    & li {
      margin: 5px;
    }*/
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Anchor = styled.button`
  cursor: pointer;
  background: ${p => p.theme.accentColor};
  color: #fff;
  border-radius: 40px;
  padding: 7.5px 15px;
  float: left;
  margin-right: 10px;
  font-weight: bold;
  font-size: .9em;
  pointer-events: all !important;
  border: none;

  &:last-of-type {
    margin-right: 0;
  }

  &:active {
    opacity: .9;
  }
`;

/**
 * Render an anchor navigation
 * @returns {JSX.Element} Component template
 */
const AnchorNavigation: FC<AnchorNavigationProps> = ({blocks}) => {
  const [anchors, setAnchors] = useState<any>([]);

  // Navigate to anchor
  const goToAnchor = useCallback((index: number) => {
    document?.getElementById(`anchor${index}`)?.scrollIntoView();
  }, []);

  // Get anchors from blocks
  useEffect(() => {
    // Append index. Used for IDs
    const blocksWithIndex = blocks?.map((block: any, i: number) => { return {...block, ...{index: i} } });

    setAnchors(blocksWithIndex?.filter((block: any) => block.blockType === "anchor"));
  }, [blocks]);

  if (anchors?.length < 2) { return null; }

  return (
    <AnchorWrapper>
      {anchors?.map((anchor: any, i: number) => {
        if (anchor?.title?.length > 0) {
          return <Anchor key={`anchor${i}`} onClick={() => { goToAnchor(anchor?.index) }}>{anchor.title}</Anchor>
        } else {
          return null;
        }
      })}
    </AnchorWrapper>
  )
}

export default AnchorNavigation;