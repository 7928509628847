import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import { ReactComponent as CheckIcon } from "../assets/icon_check.svg";
import { AppContext } from '../contexts/AppContext';
import { getCookie, setCookie } from '../common/Cookies';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, .5);
`;

const Popup = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: 21;
  padding: 20px;
  background-color: #fff;
  color: #000;
  border-radius: 4px;
  margin: 0 auto;
  max-width: 500px;

  & a {
    color: #fff;
  }
`;

const Text = styled.p`
  margin: 10px 0 35px;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * Render the cookie popup notice
 * @returns {JSX.Element} Component template
 */
const CookiePopup: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDesktop, previewIsActive } = useContext(AppContext);
  const [accepted, setAccepted] = useState<boolean>();

  const acceptStatement = useCallback(() => {
    setCookie("privacy", JSON.stringify({}));
    setAccepted(true);
  }, []);

  const readMore = useCallback(() => {
    setCookie("privacy", JSON.stringify({}));
    setAccepted(true);
    navigate("/privacy");
  }, [navigate]);

  useEffect(() => {
    if (getCookie("privacy")) {
      setAccepted(true);
    }
  }, []);

  if (!(accepted === false) || previewIsActive) { return null; }

  return (
    <Wrapper>
      <Popup className={isDesktop ? "desktop" : undefined}>
        <Text>{t("privacy.popup.body")}</Text>
        <Toolbar>
          <Button layout="transparent" onClick={readMore}>{t("privacy.popup.readMore")}</Button>
          <Button layout="filled" onClick={acceptStatement}><CheckIcon/>{t("privacy.popup.accept")}</Button>
        </Toolbar>
      </Popup>
    </Wrapper>
  )
}

export default CookiePopup;