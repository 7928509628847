import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getTranslation } from '../common/Translation';
import BlockTitle from '../components/BlockTitle';
import ErrorMessage from '../components/ErrorMessage';
import { VListItem, VListPlaceholderItem, VListWrapper } from '../components/ListVertical';
import PageTransition from '../components/PageTransition';
import Pagination from '../components/Pagination';
import SearchBar  from '../components/SearchBar';
import { TabBarWrapper, TabBarItem } from '../components/TabButtons';
import { AppContext } from '../contexts/AppContext';
import Header from '../framework/Header';
import Api from '../services/Api';
import { SCHEMA_ARTICLE, SCHEMA_PRESENTATION } from '../Settings';

const SearchBarElement = styled(SearchBar)`
  background-color: #fff;

  & input {
    background-color: #fff;
  }
`;

const NoHits = styled.p`
  margin: 0 25px 30px;
`;

/**
 * Render the search view
 * @returns {JSX.Element} Component template
 */
const SearchView: FC = () => {
  const pageSize = 10;
  const { t } = useTranslation();
  const { search } = useLocation();
  const { currentLanguage } = useContext(AppContext);
  const inputRef = useRef<HTMLInputElement>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentTab, setCurrentTab] = useState<string>("articles");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [articleList, setArticleList] = useState<any>();
  const [presentationList, setPresentationList] = useState<any>();
  const [ownerList, setOwnerList] = useState<any>();
  const [articlePage, setArticlePage] = useState<any>(1);
  const [presentationPage, setPresentationPage] = useState<any>(1);
  const [ownerPage, setOwnerPage] = useState<any>(1);
  const [loadedArticlePage, setLoadedArticlePage] = useState<any>();
  const [loadedPresentationPage, setLoadedPresentationPage] = useState<any>();
  const [loadedOwnerPage, setLoadedOwnerPage] = useState<any>();
  const [presentationImages, setPresentationImages] = useState<any>([]);
  const [error, setError] = useState<any>();

  /**
   * Fetch presentation images from articles - and store in presentationImages state
   * @param {object} presentations List of presentations
   */
   const fetchPresentationImages = useCallback((presentations: any) => {
    presentations?.items?.[0] && presentations?.items?.forEach(async presentation => {
      let imageUrl: string = "#";

      if (presentation?.image_url) {
        // Get presentation image - if it exists
        imageUrl = presentation?.image_url; 
      } else {
        // Get first article
        const articleId = presentation?.content?.articles?.[0]?.to_document_id;
        if (!articleId) { return; }
    
        await Api.getDocumentHeadList([articleId]).then((articleData: any) => {
          imageUrl = articleData?.[0]?.image_src;
        });
      }
  
      let presentationImageList = presentationImages;
      presentationImageList[presentation?.id] = imageUrl;
  
      setPresentationImages(presentationImageList);
    });
  }, [presentationImages]);

  /**
   * Fetch articles from API
   * @param {number} start Start item. Default: 0
   * @param {number} count Number of items to be returned. Default: 10
   */
   const loadArticles = useCallback((start?: number, count?: number) => {
    if (searchQuery && searchQuery.length > 2) {
      setIsLoading(true);
      setArticleList({...articleList, ...{items: null}});
      Api.getDocumentList({ search: searchQuery, schema_ids: SCHEMA_ARTICLE, referenced_from_schema_id: SCHEMA_PRESENTATION, page: start || 0, page_size: count || 10}).then(docList => {
        setIsLoading(false);
        setArticleList(docList);
      }).catch(e => {
        setError(e);
      });;
    }
  }, [articleList, setIsLoading, searchQuery]);

    /**
   * Fetch presentations from API
   * @param {number} start Start item. Default: 0
   * @param {number} count Number of items to be returned. Default: 10
   */
     const loadPresentations = useCallback((start?: number, count?: number) => {
      if (searchQuery && searchQuery.length > 2) {
        setPresentationList({...presentationList, ...{items: null}});
        Api.getDocumentList({ search: searchQuery, schema_ids: SCHEMA_PRESENTATION, page: start || 0, page_size: count || 10, include_no_location: "true"}).then(docList => {
          setPresentationList(docList);
          fetchPresentationImages(docList)
        }).catch(e => {
          setError(e);
        });;
      }
    }, [presentationList, searchQuery, fetchPresentationImages]);

  /**
   * Fetch owners from API
   * @param {number} start Start item. Default: 0
   * @param {number} count Number of items to be returned. Default: 10
   */
   const loadOwners = useCallback((start?: number, count?: number) => {
    if (searchQuery && searchQuery.length > 2) {
      setIsLoading(true);
      setOwnerList({...ownerList, ...{items: null}});
      Api.getOwnerList({ search: searchQuery, schema_ids: SCHEMA_ARTICLE, page: start || 0, page_size: count || 10}).then(docList => {
        setIsLoading(false);
        setOwnerList(docList);
      }).catch(e => {
        setError(e);
      });;
    }
  }, [ownerList, setIsLoading, searchQuery]);

  /**
   * Change tab on click
   * @param {string} Tab identifier
   */
   const setTab = useCallback((tab: string) => {
    setCurrentTab(tab);
  }, []);

  // Catch query string param
  useEffect(() => {
    const params = new URLSearchParams(search);
    const query = params.get('q') || "";

    setSearchQuery(query);
    setLoadedArticlePage(0);
    setArticlePage(1);
    setArticleList({});
    setLoadedPresentationPage(0);
    setPresentationPage(1);
    setPresentationList({});
    setLoadedOwnerPage(0);
    setOwnerPage(1);
    setOwnerList({});
    if (inputRef.current) {
      inputRef.current.value = query;
    }
  }, [search]);

  // Search param received or page changed - article page
  useEffect(() => {
    if (searchQuery.length > 0 && articlePage !== loadedArticlePage) {
      loadArticles((articlePage - 1), pageSize);
      setLoadedArticlePage(articlePage);
    }
  }, [searchQuery, loadArticles, articlePage, pageSize, loadedArticlePage]);

  // Search param received or page changed - presentation page
  useEffect(() => {
    if (searchQuery.length > 0 && presentationPage !== loadedPresentationPage) {
      loadPresentations((presentationPage - 1), pageSize);
      setLoadedPresentationPage(presentationPage);
    }
  }, [searchQuery, loadPresentations, presentationPage, pageSize, loadedPresentationPage]);

  // Search param received or page changed - owner page
  useEffect(() => {
    if (searchQuery.length > 0 && ownerPage !== loadedOwnerPage) {
      loadOwners((ownerPage - 1), pageSize);
      setLoadedOwnerPage(ownerPage);
    }
  }, [searchQuery, loadOwners, ownerPage, pageSize, loadedOwnerPage]);

  // Update title
  useEffect(() => {
    document.title = `${t("search.placeholder")} - KulturPunkt`;
  }, [t]);

  // Display error - if data could not be fetched
  if (error) {
    return (
      <>
        <Header/>
        <ErrorMessage message={t(error?.message)} tryAgain={true}/>
      </>
    );
  }

  return (
    <>
      <Header showProgress={isLoading}/>
      <PageTransition>
        <BlockTitle level="h1" title={t("search.placeholder")}/>
        <SearchBarElement inputRef={inputRef}/>
        <>
          {searchQuery.length > 0 && (
            <>
              { (articleList?.total_count === 0 && presentationList?.total_count === 0 && ownerList?.total_count === 0) ? (
                <NoHits>{t("search.noHits")}</NoHits>
              ) : (
                <TabBarWrapper>
                  { (articleList?.total_count > 0 || ownerList?.total_count > 0) && (<TabBarItem label={t("list.articles")} isActive={currentTab === "articles"} hits={articleList?.total_count} onClick={() => setTab("articles")}/>)}
                  { presentationList?.total_count > 0 && (<TabBarItem label={t("list.presentations")} isActive={currentTab === "presentations"} hits={presentationList?.total_count} onClick={() => setTab("presentations")}/>)}
                  { ownerList?.total_count > 0 && (<TabBarItem label={t("list.museums")} isActive={currentTab === "museums"} hits={ownerList?.total_count} onClick={() => setTab("museums")}/>)}
              </TabBarWrapper>
              )}

              {currentTab === "articles" && (
                <>
                  <VListWrapper>
                    {Symbol.iterator in Object(articleList?.items) && articleList?.items?.map((item, i) => {
                      return <VListItem key={`item${i}`} media={{url: item?.image_url, media_type: "image", ...item?.content?.general?.image}} title={getTranslation(item?.content?.general?.title, currentLanguage)} description={getTranslation(item?.content?.general?.listteaser, currentLanguage)} location={item?.location} owner={item?.owner_name} link={item?.id && `/article/${item?.id}`}/>
                    })}
                    {searchQuery?.length > 2 && !articleList?.items && (
                      <VListPlaceholderItem count={10}/>
                    )}
                  </VListWrapper>

                  <Pagination currentPage={articlePage} itemCount={articleList?.total_count} pageSize={pageSize} setCurrentPage={setArticlePage}  />
                </>
              )}

              {currentTab === "presentations" && (
                <>
                  <VListWrapper>
                    {Symbol.iterator in Object(presentationList?.items) && presentationList?.items?.map((item, i) => {
                      return <VListItem key={`item${i}`} media={{url: item?.image_src || presentationImages?.[item?.id], media_type: "image", ...item?.content?.general?.image}} title={getTranslation(item?.content?.general?.title, currentLanguage)} description={getTranslation(item?.content?.general?.listteaser, currentLanguage)} location={item?.location} owner={item?.owner_name} link={item?.id && `/group/${item?.id}`}/>
                    })}
                    {searchQuery?.length > 2 && !presentationList?.items && (
                      <VListPlaceholderItem count={10}/>
                    )}
                  </VListWrapper>

                  <Pagination currentPage={presentationPage} itemCount={presentationList?.total_count} pageSize={pageSize} setCurrentPage={setPresentationPage}  />
                </>
              )}

              {currentTab === "museums" && (
                <>
                <VListWrapper>
                  {Symbol.iterator in Object(ownerList?.items) && ownerList?.items?.map((item: any, i: number) => {
                    return <VListItem key={`item${i}`} profile={item} title={item?.name} location={item?.location} link={item?.id && `/owner/${item?.id}`}/>
                  })}
                  {searchQuery?.length > 2 && !ownerList?.items && (
                    <VListPlaceholderItem count={10}/>
                  )}
                </VListWrapper>

                <Pagination currentPage={ownerPage} itemCount={ownerList?.total_count} pageSize={pageSize} setCurrentPage={setOwnerPage}  />
              </>
              )}
            </>
          )}
        </>
      </PageTransition>
    </>
  );
}

export default SearchView;