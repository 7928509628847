import { FC } from 'react';
import styled from 'styled-components';
import NewWindowIcon from "../assets/icon_new_window.svg";

interface BlockLinkItemProps {
  url: string,
  title?: string
}

export const BlockLinksWrapper = styled.ul`
  margin: 0 0 30px 25px;
  padding-left: 25px;
  float: left;
  width: 100%;
  display: block;
  list-style: square;

  &:empty {
    display: none;
  }
`;

const BlockLink = styled.li`
  padding: 2px 0;

  & a {
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: 12px;
    background-image: url(${NewWindowIcon});
    padding-right: 20px;
  }
`;

/**
 * Render a link block
 * @returns {JSX.Element} Component template
 */
 export const BlockLinkItem: FC<BlockLinkItemProps> = ({url, title}) => {

  return (
    <BlockLink>
      <a href={url} target="_blank" rel="noreferrer" >{title || url}</a>
    </BlockLink>
  );
}