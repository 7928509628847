import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/'; // import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "./i18n/config";
import { I18nextProvider } from "react-i18next";
import { HelmetProvider } from 'react-helmet-async';
import { isIE } from './common/Browsers';
import { IS_PWA } from './Settings';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const helmetContext = {};
//const container = document.getElementById('root');
//const root = createRoot(container);

if (isIE()) {
  ReactDOM.render(
    <p style={{margin: "10px"}}>Internet Explorer is not supported by KulturPunkt. Please use a modern browser.</p>, document.getElementById('root'));
} else {
  // Render application
  ReactDOM.render(
    <HelmetProvider context={helmetContext}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </HelmetProvider>, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (IS_PWA) {
  serviceWorkerRegistration.register();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
