import { FC, useCallback, useContext } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { FONTSIZES, LANGUAGES } from '../Settings';
import { AppContext } from '../contexts/AppContext';
import NewWindowIcon from "../assets/icon_new_window.svg";
import HomeIcon from "../assets/tabbar_home.svg";
import ExploreIcon from "../assets/tabbar_explore.svg";
import LoginIcon from "../assets/icon_login.svg";
import FavouritesIcon from "../assets/icon_favourites.svg";
import LanguageIcon from "../assets/icon_language.svg";
import FontSizeIcon from "../assets/icon_fontsize.svg";
import AboutIcon from "../assets/icon_about.svg";
import PrivacyIcon from "../assets/icon_privacy.svg";
import ChevronUp from "../assets/chevron_up.svg";
import ChevronDown from "../assets/chevron_down.svg";
import { Link, useLocation } from 'react-router-dom';

export interface MenuProps {
  close?: () => void
}

const MenuItems = styled.ul`
  padding: 20px 0;
  margin: 0;
  list-style: none;

  &.mobile {
    margin-top: -40px;
  }

  &.desktop {
    margin-top: auto;
  }

  & li a, & li button {
    text-decoration: none;
    color: #000;
    font-size: 1em;
    background-position: 25px 50%;
    background-repeat: no-repeat;
    padding: 15px 25px 15px 65px;
    display: block;
    border: none;
    display: block;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    display: inline-flex;
  }

  & li.active a {
    color: ${p => p.theme.accentColor};
  }

  & li a:active, & li button:active {
    background-color: ${p => p.theme.accentColor}50;
    border-radius: 3px;
  }

  & li a.active, & li button.active {
    font-weight: bold;
  }

  & li a.home {
    background-image: url(${HomeIcon});
  }

  & li a.explore {
    background-image: url(${ExploreIcon});
  }

  & li a.login {
    background-image: url(${LoginIcon});
  }

  & li a.favourites {
    background-image: url(${FavouritesIcon});
  }

  & li button.language {
    background-image: url(${LanguageIcon});
  }

  & li button.fontsize {
    background-image: url(${FontSizeIcon});
  }

  & li a.about {
    background-image: url(${AboutIcon});
  }

  & li a.privacy {
    background-image: url(${PrivacyIcon});
  }

  & li ul {
    max-height: 0;
    overflow:hidden;
    transition: max-height .5s;
  }

  & li.expanded ul {
    max-height: 100vh;
  }

  & > li.sub {
    background-position: calc(100% - 25px) 12px;
    background-repeat: no-repeat;
    background-image: url(${ChevronDown});
  }

  & > li.sub.expanded {
    background-image: url(${ChevronUp});
  }

  & > li.sub.expanded ul {
    border-top: 1px solid ${p => p.theme.separatorColor};
    border-bottom: 1px solid ${p => p.theme.separatorColor};
  }

  & > li.new {
    background-position: calc(100% - 30px) 50%;
    background-repeat: no-repeat;
    background-size: 12px;
    background-image: url(${NewWindowIcon});
  }
`

const SubmenuItems = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  & a {
    padding: 0 20px;
  }
`;

/**
 * Render the app menu
 * @returns {JSX.Element} Component template
 */
const Menu: FC<MenuProps> = ({close}) => {
  const { t } = useTranslation();
  const currentPage = useLocation()?.pathname;
  const { currentLanguage, setCurrentLanguage, currentFontSize, setCurrentFontSize, isDesktop } = useContext(AppContext);

  /**
   * Toggle sub menu on click
   * @param e Event
   */
  const toggleSubmenu = useCallback((e: any) => {
    const classList = e?.target?.parentElement?.classList;
    if (classList.contains("expanded")) {
      classList.remove("expanded");
      e?.target?.parentElement?.querySelector("ul")?.setAttribute("aria-expanded", "false");
      e?.target?.parentElement?.querySelectorAll("li > button")?.forEach(item => {
        item?.setAttribute("tabIndex", -1);
      });
    } else {
      document.querySelectorAll(".sub").forEach(item => {
        item?.classList.remove("expanded");
        e?.target?.parentElement?.querySelector("ul")?.setAttribute("aria-expanded", "true");
      });
      e?.target?.parentElement?.querySelectorAll("li > button")?.forEach(item => {
        item?.setAttribute("tabIndex", 0);
      });
      classList.add("expanded");
    }
    
    return false;
  }, []);

  /**
   * Close sidebar
   */
  const closeMenu = useCallback(() => {
    if (close) {
      close();
    }
  }, [close]);

  return (
    <>
      <MenuItems role="menubar">
        {isDesktop ? (
          <>
            <li className={currentPage === "/" ? "active" : ""}><Link role="menuitem" to="/" className="home">{t("tabs.home")}</Link></li>
            <li className={currentPage.startsWith("/explore") ? "active" : ""}><Link role="menuitem" to="/explore/map" className="explore">{t("tabs.explore")}</Link></li>
          </>
        ) : (
          <>
            <li style={{display: "none"}} className={currentPage.startsWith("/login") ? "active" : ""}><Link role="menuitem" to="/login" className="login" onClick={closeMenu} >{t("menu.login")}</Link></li>
            <li style={{display: "none"}} className={currentPage.startsWith("/favourites") ? "active" : ""}><Link role="menuitem" to="/favourites" className="favourites" onClick={closeMenu}>{t("menu.favourites")}</Link></li>
          </>
        )}

        <li className="sub">
          <button className="language" role="menuitem" aria-expanded="false" aria-owns="languagemenu" onClick={toggleSubmenu}>{t("menu.language")}</button>
          <SubmenuItems id="languagemenu" role="group">
            {LANGUAGES?.map((language, i) => {
              return <li key={`lang${i}`}><button tabIndex={-1} role="menuitem" className={language?.key === currentLanguage ? "active" : undefined} onClick={() => setCurrentLanguage(language?.key)}>{language?.name}</button></li>
            })}
          </SubmenuItems>
        </li>

        {!isDesktop && (
          <li className="sub">
            <button className="fontsize" role="menuitem" aria-expanded="false" aria-owns="fontsizemenu" onClick={toggleSubmenu}>{t("menu.fontsize")}</button>
            <SubmenuItems id="fontsizemenu" role="group">
              {FONTSIZES?.map((fontsize, i) => {
                return <li key={`fontsize${i}`}><button tabIndex={-1} role="menuitem" className={fontsize?.size === currentFontSize ? "active" : undefined} onClick={() => setCurrentFontSize(fontsize?.size)}>{t(fontsize?.name)}</button></li>
              })}
            </SubmenuItems>
          </li>
        )}

      </MenuItems>
      <MenuItems role="menu" className={isDesktop ? "desktop" : "mobile"}>
        <li className={currentPage.startsWith("/about") ? "active" : undefined}><Link role="menuitem" to="/about" className="about" onClick={closeMenu}>{t("menu.about")}</Link></li>
        <li className={currentPage.startsWith("/privacy") ? "active" : undefined}><Link role="menuitem" to="/privacy" className="privacy" onClick={closeMenu}>{t("menu.privacy")}</Link></li>
      </MenuItems>
    </>
  )
}
export default Menu;