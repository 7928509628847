import { FC, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../common/Translation';
import BlockTitle from './BlockTitle';
import { HListItem, HListWrapper } from './ListHorizontal';
import styled from 'styled-components';
import useOnScreen from '../hooks/useOnScreen';
import Api from '../services/Api';

interface PresentationReferencesProps {
  presentationId?: number|string|undefined,
  articleId?: number|string|undefined
}

const Wrapper = styled.div``;

/**
 * Render a list of references for a specified article in a specific presentation
 * @returns {JSX.Element} Component template
 */
export const PresentationReferences: FC<PresentationReferencesProps> = ({presentationId, articleId}) => {
  const { t } = useTranslation();
  const { currentLanguage, presentationCache } = useContext(AppContext);
  const linkRef = useRef<HTMLDivElement>(null);
  const [linkIds, setLinkIds] = useState<any>([]);
  const [referenceList, setReferenceList] = useState<any>();
  const [linksInViewPort] = useOnScreen(linkRef, "0px 0px");

  // Collect link IDs from presentation cache
  useEffect(() => {
    if (!linksInViewPort || presentationId !== presentationCache?.id) { return; }

    const articleData = presentationCache?.articles?.list?.filter((record: any) => record?.document_id === Number(articleId));
    const linksArray = articleData?.[0]?.to?.map((link: any) => link?.document_id);
    setLinkIds(linksArray);
  }, [presentationCache, presentationId, articleId, linksInViewPort]);

  useEffect(() => {
    Api.getDocumentHeadList(linkIds).then(articleList => {

      // Sort and show only published items
      let publishedReferenceList: any[] = [];
      linkIds?.forEach((id: any, i: number) => {
        articleList.forEach((item:any) => {
          if (item?.document_id === id && item?.status?.includes("published")) {
            let mergedItem = item;

            // Push to list
            publishedReferenceList.push(mergedItem);
          }
        });
      });

      setReferenceList(publishedReferenceList);
    });
  }, [linkIds]);

  if (!presentationCache || !referenceList || referenceList?.length === 0) { return <Wrapper ref={linkRef}/>; }

  return (
    <Wrapper ref={linkRef}>
      <BlockTitle title={t("article.alsoSee")} level="h2"/>
      <HListWrapper backgroundColor='#fff' count={referenceList?.length}>
        {referenceList?.map((item: any, i: number) => {
          return <HListItem key={`reference${i}`} media={{url: item?.image_src, media_type: "image"}} title={getTranslation(item?.title, currentLanguage)} location={item?.location} link={item?.document_id && `/group/${presentationId}/article/${item?.document_id}`}/>
        })}
      </HListWrapper>
    </Wrapper>
  );
}

export default PresentationReferences;