import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Circle, useMapEvents } from 'react-leaflet'
import L, { Icon, LatLngExpression } from 'leaflet'
import MarkerClusterGroup from '@changey/react-leaflet-markercluster';
import { AppContext } from '../contexts/AppContext';
import { LocationContext } from '../contexts/LocationContext';
import styled from 'styled-components';
import MapPopup from '../components/MapPopup';
import CurrentMarkerIcon from '../assets/marker_currentposition.svg';
import CurrentMarkerButton from '../assets/icon_currentposition.svg';
import 'leaflet/dist/leaflet.css';

interface MapProps {
  children: JSX.Element,
  active: boolean,
  cluster: boolean,
  setMapIsLoaded: any,
  popupData: any,
  setPopupData: any,
  map: any,
  setMap: any,
  mapIsCentered: boolean,
  setMapIsCentered: any,
  panCurrentLocation?: boolean
}

const Wrapper = styled.div.attrs((p: any) => ({
  visible: p.visible
}))`
  visibility: ${p => p.visible? "visible" : "hidden"};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const MapInstance = styled(MapContainer)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
`;

const Tiles = styled(TileLayer)`
  filter: grayscale(50%);
`;

const PositionButton = styled.button.attrs((p: any) => ({
  showAbovePopup: p.showAbovePopup || false,
}))`
  position: fixed;
  right: 25px;
  bottom: ${p => p.showAbovePopup ? "205px" : "100px"};
  background: transparent url(${CurrentMarkerButton}) no-repeat 0 0;
  background-size: 100%;
  width: 45px;
  height: 45px;
  z-index: 30;
  transition: bottom .1s;
  cursor: pointer;
  border: none;
  border-radius: 50%;

  &.desktop {
    right: 40px;
    bottom: 40px !important;
  }
`;

/**
 * Generate cluster icon. Set size based on number of markers
 * @param {object} cluster Cluster element 
 * @returns {object} Leaflet icon
 */
const clusterIcon = (cluster: any) => {
  const count = cluster.getChildCount();
  let size = 0;

  switch(true) {
    case (count > 50):
      size = 100;
      break;
    case (count > 40):
      size = 90;
      break;
    case (count > 30):
      size = 80;
      break;
    case (count > 20):
      size = 70;
      break;
    case (count > 10):
      size = 60;
      break;
    case (count > 5):
      size = 50;
        break;
    default:
      size = 40;
      break;
  }

  return L.divIcon({
    html: cluster.getChildCount(),
    className: 'mapcluster',
    iconSize: L.point(size, size, true)
  });
};

// Define icons
const currentMarker = new Icon({ iconUrl: CurrentMarkerIcon, iconSize: [25, 25] });

/**
 * Render a map
 * @returns {JSX.Element} Component template
 */
const Map: FC<MapProps> = ({children, active, cluster, map, setMap, popupData, setPopupData, setMapIsLoaded, mapIsCentered, setMapIsCentered, panCurrentLocation}) => {
  const startPosition: LatLngExpression = useMemo(() => { return [65, 17] }, []);
  const { isDesktop } = useContext(AppContext);
  const { geoLocation } = useContext(LocationContext);
  const tilesRef = useRef<any>(null);
  const [currentPosition, setCurrentPosition] = useState<LatLngExpression>();

  /**
  * Make popup hide when user clicks on map tiles
  * @returns {JSX.Element} Component template
  */
  const DisablePopupOnClick = () => {
    useMapEvents({
      click(e) {
        unfocusMarkers();
        setPopupData(undefined);
      }
    });
    return null;
  }

  /**
   * Remove active class from all markers
   */
  const unfocusMarkers = useCallback(() => {
    const markerList = document.querySelectorAll(".leaflet-marker-icon");
    markerList.forEach(item => {
      item?.classList?.remove("active");
    });
    setPopupData(undefined);
  }, [setPopupData]);

  // Unfocus all markers if list tab is clicked
  useEffect(() => {
    if (!active) {
      unfocusMarkers();
    }

  }, [active, unfocusMarkers]);

  /**
  * Get user position - if available
  */
  useEffect(() => {
    if (geoLocation?.lat) {
      setCurrentPosition([geoLocation?.lat, geoLocation?.lng]);
    }
  }, [geoLocation]);

  /**
  * Pan to user location on startup - if available. Run only once.
  */
  useEffect(() => {
    if (currentPosition && !mapIsCentered && panCurrentLocation) {
      setTimeout(() => {
        map?.flyTo(currentPosition, 11, {
          "animate": true,
          "duration": 0.5
        });
        setMapIsCentered(true);
      }, 1000);
    }
  }, [currentPosition, map, mapIsCentered, setMapIsCentered, panCurrentLocation]);

  // Invalidate map size when map container becomes active
  useEffect(() => {
    if (active && map) {
      map?.invalidateSize();
      unfocusMarkers();
      window?.scrollTo(0, 0);
    }
  }, [map, active, unfocusMarkers]);

  /**
  * Pan the map to the user's current location
  */
   const panToCurrentLocation = useCallback(() => {
     unfocusMarkers();
    if (currentPosition) {
      map?.setView(currentPosition, map?.getZoom());
    }
  }, [currentPosition, map, unfocusMarkers]);

  /**
  * Render current position
  * @returns {JSX.Element} Component template
  */
  const renderCurrentPosition = useMemo(() => {
    if (!currentPosition || !geoLocation?.accuracy) { return; }
    return (
      <>
        <Marker position={currentPosition} icon={currentMarker} aria-hidden="true"/>
        <Circle center={currentPosition} radius={geoLocation?.accuracy} pathOptions={{ fillColor: '#4A90E2' }} opacity={0} fillOpacity={.3} aria-hidden="true"/>
      </>
    );
  }, [currentPosition, geoLocation?.accuracy]);

  // Indicate that map is loaded 0,5 second after map render
  useEffect(() => {
    if (map) {
      setTimeout(() => {
        setMapIsLoaded(true);
      }, 500);
    }
  }, [map, setMapIsLoaded]);

  return (
    <Wrapper visible={active}>
      <MapInstance center={startPosition} zoom={4} ref={setMap} preferCanvas={true}>
        <Tiles url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" ref={tilesRef}/>
        <DisablePopupOnClick/>

        {cluster ? (
          <MarkerClusterGroup showCoverageOnHover={false} spiderfyDistanceMultiplier={2} iconCreateFunction={clusterIcon} animateAddingMarkers={true}>
            {children}
          </MarkerClusterGroup>
        ) : (
          <>{children}</>
        )}
        {renderCurrentPosition}
      </MapInstance>

      {currentPosition && (<PositionButton onClick={panToCurrentLocation} showAbovePopup={Boolean(popupData)} className={isDesktop ? "desktop" : undefined}/>)}

      <MapPopup id={popupData?.id} type={popupData?.type} presentationId={popupData?.presentationId}/>
    </Wrapper>
  );
}
export default Map;