/**
 * Initialize GPS watcher. Provide current location to the 'geoLocation' global state
 */
 export const initGPS = (setGeoLocation: any) => {
  if (navigator && navigator.geolocation) {
    let lat: number, lng: number;
    const watcher = navigator.geolocation.watchPosition(pos => {
      const { latitude, longitude, accuracy } = pos?.coords;
      lat = latitude; lng = longitude;
      setGeoLocation({lat: latitude, lng: longitude, accuracy: accuracy });
    }, err => {
      console.error("Map error: ", err.message);
      if (!isNaN(lat) && !isNaN(lng)) {
        setGeoLocation({lat: lat, lng: lng, error: err.message, accuracy: 1});
      } else {
        setGeoLocation({error: err.message});
      }
    }, { maximumAge: 0, timeout: 10000, enableHighAccuracy: true });

    return () => navigator.geolocation.clearWatch(watcher)
  }
}

/**
 * Calculate distance between two locations in meters
 * @param source {array} Source latitude and longitude
 * @param destination {array} Destination latitude and longitude
 * @param omitUnit {boolean} Omit unit (m, km) in output
 * @returns {number} Distance in meters
 */
 export const calculateDistance = (source: number[], destination: number[], omitUnit?: boolean) => {
  if (typeof source === "undefined" || typeof destination === "undefined" || typeof source?.[0] === "undefined" || typeof destination?.[0] === "undefined") return null;

  // Converts numeric degrees to radians
  const toRad = (val: number) => {
    return val * Math.PI / 180;
  }

  const dLat = toRad(destination[0]-source[0]);
  const dLon = toRad(destination[1]-source[1]);
  const lat1 = toRad(destination[0]);
  const lat2 = toRad(source[0]);

  let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  let d = 6371000 * c;
  let result = "";

  // Return without units
  if (omitUnit) {
    return d.toString();
  }

  // Add units to output
  if (d > 1000) {
    // Kilometers
    result = (d/1000).toFixed(1) + " km";
  } else {
    // Meters
    result = Math.round(d) + " m";
  }
  return result;
}

/**
 * Order a list by distance from the current user
 * @param {object} list List to sort
 * @param {object} currentPosition Current position
 * @returns {object} Sorted list
 */
export const orderByDistance = (object: any, currentPosition: {lat: number, lng: number}) => {  
  if (!object) { return object; };
  let listWithPos: any = [];

  if (Symbol.iterator in Object(object?.items)) {
    object?.items?.forEach(item => {
      if (item?.location) {
        const distance = calculateDistance([currentPosition?.lat, currentPosition?.lng], [item.location?.lat, item.location?.lng], true);
        listWithPos.push({...item, ...{distance: Number(distance)}});
      } else {
        listWithPos.push({...item, ...{distance: 10000000}});
      }
     });
  }

  const orderedItems = listWithPos?.sort(dynamicSort(("distance"))) || object?.items;
  return {...object, ...{items: orderedItems}}
}

/**
 * Perform dynamic sort of arrays containing objects
 * @param {string} property Property name
 * @returns {function} 
 */
const dynamicSort = (property: string) => {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substring(1);
  }

  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}