import { FC, useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { VListWrapper, VListItem, VListPlaceholderItem } from '../components/ListVertical';
import styled, { keyframes } from 'styled-components';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import ErrorMessage from '../components/ErrorMessage';
import useFetchArticleList from '../hooks/useFetchArticleList';
import useIntersectObserver from '../hooks/useIntersectObserver';
import SpinnerIcon from '../assets/icon_spinner_tiny.svg';
import { trackOwner } from '../common/Analytics';

interface exploreListProps {
  active?: boolean,
  setIsLoading?: any
}

const SpinAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Loader = styled.div.attrs((p: any) => ({
  showSpinner: p.showSpinner || false
}))`
  width: 40px;
  height: 40px;
  background-image: url('${SpinnerIcon}');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  animation: ${SpinAnimation} 2s infinite linear;
  opacity: ${p => p.showSpinner ? "1" : "0"};
  clear: both;
  margin: 0 auto;
  transition: opacity .2s;
`;

const ListView = styled.div.attrs((p: any) => ({
  active: p.active || false
}))`
  display: ${p => p.active ? "inline" : "none"};
  margin-top: 20px;
`;

/**
 * Render the explore page
 * @returns {JSX.Element} Component template
 */
const ExploreArticleList: FC<exploreListProps> = ({active, setIsLoading}) => {
  const { t } = useTranslation();
  const { currentLanguage, ownerFilter } = useContext(AppContext);
  const intersectRef = useRef<HTMLDivElement>(null);
  const [articlePageNumber, incrementPageCount] = useReducer((state) => { return state + 1; }, 0);
  const [articleList, isLoading, error] = useFetchArticleList({ location: true, page_size: 20, page: articlePageNumber, owner_id: ownerFilter?.id }, { updateDistance: true, increment: true }, active);
  useIntersectObserver(!isLoading, intersectRef, incrementPageCount);

  // Is data being loaded?
  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  // Analytics tracker
  useEffect(() => {
    trackOwner(ownerFilter?.analyticsId);
  }, [ownerFilter?.analyticsId]);

  // Display error - if data could not be fetched
  if (error && active) {
    setIsLoading(false);
    return (
      <ListView active={active}>
        <ErrorMessage message={t(error?.message)} tryAgain={true}/>
      </ListView>
    );
  }

  return (
    <>
      <ListView active={active}>
        <VListWrapper>
          {Symbol.iterator in Object(articleList?.items) && articleList?.items?.map((item, i) => {
            return <VListItem key={`article${i}`} media={{url: item?.image_url, media_type: "image", ...item?.content?.general?.image}} title={getTranslation(item?.content?.general?.title, currentLanguage)} description={getTranslation(item?.content?.general?.listteaser, currentLanguage)} location={item?.location} owner={item?.owner_name} link={item?.id && `/article/${item?.id}`}/>
          })}

          {!articleList?.items && (
            <VListPlaceholderItem count={10}/>
          )}
        </VListWrapper>

        {articleList?.items && (
          <Loader ref={intersectRef} showSpinner={isLoading}/>
        )}
      </ListView>
    </>
  );
}

export default ExploreArticleList;