import { useEffect, useState } from "react"
import Api from "../services/Api"

/**
 * Fetch article from the API
 * @param {number|string|undefined} id Document ID 
 * @param {object} previewData Preview object
 * @returns {object} Article object
 */
export default function useFetchArticle(id: number|string|undefined, previewData?: any) {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastId, setLastId] = useState<number|string|undefined>();

  useEffect(() => {
    if (!id || lastId === id || previewData) { return; }
    setData({});
    setIsLoading(true);
    setLastId(id);

    Api.getDocument(String(id)).then(document => {
      setData(document);
      setIsLoading(false);
    }).catch(e => { 
      setError(e); 
    });
  }, [id, data, lastId, previewData]);

  useEffect(() => {
    if (previewData) {
      setData({content: previewData});
    }
  }, [previewData]);

  if (!id && !previewData) {
    return [null, null];
  }

  return [ data, isLoading, error ]
}