import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import MarkersIcon from "../assets/icon_markers.svg";
import ThemeIcon from "../assets/icon_theme.svg";
import { PlaceholderText } from './Placeholders';
import TextCrop from './TextCrop';

interface PresentationHeaderProps {
  title: string,
  ownerId?: number,
  ownerName?: string,
  preface?: string,
  articleCount?: number,
  presentationCount?: number
}

const Wrapper = styled.div`
  background-color: #e9ebee;
  width: 100%;
  padding: 30px 25px;
  text-align: center;
  transition: height 1s ease;
  border-radius: 4px;
  width: calc(100% - 20px);
  margin: 0 10px 10px 10px;
`;

const OwnerName = styled.a`
  font-weight: bold;
  color: ${p => p.theme.darkAccentColor};
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
  text-decoration: none;
`

const Title = styled.h1`
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
`

const Preface = styled.span`
  font-size: 1.1em;
  margin: 15px 0 0 0;
  display: block;
`

const Summary = styled.div`
  list-style: none;
  margin: 25px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: .9em;

  & button {
    float: left;
    font-size: 1em;
    border: none;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-color: transparent;
    cursor: pointer;
  }
`;

const SummaryArticles = styled.button`
  background-image: url(${MarkersIcon});
  padding-left: 25px;
  color: #000;
`;

const SummaryThemes = styled.button`
  background-image: url(${ThemeIcon});
  padding-left: 20px;
  color: #000;
`;

/**
 * Render an article header
 * @returns {JSX.Element} Component template
 */
const PresentationHeader: FC<PresentationHeaderProps> = ({title, ownerId, ownerName, preface, articleCount, presentationCount}) => {
  const { t } = useTranslation();

  /**
   * Scroll to element
   * @param {string} id Element ID
   */
  const scrollToElement = useCallback((id: string) => {
    var element = document.getElementById(id);   
    if (element) {
      window.scrollTo(0, element.offsetTop);
    }
  }, []);

  return (
    <Wrapper>
      {ownerName && (
        <OwnerName href={`/owner/${ownerId}`}>{ownerName || <PlaceholderText width="50" height="1em"/>}</OwnerName>
      )}
      <Title>{title || <PlaceholderText width="30" height="1.1em"/>}</Title>
      {preface && (<Preface><TextCrop text={preface}></TextCrop></Preface>)}
      {(Number(articleCount || 0) > 0) && (
        <Summary>
          {articleCount !==0 && (<SummaryArticles onClick={() => scrollToElement("articles")}>{`${articleCount} ${t("list.articles").toLowerCase()}`}</SummaryArticles>)}
          {presentationCount !== 0 && (<SummaryThemes onClick={() => scrollToElement("presentations")}>{`${presentationCount} ${t("list.presentations").toLowerCase()}`}</SummaryThemes>)}
        </Summary>
      )}
    </Wrapper>
  );
}

export default PresentationHeader;