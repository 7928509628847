import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HomeView from "./views/HomeView";
import NotFoundView from "./views/NotFoundView";
import ExploreView from "./views/ExploreView";
import CodeView from "./views/CodeView";
import QrScanView from "./views/QrScanView";
import ArticleView from "./views/ArticleView";
import AboutView from "./views/AboutView";
import PresentationView from "./views/PresentationView";
import OwnerView from "./views/OwnerView";
import SearchView from "./views/SearchView";
import PreviewView from "./views/PreviewView";
import { LANGUAGES } from "./Settings";
import Redirect from "./components/Redirect";
import QrCodeForwardView from "./views/QrCodeForwardView";
import PresentationMap from "./views/PresentationMap";
import SelectionView from "./views/SelectionView";
import SelectionMap from "./views/SelectionMap";
import PrivacyView from "./views/PrivacyView";
import MinneView from "./views/MinneView";

/**
 * Define all app routes
 * @returns {JSX.Element} Component template
 */
const AppRoutes = () => {
  const location = useLocation()?.pathname;

  return (
    <>
      { /* Keep these pages always alive */}
      <HomeView active={ location === "/" }/>
      <ExploreView active={ location.startsWith("/explore/") }/>

      <Routes>
        <Route path="/" element={<></>}/>
        <Route path="/explore/map" element={<></>}/>
        <Route path="/explore/list/:tab" element={<></>}/>
        {/* 
          <Route path="/" element={<HomeView/>}/>
          <Route path="/explore/map" element={<ExploreView page="map"/>}/>
          <Route path="/explore/list/:tab" element={<ExploreView page="list"/>}/>
        */}

        <Route path="/explore/list" element={<Navigate to="/explore/list/articles"/>}/>
        <Route path="/explore" element={<Navigate to="/explore/map"/>}/>
        <Route path="/code" element={<CodeView />}/>
        <Route path="/scan" element={<QrScanView />}/>
        <Route path="/about" element={<AboutView />}/>
        <Route path="/privacy" element={<PrivacyView />}/>

        <Route path="/article/:articleId" element={<ArticleView />}/>
        <Route path="/group/:presentationId" element={<PresentationView />}/>
        <Route path="/group/:presentationId/map" element={<PresentationMap/>}/>
        <Route path="/group/:presentationId/selection/:selectionId" element={<SelectionView/>}/>
        <Route path="/group/:presentationId/selection/:selectionId/list" element={<SelectionView/>}/>
        <Route path="/group/:presentationId/selection/:selectionId/map" element={<SelectionMap/>}/>
        <Route path="/group/:presentationId/article/:articleId" element={<ArticleView />}/>
        <Route path="/owner/:ownerId" element={<OwnerView />}/>
        <Route path="/owner/:ownerId/filter" element={<OwnerView filter={true}/>}/>
        <Route path="/minner/:responseId" element={<MinneView country="no"/>}/>
        <Route path="/minnen/:responseId" element={<MinneView country="sv"/>}/>
        <Route path="/search" element={<SearchView />}/>

        <Route path="/preview" element={<PreviewView />}/>

        {/* Redirect old links */}
        {LANGUAGES.map((lang, i) => {
          return <Route key={`lang${i}`} path={`/${lang?.key}/*`} element={<Redirect url={location.replace(`/${lang?.key}/`, "/")} languageCode={lang?.key}/>}/>
        })}

        <Route path="/kulturpunkt/:documentId" element={<Navigate to={location.replace("/kulturpunkt/", "/article/")}/>}/>
        <Route path="/museum/:documentId" element={<Navigate to={location.replace("/museum/", "/owner/")}/>}/>
        <Route path="/map" element={<Navigate to="/explore/map"/>}/>
        <Route path="/list" element={<Navigate to="/explore/list"/>}/>
        <Route path="/number" element={<Navigate to="/code"/>}/>
        <Route path="/qr" element={<Navigate to="/scan"/>}/>

        <Route path="/:code" element={<QrCodeForwardView />}/>
        <Route path="*" element={<NotFoundView />}/>
      </Routes>
    </>
  );
}

export default AppRoutes;