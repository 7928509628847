import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { LocationContext } from '../contexts/LocationContext';
import { calculateDistance } from '../common/Location';
import Image from './Image';
import DistanceIcon from "../assets/icon_distance_white.svg";
import { PlaceholderText } from './Placeholders';
import useFetchOwnerProfile from '../hooks/useFetchOwnerProfile';
import { HListScroller } from './ListHorizontalScroller';

interface ListWrapperProps {
  children: JSX.Element | JSX.Element[],
  count?: number,
  backgroundColor?: string
}

interface ListItemProps {
  media?: any,
  title: string,
  location?: {lat: number, lng: number},
  owner?: string,
  profile?: any,
  link?: string
}

interface HListPlaceholderItemProps {
  count: number
}

const HListWrapperElement = styled.ul.attrs((p: any) => ({
  scrollSnap: p.scrollSnap || false,
}))`
  margin: 0 0 30px;
  padding: 2px 20px 2px 25px;
  list-style: none;
  float: left;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  min-height: 170px;
  overflow: visible;
`;

const ListItem = styled.li`
  float: left;
  margin-right: 5px;
  width: 180px;
  min-height: 170px;
  flex: 0 0 auto;
  position: relative;
  -webkit-tap-highlight-color: ${p => p.theme.accentColor}10;
  cursor: pointer;
  scroll-snap-align: center;

  &.desktop {
    width: calc((100%/5) - 5px);

    @media screen and (max-width: 1300px) {
      width: calc((100%/3) - 5px);
    }
  }
`;

const ListLink = styled.a`
  display: block;
  text-decoration: none;
`;

const Img = styled(Image)`
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: #333;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 70px;
    width: 100%;
    height: 50px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 0 0 4px 4px;
    z-index: 0;
  }

  &.placeholder {
    background: ${p => p.theme.placeholderColor} !important;
    &::after {
      background: none;
    }
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: .9em;
  margin-bottom: 5px;
  display: block;
  --max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${p => p.theme.textColor};
`;

const Distance = styled.span`
  color: #fff;
  position: absolute;
  top: 92px;
  left: 15px;
  background-image: url(${DistanceIcon});
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 8px 10px;
  font-size: .8em;
  font-weight: 400;
  line-height: 17px;
  margin: 0 12px 0 0;
  padding-left: 15px;
  display: block;
  float: left;
  z-index: 1;
`;

const Owner = styled.span`
  font-size: .9em;
  font-weight: 400;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  color: ${p => p.theme.textColor};
`;

/**
 * Render a horizontal slider wrapper. Includes navigation arrows if desktop mode is active
 * @returns {JSX.Element} Component template
 */
export const HListWrapper: FC<ListWrapperProps> = ({children, count, backgroundColor}) => {
  return (
    <HListScroller count={count} backgroundColor={backgroundColor}>
      <HListWrapperElement>
        {children}
      </HListWrapperElement>
    </HListScroller>
  );
}

/**
 * Render a horizontal list element
 * @returns {JSX.Element} Component template
 */
export const HListItem: FC<ListItemProps> = ({media, title, location, owner, profile, link}) => {
  const { isDesktop } = useContext(AppContext);
  const { geoLocation } = useContext(LocationContext);
  const [mediaObject, setMediaObject] = useState<any>(null);
  const [profileImage] = useFetchOwnerProfile(profile);

  // Put media in state to prevent rerenders
  useEffect(() => {
    if (!mediaObject || mediaObject?.mediaId !== media?.mediaId) {
      setMediaObject(media || profileImage);
    }
  }, [media, mediaObject, profileImage]);
  
  return (
    <ListItem className={isDesktop ? "desktop" : undefined}>
      <ListLink href={link} aria-label={title} tabIndex={0}>
        <Img media={mediaObject} usePlaceholder={true} alt={title}/>
        <Title>{title}</Title>
        {(geoLocation?.lat && location?.lat) && (<Distance>{calculateDistance([geoLocation?.lat, geoLocation?.lng], [location?.lat, location?.lng])}</Distance>)}
        {owner && (<Owner>{owner}</Owner>)}
      </ListLink>
    </ListItem>
  )
};


/**
 * Render placeholder items to be displayed while fetching data
 * @param {number} count Number of items to be rendered 
 * @returns {JSX.Element} Component template
 */
 export const HListPlaceholderItem: FC<HListPlaceholderItemProps> = ({count}) => {
  const { isDesktop } = useContext(AppContext);
  const iterateArray = Array.from(Array(count).keys());

  return (
    <>
      {iterateArray?.map((item: any, i: number) => {
        return <ListItem key={`placeholder${i}`} className={isDesktop ? "desktop" : undefined}>
          <Img className="placeholder"/>
          <Title><PlaceholderText/></Title>
          <Owner><PlaceholderText width={66}/></Owner>
        </ListItem>
      })}
    </>
  );
}