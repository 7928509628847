import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { ReactComponent as ChevronBack } from "../assets/chevron_left.svg";
import { ReactComponent as ChevronNext } from "../assets/chevron_right.svg";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface PresentationNavigationProps {
  presentationId?: number|string|undefined,
  articleId?: number|string|undefined,
  visible?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  font-size: .9em;
  margin: 30px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10px;
  min-height: 25px;

  &.empty {
    margin: -15px 0 0;
  }
`;

const Button = styled.button`
  position: relative;
  cursor: pointer;
  border: none;
  background-color: transparent;

  &&::before {
    content: "";
    position: absolute;
    padding: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const ButtonPlaceholder = styled.div`
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
`

/**
 * Render a the presentation header bar. Caches the presentation data.
 * @returns {JSX.Element} Component template
 */
export const PresentationNavigation: FC<PresentationNavigationProps> = ({presentationId, articleId, visible}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { presentationCache } = useContext(AppContext);
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [prevId, setPrevId] = useState<number>(-1);
  const [nextId, setNextId] = useState<number>(-1);

  /**
   * Navigate to previous article
   */
  const goToPrevArticle = useCallback(() => {
    navigate(`/group/${presentationId}/article/${prevId}`);
  }, [navigate, presentationId, prevId]);

  /**
   * Navigate to next article
   */
  const goToNextArticle = useCallback(() => {
    navigate(`/group/${presentationId}/article/${nextId}`);
  }, [navigate, presentationId, nextId]);

  // Get position and length
  useEffect(() => {
    setTotalCount(presentationCache?.articles?.list?.length || 0);
    setCurrentPosition(presentationCache?.articles?.list?.map(e => e.document_id).indexOf(Number(articleId)) || 0);

    if (presentationCache?.articles?.list) {
      setPrevId(presentationCache?.articles?.list?.[currentPosition-1]?.document_id);
      setNextId(presentationCache?.articles?.list?.[currentPosition+1]?.document_id);
    }
  }, [presentationCache, articleId, currentPosition]);

  if (!visible) { return null; }

  if (!presentationCache) { return <Wrapper/>; }

  if (presentationCache?.displayType !== "route") {
    return <Wrapper className="empty"/>
  }

  return (
    <Wrapper>
      {currentPosition !== -1 && (
        <>
          {prevId > 0 ? (<Button onClick={goToPrevArticle} aria-label={t("presentation.previous")}><ChevronBack/></Button>) : (<ButtonPlaceholder/>)}
          {(totalCount) && (
            <>{t("presentation.point")} {(currentPosition + 1)} {t("presentation.of")} {totalCount}</>
          )}
          {nextId > 0 ? (<Button onClick={goToNextArticle} aria-label={t("presentation.next")}><ChevronNext/></Button>) : (<ButtonPlaceholder/>)}
        </>
      )}
    </Wrapper>
  );
}

export default PresentationNavigation;