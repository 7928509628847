import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TabBarItem, TabBarWrapper } from '../components/TabButtons';
import { TabSwitchItem, TabSwitchWrapper } from '../components/TabSwitch';
import PageTransition from '../components/PageTransition';
import Header from '../framework/Header';
import ExploreMapView from './ExploreView_Map'
import ExploreArticleList from './ExploreView_ListArticles';
import ExploreOwnerList from './ExploreView_ListOwners';
import { AppContext } from '../contexts/AppContext';

export interface ExploreViewProps {
  active: boolean
}

const DesktopHeader = styled.div`
  width: 100%;
  height: 30px;
  position: relative;
  z-index: 20;
`;

const Wrapper = styled.div.attrs((p: any) => ({
  active: p.active
}))`
  display: ${p => p.active ? "inherit" : "none"};
`;

const ListView = styled.div.attrs((p: any) => ({
  active: p.active || false
}))`
  visibility: ${p => p.active ? "visible" : "hidden"};
  margin-top: 20px;
`;

/**
 * Render the explore page
 * @returns {JSX.Element} Component template
 */
const ExploreView: FC<ExploreViewProps> = ({active}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { ownerFilter, isDesktop } = useContext(AppContext);
  const [shouldRender, setShouldRender] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<string>("map");
  const [currentTab, setCurrentTab] = useState<string>("articles");
  const [mapIsLoading, setMapIsLoading] = useState<boolean>(false);
  const [articlesAreLoading, setArticlesAreLoading] = useState<boolean>(false);
  const [ownersAreLoading, setOwnersAreLoading] = useState<boolean>(false);

  // Get page and tab from URL
  useEffect(() => {
    const path = location.pathname.split("/");
    setCurrentPage(path?.[2]);
    setCurrentTab(path?.[3]);
  }, [location]);

  // Update title
  useEffect(() => {
    if (active) {
      document.title = `${t("tabs.explore")} - KulturPunkt`;
    }
  }, [t, active]);

  // Force hard rerendering if owner filter is changed
  useEffect(() => {
    setShouldRender(false);
    setTimeout(() => {
      setShouldRender(true);
    }, 100);
  }, [ownerFilter]);

  // On switch change
  const showSubPage = useCallback((page: string) => {
    switch(page) {
      case "map":
        navigate("/explore/map");
        setCurrentPage("map");
        break;
      case "list":
        navigate("/explore/list/articles");
        setCurrentPage("list");
        break;
    }
  }, [navigate]);

  // On switch change
  const setTab = useCallback((item: string) => {
    switch(item) {
      case "articles":
        navigate("/explore/list/articles");
        break;
      case "owners" || undefined:
        navigate("/explore/list/owners");
        break;
    }
  }, [navigate]);

  return (
    <>
      {shouldRender && (
        <Wrapper active={active && currentPage}>
          <Header layout={"full"} showProgress={mapIsLoading || articlesAreLoading || ownersAreLoading}/>
          { isDesktop && (<DesktopHeader/>)}
          <TabSwitchWrapper>
            <TabSwitchItem label={t("explore.map")} isActive={currentPage === "map"} onClick={() => showSubPage("map")}/>
            <TabSwitchItem label={t("explore.list")} isActive={currentPage === "list"} onClick={() => showSubPage("list")}/>
          </TabSwitchWrapper>
          
          <PageTransition scroll={currentPage === "map" ? false : true}>
            <ExploreMapView active={active && currentPage === "map"} setIsLoading={setMapIsLoading}/>
            <ListView active={active && currentPage === "list"}>
              <TabBarWrapper>
                <TabBarItem label={t("list.articles")} isActive={currentTab === "articles"} onClick={() => setTab("articles")}/>
                <TabBarItem label={t("list.museums")} isActive={currentTab === "owners"} onClick={() => setTab("owners")}/>
              </TabBarWrapper>
            </ListView>

            <ExploreArticleList active={currentTab === "articles"} setIsLoading={setArticlesAreLoading}/>
            <ExploreOwnerList active={currentTab === "owners"} setIsLoading={setOwnersAreLoading}/>
          </PageTransition>
        </Wrapper>
      )}
    </>
  );
}

export default ExploreView;