import { FC, useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { LocationContext } from '../contexts/LocationContext';
import { calculateDistance } from '../common/Location'
import Image from './Image';
import ChevronRight from "../assets/chevron_next.svg";
import DistanceIcon from "../assets/icon_distance_black.svg";
import { getTranslation } from '../common/Translation';
import useFetchDocument from '../hooks/useFetchDocument';
import useFetchOwner from '../hooks/useFetchOwner';
import useFetchOwnerProfile from '../hooks/useFetchOwnerProfile';
//import useFetchNearbyArticles from '../hooks/useFetchNearbyArticles';

interface MapPopupProps {
  id?: number,
  type?: "museum" | "article",
  presentationId?: string
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const WrapperEnterence = keyframes`
  from { transform: translateY(150%); }
  to { transform: translateY(0%); }
`

const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  align-items: flex-start;
  bottom: 75px;
  position: fixed;
  z-index: 19;
  width: 100%;
  overflow-x: auto;
  margin: 0;
  padding: 0 20px;
  scroll-snap-type: x mandatory;
  animation: ${WrapperEnterence} 0.2s linear;

  &.desktop {
    position: absolute;
    bottom: 20px;
  }

  & li:not(.placeholder) > div {
    animation: ${fadeIn} 2s;
  }

  &:visited {
    color: #000;
  }
`

const Item = styled.li`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  background-color: #fff;
  background-image: url(${ChevronRight});
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;
  border-radius: 4px;
  margin: 15px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  width: 100%;
  max-width: 600px;
  scroll-snap-align: center;
  cursor: pointer;
`;

const PopupLink = styled.a`
  display: block;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #000;
  width: 100%;
  max-width: 600px;
`;

const Img = styled(Image)`
  float: left;
  width: 90px;
  height: 90px;
  transition: opacity .3s;
  flex-shrink: 0;
`;

const Metadata = styled.div`
  float: left;
  padding: 0 30px 0 15px;
  width: calc(100% - 105px);
  transition: opacity .3s;
  color: #000;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #000;
`;

const Description = styled.span`
  font-size: 12px;
  margin-bottom: 10px;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
  overflow: hidden;
  --max-lines: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #000;
`;

const Distance = styled.span`
  background-image: url(${DistanceIcon});
  background-position: 0 50%;
  background-repeat: no-repeat;
  font-size: 12px;
  margin: 0 15px 0 0;
  padding-left: 15px;
  display: block;
  float: left;
  color: #000;
`;

const Owner = styled.span`
  font-size: 12px;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  color: #000;
`;

const PlaceholderText = styled.div.attrs((p: any) => ({
  width: p.width || 100,
}))`
  aspect-ratio: 16 / 1;
  background: ${p => p.theme.placeholderColor};
  display: inline-block;
  width: -webkit-fill-available;
  height: 13px;
  border-radius: 2px;
  width: ${p => p.width}%;
`;

/**
 * Render a map popup bar
 * @returns {JSX.Element} Component template
 */
const MapPopup: FC<MapPopupProps> = ({id, type, presentationId}) => {
  const { currentLanguage, isDesktop } = useContext(AppContext);
  const { geoLocation } = useContext(LocationContext);
  const [distance, setDistance] = useState<string|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [articleId, setArticleId] = useState<number>();
  const [ownerId, setOwnerId] = useState<number>();
  const [articleData, articleIsLoading] = useFetchDocument(articleId);
  const [ownerData, ownerIsLoading] = useFetchOwner(ownerId);
  const [ownerImage] = useFetchOwnerProfile(ownerData);
  //const [nearbyArticles] = useFetchNearbyArticles(articleData?.location, 6, articleData?.id);

  // Calculate distance
  useEffect(() => {
    if (geoLocation && (articleData || ownerData)) {
      const pointLocation = (articleData || ownerData)?.location;
      const pointDistance = calculateDistance([geoLocation?.lat, geoLocation?.lng], [pointLocation?.lat, pointLocation?.lng]);
      setDistance(pointDistance);
    }
  }, [geoLocation, articleData, ownerData]);

  // Is any data being loaded?
  useEffect(() => {
    setIsLoading(articleIsLoading || ownerIsLoading);  
  }, [setIsLoading, articleIsLoading, ownerIsLoading]);

  // Catch data from API
  useEffect(() => {
    if (type === "museum") {
      setOwnerId(id);
    } else {
      setArticleId(id);
    }
  }, [id, type, setOwnerId, setArticleId]);

  if (id) { 
    return (
      <Wrapper className={`${isDesktop ? "desktop" : ""} ${isLoading ? "loading" : ""}`}>
        {(type === "article" && !isLoading) && (
          <Item>
            <PopupLink href={presentationId ? `/group/${presentationId}/article/${articleData?.id}` : `/article/${articleData?.id}`} aria-label={getTranslation(articleData?.content?.general?.title, currentLanguage)}>
              <Img media={{url: articleData?.image_url, media_type: "image", ...articleData?.content?.general?.image}} alt={getTranslation(articleData?.content?.general?.title, currentLanguage)} usePlaceholder={true}/>
              <Metadata>
                <Title>{getTranslation(articleData?.content?.general?.title, currentLanguage)}</Title>
                <Description>{getTranslation(articleData?.content?.general?.listteaser, currentLanguage)}</Description>
                {distance && (<Distance>{distance}</Distance>)}
                <Owner>{articleData?.owner_name}</Owner>
              </Metadata>
            </PopupLink>
          </Item>
        )}
        {(type === "museum" && !isLoading) && (
          <Item>
            <PopupLink href={`/owner/${ownerData?.id}`} aria-label={ownerData?.name}>
              <Img media={ownerImage} alt={ownerData?.name} usePlaceholder={true}/>
              <Metadata>
                <Title>{ownerData?.name}</Title>
                {distance && (<Distance>{distance}</Distance>)}
              </Metadata>
            </PopupLink>
          </Item>
        )}

        {/* Placeholder if no data is yet loaded */}
        {isLoading && (
          <Item className="placeholder">
            <Img usePlaceholder={true}/>
            <Metadata>
              <Title><PlaceholderText width="80"/></Title>
              <Description><PlaceholderText/></Description>
            </Metadata>
          </Item>
        )}
      </Wrapper>
    );
  } else {
    return null;
  }
}

export default MapPopup;