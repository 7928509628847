import { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import CheckIcon from "../assets/icon_check.svg";

interface BlockTaskQuizProps {
  taskData: any,
  completeCallback: (wasCorrect: boolean) => void
}

const Question = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 15px;
`;

const Answers = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
`;

const Answer = styled.li`

`;

const AnswerButton = styled.button`
  clear: both;
  padding: 5px 0;
  line-height: 30px;
  cursor: pointer;
  min-height: 30px;
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  font-size: .9em;

  &::before {
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: block;
    background-color: ${p => p.theme.accentColor};
    float: left;
    clear: both;
    margin-right: 10px;
  }

  &:active::before {
    opacity: .8;
  }

  &.checked::before {
    background-image: url(${CheckIcon});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 20px;
  }
`;

/**
 * Render a quiz task
 * @returns {JSX.Element} Component template
 */
const BlockTaskQuiz: FC<BlockTaskQuizProps> = ({taskData, completeCallback}) => {
  const answersRef = useRef<HTMLUListElement>(null);

  // Handle answer click
  const processAnswer = useCallback((e, index) => {
    // Clear all checkboxes
    if (answersRef.current) {
      answersRef.current.querySelectorAll("li")?.forEach(item => {
        item?.classList?.remove("checked");
      })
    }

    // Check the selected checkbox
    e?.target.classList?.add("checked");

    // Check answer
    if (taskData?.answers?.[index]?.correct) {
      //console.log("CORRECT ANSWER!");
      completeCallback(true);
    } else {
      //console.log("WRONG ANSWER!")
      completeCallback(false);
    }
  }, [taskData?.answers, completeCallback]);

  return (
    <>
      <Question>{taskData?.question}</Question>
      <Answers ref={answersRef}>
        {taskData?.answers?.map((answer: any, i: number) => {
          return <Answer key={`answer${i}`}><AnswerButton onClick={e => processAnswer(e, i)}>{answer.answer}</AnswerButton></Answer>
        })}
      </Answers>
    </>
  );
}

export default BlockTaskQuiz;