import { FC } from 'react';
import styled from 'styled-components';

interface TabBarWrapperProps {
  children: any
}

interface TabBarItemProps {
  label: string
  hits?: number,
  isActive?: boolean,
  onClick?: () => void
}

export const TabBarWrapperElement = styled.div`
  list-style: none;
  margin: 10px 0 20px;
  padding: 0 25px;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  flex: 0 0 auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabBarItemElement = styled.button`
  float: left;
  padding: 10px 15px;
  background-color: #D8D8D8;
  border-radius: 10px;
  color: #000;
  font-weight: bold;
  font-size: .9em;
  flex: 0 0 auto;
  -webkit-tap-highlight-color: #4A90E210;
  cursor: pointer;
  border: none;

  &:not(:first-child) {
    margin-left: 10px;
  }

  &.active {
    background-color: ${p => p.theme.accentColor};
    color: #fff;
  }
`

const HitCounter = styled.span`
  font-weight: normal;
  margin-left: 5px;
`;

/**
 * Render navigation tab wrapper
 * @returns {JSX.Element} Component template
 */
export const TabBarWrapper: FC<TabBarWrapperProps> = ({children}) => {
  return <TabBarWrapperElement role="tablist">{children}</TabBarWrapperElement>
}

/**
 * Render navigation tabs
 * @returns {JSX.Element} Component template
 */
export const TabBarItem: FC<TabBarItemProps> = ({label, hits, isActive, onClick}) => {
  return (
    <TabBarItemElement role="tab" aria-selected={isActive ? "true" : "false"} className={isActive ? "active" : undefined} onClick={onClick}>{label}{typeof hits !== "undefined" && <HitCounter>({hits})</HitCounter>}</TabBarItemElement>
  );
}