import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PlaceholderText } from './Placeholders';
import useFetchDocument from '../hooks/useFetchDocument';
import Api from '../services/Api';
import { ReactComponent as RouteIcon } from "../assets/icon_route.svg";
import { ReactComponent as MapIcon } from "../assets/icon_markers.svg";
import { ReactComponent as ListIcon } from "../assets/icon_list.svg";
import { ReactComponent as CloseIcon } from "../assets/icon_close.svg";
import useFetchOwner from '../hooks/useFetchOwner';

interface PresentationHeaderProps {
  presentationId?: number|string|undefined,
  articleId?: number|string|undefined,
  selectionId?: number|string|undefined,
  button?: "map" | "route" | "list" | "close",
  visible: boolean,
  layout?: "default" | "map"
}

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  min-height: 65px;
  z-index: 20;
  padding: 15px;
  box-sizing: border-box;
  background-color: rgb(240, 242, 245, .7);

  @media (min-width: 1000px) {
    &.map {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
`;

const Content = styled.div`
  float: left;
  max-width: calc(100% - 75px);
  cursor: pointer;
`;

const Owner = styled.span`
  display: block;
  font-size: .9em;
  font-weight: bold;
  color: ${p => p.theme.darkAccentColor};
  --max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const Title = styled.span`
  display: block;
  font-size: .9em;
  font-weight: bold;
  --max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const Toolbar = styled.div`
  float: right;
`;

const Button = styled.button`
  position: relative;
  cursor: pointer;
  padding: 10px 12px;
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  max-width: 130px;
  font-size: 14px;
  margin-right: -5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  border: none;
  font-size: 1em;

  & svg {
    max-width: 14px;
    margin-left: 8px;
  }

  &&::before {
    content: "";
    position: absolute;
    padding: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

/**
 * Render a the presentation header bar. Caches the presentation data.
 * @returns {JSX.Element} Component template
 */
export const PresentationHeader: FC<PresentationHeaderProps> = ({presentationId, articleId, selectionId, layout, button, visible}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentLanguage, presentationCache, setPresentationCache } = useContext(AppContext);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [selectionData, setSelectionData] = useState<any>(null);
  const [presentationData,, error] = useFetchDocument((!isLoaded && presentationId) ? presentationId : undefined);
  const [ownerData] = useFetchOwner(presentationData?.owner_id);

  const showOnMap = useCallback(() => {
    if (selectionId) {
      navigate(`/group/${presentationId}/selection/${selectionId}/map`);
    } else if (articleId) {
      navigate(`/group/${presentationId}/map?id=${articleId}`);
    } else {
      navigate(`/group/${presentationId}/map`);
    }
  }, [presentationId, navigate, articleId, selectionId]);

  const showOnList = useCallback(() => {
    if (selectionId) {
      navigate(`/group/${presentationId}/selection/${selectionId}/list`);
    } else {
      navigate(`/group/${presentationId}`);
    }
  }, [presentationId, navigate, selectionId]);

  // Return to previous page
  const close = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  // Get theme - if provided
  useEffect(() => {
    if (!presentationCache?.selections) { setSelectionData(null); return; }
    if (selectionId) {
      setSelectionData(presentationCache?.selections?.filter((item: any, i: number) => item.status === "published" && i === Number(selectionId))?.[0]);
    } else {
      setSelectionData(null);
    }
  }, [selectionId, presentationCache]);

  useEffect(() => {

  }, [presentationData]);

  // Errors?
  useEffect(() => {
    if (error) {
      setPresentationCache({error: true});
    }
  }, [error, setPresentationCache]);

  // Get presentation data and store in cache for faster page browsing within the presentation
  useEffect(() => {
    if (presentationData?.content && presentationCache?.id !== presentationId) {

      // Get metadata from all articles in presentation
      const articleIds = Symbol.iterator in Object(presentationData?.content?.articles?.list) ? presentationData?.content?.articles?.list?.map(article => article?.to_document_id) || [] : [];
      Api.getDocumentHeadList(articleIds).then(articleList => {

        // Sort and show only published items
        let publishedArticleList: any[] = [];
        articleIds?.forEach((id: any, i: number) => {
          articleList.forEach((item:any) => {
            if (item?.document_id === id && item?.status?.includes("published")) {
              let mergedItem = item;

              // Add outbound links to item
              mergedItem.to = presentationData?.content?.articles?.list?.[i]?.content?.to || [];

              // Push to list
              publishedArticleList.push(mergedItem);
            }
          });
        });

        setPresentationCache({
          id: presentationId,
          title: presentationData?.content?.general?.title,
          description: presentationData?.content?.general?.description,
          image: presentationData?.content?.general?.image,
          ownerId: presentationData?.owner_id,
          ownerName: presentationData?.owner_name,
          recordCount: publishedArticleList?.length || 0,
          selectionCount: presentationData?.content?.selections?.filter(item => item.status === "published")?.length || 0,
          articles: { list: publishedArticleList },
          selections: presentationData?.content?.selections?.filter(item => item.status === "published"),
          startItem: presentationData?.content?.general?.startItem || "first", // "none" || "first"
          displayType: presentationData?.content?.general?.displayType || "default", // "default" || "route",
          google_analytics_code: ownerData?.google_analytics_code
        });
        setIsLoaded(true);
      });
    } else {
      setIsLoaded(false);
    }
  }, [presentationId, presentationData, presentationCache, setPresentationCache, ownerData?.google_analytics_code]);

  const goToParentPresentation = useCallback(() => {
    navigate(`/group/${presentationId}`);
  }, [navigate, presentationId]);

  if (!visible) { return null; }

  return (
    <Wrapper className={layout}>
      <Content onClick={() => { goToParentPresentation(); }}>
        <Owner>{presentationCache?.ownerName || <PlaceholderText width="80"/>}</Owner>
        <Title>
          {getTranslation(presentationCache?.title, currentLanguage) || <PlaceholderText width="60"/>}
          {selectionData && (<span> &gt; {getTranslation(selectionData?.title, currentLanguage)}</span>)}
        </Title>
      </Content>
      <Toolbar>
        {(button === "map") && (
          <Button onClick={showOnMap}>{t("presentation.showPoints")}<MapIcon/></Button>
        )}
        {(button === "route") && (
          <Button onClick={showOnMap}>{t("presentation.showRoute")}<RouteIcon/></Button>
        )}
        {(button === "list") && (
          <Button onClick={showOnList}>{t("presentation.showList")}<ListIcon/></Button>
        )}
        {button === "close" && (
          <Button onClick={close}>{t("presentation.close")}<CloseIcon/></Button>
        )}
      </Toolbar>
    </Wrapper>
  );
}

export default PresentationHeader;