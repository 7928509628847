import { FC } from 'react';
import styled from 'styled-components';

interface SeparatorProps {
  width?: "full" | "content"
}

const SeparatorElement = styled.hr`
  border: none;
  width: 100%;
  height: 2px;
  background-color: ${p => p.theme.separatorColor};
  margin: 0 0 30px;

  &.content {
    margin: 0 25px 30px;
    width: calc(100% - 50px);
  }
`;

/**
 * Render a simple horizontal separator line
 * @returns {JSX.Element} Component template
 */
const Separator: FC<SeparatorProps> = ({width}) => {
  return (
    <SeparatorElement className={width || undefined}/>
  );
}

export default Separator;