import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import ArticleText from '../components/BlockText';
import BlockTitle from '../components/BlockTitle';
import ArticleTitle from '../components/BlockTitle';
import HeroArticle from '../components/HeroArticle';
import Switch from '../components/Switch';
import PageTransition from '../components/PageTransition';
import Header from '../framework/Header';
import { deleteCookie, getCookie, setCookie } from '../common/Cookies';
import { PRIVACY_POLICY_URL } from '../Settings';

const SettingsItem = styled.div`
  display: inline-flex;
  padding: 0 25px;
  margin: 0 0 30px;
  gap: 20px;
  font-weight: bold;
`;

const Table = styled.table`
  margin: 0 0 30px 0;
  padding: 0 25px;
  float: left;
  width: 100%;
  display: block;
  font-weight: 400;
  font-size: .9em;
  line-height: 24px;
  border-collapse: collapse;
  overflow: auto;
  max-width: 500px;
  margin: 0 auto;
`;

const TableHead = styled.thead`
  font-weight: 700;
  color: #fff;
  background-color: ${p => p.theme.accentColor};
`;

const TableHeadColumn = styled.th``;

const TableBody = styled.tbody``;

const TableColumn = styled.td`
  border: 1px solid ${p => p.theme.accentColor};
  padding: 2px 5px;
`;

const TableRow = styled.tr`
`;

/**
 * Render the about page
 * @returns {JSX.Element} Component template
 */
const PrivacyView: FC = () => {
  const [analyticsState, setAnalyticsState] = useState<boolean>(false);
  const { t } = useTranslation();

  // Update title
  useEffect(() => {
    document.title = `${t("privacy.title")} - KulturPunkt`;
  }, [t]);

  // Get statistics cookie
  useEffect(() => {
    if (getCookie("privacy")) {
      const privacyCookie = JSON.parse(getCookie("privacy") || "{}");
      if (privacyCookie?.analytics !== false) {
        setAnalyticsState(true);
      } else {
        setAnalyticsState(false);
      }
    }
  }, []);

  // Update cookies when setting is toggled
  useEffect(() => {
    if (analyticsState) {
      setCookie("privacy", JSON.stringify({ analytics: true }))
    } else {
      deleteCookie("_ga");
      deleteCookie("_gid");
      setCookie("privacy", JSON.stringify({ analytics: false }))
    }
  }, [analyticsState]);

  return (
    <>
      <Header/>
      <PageTransition>
        <HeroArticle media={{url: "/img/kp_privacy.jpg"}}/>
        <ArticleTitle level="h1" title={t("privacy.title")}/>
        <ArticleText text={t("privacy.body")}/>
        <ArticleText text={`[${t("privacy.link")}](${PRIVACY_POLICY_URL})`}/>

        <BlockTitle level="h2" title={t("privacy.settings")}/>
        <SettingsItem><Switch isActive={analyticsState} setActiveState={setAnalyticsState} label="Google Analytics"/> Google Analytics</SettingsItem>

        <BlockTitle level="h2" title={t("privacy.allCookies")}/>
        <Table>
          <TableHead>
            <TableRow>
            <TableHeadColumn>{t("privacy.cookieName")}</TableHeadColumn>
              <TableHeadColumn>{t("privacy.cookiePurpose")}</TableHeadColumn>
              <TableHeadColumn>{t("privacy.cookieOptional")}</TableHeadColumn>
              <TableHeadColumn>{t("privacy.cookieTime")}</TableHeadColumn>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableColumn>checkedin</TableColumn>
              <TableColumn>{t("privacy.checkedin.description")}</TableColumn>
              <TableColumn>{t("privacy.mandatory")}</TableColumn>
              <TableColumn>90 {t("privacy.days")}</TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>fontsize</TableColumn>
              <TableColumn>{t("privacy.fontsize.description")}</TableColumn>
              <TableColumn>{t("privacy.mandatory")}</TableColumn>
              <TableColumn>90 {t("privacy.days")}</TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>language</TableColumn>
              <TableColumn>{t("privacy.language.description")}</TableColumn>
              <TableColumn>{t("privacy.mandatory")}</TableColumn>
              <TableColumn>90 {t("privacy.days")}</TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>privacy</TableColumn>
              <TableColumn>{t("privacy.cookies.description")}</TableColumn>
              <TableColumn>{t("privacy.mandatory")}</TableColumn>
              <TableColumn>90 {t("privacy.days")}</TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>_ga</TableColumn>
              <TableColumn>{t("privacy.analytics.description")}</TableColumn>
              <TableColumn>{t("privacy.optional")}</TableColumn>
              <TableColumn>90 {t("privacy.days")}</TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>_gid</TableColumn>
              <TableColumn>{t("privacy.analytics.description")}</TableColumn>
              <TableColumn>{t("privacy.optional")}</TableColumn>
              <TableColumn>90 {t("privacy.days")}</TableColumn>
            </TableRow>
          </TableBody>
        </Table>
      </PageTransition>
    </>
  );
}

export default React.memo(PrivacyView);
