import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getTranslation } from '../common/Translation';
import { useTranslation } from "react-i18next";
import { VListItem, VListPlaceholderItem, VListWrapper } from '../components/ListVertical';
import PageTransition from '../components/PageTransition';
import { AppContext } from '../contexts/AppContext';
import Header from '../framework/Header';
import Api from '../services/Api';
import { useParams } from 'react-router-dom';
import HeroPresentation from '../components/HeroPresentation';
import BlockTitle from '../components/BlockTitle';
import Separator from '../components/Separator';
import PresentationHeader from '../components/PresentationHeader';

const SelectionListWrapper = styled.div.attrs((p: any) => ({
  visible: p.visible || true
}))`
  background-color: #fff;
  float: left;
  width: 100%;
  margin-bottom: 25px;
  opacity: ${p => p.visible ? "1" : "0"};
  transition: opacity .2s;
  padding: 20px 0 0;
  margin: 0 10px 25px;
  border-radius: 4px;
  width: calc(100% - 20px);

  @media (min-width: 1000px) {
    border-radius: 8px;
  }
`;

/**
 * Render the presentation selection page
 * @returns {JSX.Element} Component template
 */
const SelectionView: FC = () => {
  const { presentationCache, currentLanguage } = useContext(AppContext);
  const { presentationId } = useParams<{presentationId: string}>();
  const { selectionId } = useParams<{selectionId: string}>();
  const [selectionData, setSelectionData] = useState<any>();
  const [articleList, setArticleList] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!presentationCache?.id || !selectionId) { return; }

    const selection = presentationCache?.selections?.filter((record: any, i: number) => Boolean(Number(selectionId) === i));
    setSelectionData(selection?.[0]);
  }, [presentationCache, selectionId]);

  // Get metadata of all articles
  useEffect(() => {
    if (!selectionId || !presentationCache) { return }

    const articleIds = presentationCache?.selections?.[selectionId]?.articles?.map(article => article?.document_id) || [];
    Api.getDocumentHeadList(articleIds).then(articles => {

      // Sort and show only published items
      let publishedArticleList: any[] = [];
      articleIds?.forEach((id: any) => {
        articles.forEach((item:any) => {
          if (item?.document_id === id && item?.status === "published") {
            publishedArticleList.push(item);
          }
        });
      });
      setArticleList(publishedArticleList);
    });
  }, [presentationCache, selectionId]);

  return (
    <>
      <Header/>
      <PresentationHeader presentationId={presentationId} selectionId={selectionId} visible={true} button={selectionData?.type === "tour" ? "route" : "map"}/>
      <PageTransition className="previewdisable">
        <HeroPresentation title={getTranslation(selectionData?.title, currentLanguage)} ownerId={presentationCache?.ownerId} ownerName={presentationCache?.ownerName} preface={getTranslation(selectionData?.description, currentLanguage)} />
        <SelectionListWrapper>
          {(!articleList || articleList?.length > 0) && (
            <>
              <BlockTitle title={t("list.articles")} level="h2" id="articles" count={articleList?.length || 0}/>
              <VListWrapper>
                {Symbol.iterator in Object(articleList) && articleList?.map((item: any, i: number) => {
                  return <VListItem key={`article${i}`} media={{url: item?.image_src, media_type: "image"}}  title={getTranslation(item?.title, currentLanguage)} location={item?.content?.location} link={`/group/${presentationId}/article/${item?.document_id}`} description={getTranslation(item?.description, currentLanguage)}/>
                })}
                {!articleList && (
                  <VListPlaceholderItem count={5}/>
                )}
              </VListWrapper>
              <Separator width="content"/>
            </>
          )}

          <BlockTitle title={t("article.isPartOf")} level="h2"/>
          <VListWrapper>
            <VListItem
              media={presentationCache?.image} 
              title={getTranslation(presentationCache?.title, currentLanguage)} 
              description={getTranslation(presentationCache?.description, currentLanguage)}
              link={`/group/${presentationId}/`} />
          </VListWrapper>
        </SelectionListWrapper>
      </PageTransition>
    </>
  );
}

export default SelectionView;