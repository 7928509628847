import { FC } from 'react';
import styled from 'styled-components';

interface TabSwitchWrapperProps {
  children?: JSX.Element | JSX.Element[]
}

interface TabSwitchItemProps {
  label: string,
  isActive?: boolean,
  onClick?: () => void
}

const TabSwitchWrapperElement = styled.div`
  list-style: none;
  margin: -50px 0 10px;
  padding: 0;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: hidden;
  flex: 0 0 auto;
  border-radius: 7px;
  background-color: ${p => p.theme.switchBackgroundColor};
  z-index: 21;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  box-shadow: 0 3px 9px rgb(0 0 0 / 2%);

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabSwitchItemElement = styled.button`
  float: left;
  padding: 8px 30px;
  border-radius: 5px;
  color: #000;
  font-size: .9em;
  flex: 0 0 auto;
  -webkit-tap-highlight-color: #4A90E210;
  cursor: pointer;
  margin: 3px;
  background-color: transparent;
  border: none;

  &.active {
    font-weight: bold;
    background-color: #fff;
    color: ${p => p.theme.accentColor};
  }
`

/**
 * Render a navigation tab switch wrapper
 * @returns {JSX.Element} Component template
 */
export const TabSwitchWrapper: FC<TabSwitchWrapperProps> = ({children}): JSX.Element => {
  return <TabSwitchWrapperElement role="tablist">{children}</TabSwitchWrapperElement>
}

/**
 * Render a navigation tab switch element
 * @returns {JSX.Element} Component template
 */
export const TabSwitchItem: FC<TabSwitchItemProps> = ({label, isActive, onClick}): JSX.Element => {
  return (
    <TabSwitchItemElement role="tab" aria-selected={isActive ? "true" : "false"} className={isActive ? "active" : undefined} onClick={onClick}>{label}</TabSwitchItemElement>
  );
}