import { useCallback, useEffect } from "react";

/**
 * Detect if user has scrolled to element. Used for lazy loading
 * @param {booleaen} active Should the observer be active?
 * @param {Element} element DOM element to observe
 * @param {function} callback Callback function
 */
export default function useLazyObserver(active: boolean, intersectRef?: any, callback?: () => void) {
  /**
   * Has the user scrolled to the bottom of the page?
   */
   const detectBottomScroll = useCallback((element) => {
    const observerOptions = { root: null, threshold: 0, rootMargin: '-50px' };

    if (!('IntersectionObserver' in window)) {
      return;
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (active && entry.isIntersecting) {
          callback && callback();
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);  

    element && observer.observe(element);
  }, [active, callback]);

  useEffect(() => {
    if (active && intersectRef?.current) {
      detectBottomScroll(intersectRef.current);
    }
  }, [active, intersectRef, detectBottomScroll]);

  return;
}