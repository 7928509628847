import ReactGA from 'react-ga';
import { ANALYTICS_ACTIVE, ANALYTICS_CODE } from '../Settings';
import { getCookie } from './Cookies';

/**
 * Track owner specific page using Google Analytics
 */
export function trackOwner(analyticsId: string|undefined) {
  if (!analyticsId || !analyticsIsEnabled()) { return; }
  //console.log(`Track owner: ${analyticsId}.`);

  ReactGA.initialize([{trackingId: analyticsId}], { gaOptions: { name: "museum" }});
  ReactGA.ga('create', analyticsId, 'auto', 'museum');
  ReactGA.pageview(window.location.pathname + window.location.search, ['museum']);
}

/**
 * Track all pages using Google Analytics
 */
export function track() {
  if (!analyticsIsEnabled()) { return false; }

  //console.log(`Track general.`);

  ReactGA.initialize([{trackingId: ANALYTICS_CODE}]);
  ReactGA.ga('create', ANALYTICS_CODE, 'auto');
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export function analyticsIsEnabled() {
  if (!ANALYTICS_ACTIVE) { return false; }

  if (getCookie("privacy")) {
    const privacyCookie = JSON.parse(getCookie("privacy") || "{}");
    if (privacyCookie?.analytics !== false) {
      return true;
    }
  }
}