import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Image from './Image';
import styled from 'styled-components';
import ChevronRight from "../assets/chevron_next.svg";
import useFetchOwnerProfile from '../hooks/useFetchOwnerProfile';

interface BlockPublisherProps {
  owner: any
}

const BlockContainer = styled.a`
  margin: -30px 0 0;
  padding: 15px 30px;
  float: left;
  width: 100%;
  -webkit-tap-highlight-color: ${p => p.theme.accentColor}10;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: transparent;
  background-image: url(${ChevronRight});
  background-repeat: no-repeat;
  background-position: calc(100% - 25px) 50%;
  border: none;
  text-decoration: none;
`;

const Img = styled(Image)`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  object-fit: cover;
  float: left;
  margin-right: 10px;
  overflow: hidden;
  flex-shrink: 0;

  & img {
    border-radius: 32px;
  }
`;

const Metadata = styled.div``

const LeadText = styled.span`
  font-size: .9em;
  margin: 5px 0;
  display: block;
  color: ${p => p.theme.textSecondaryColor};
`;

const Publisher = styled.span`
  font-size: .9em;
  font-weight: bold;
  display: block;
  color: #000;
`;

/**
 * Render an article footer containing publisher details
 * @returns {JSX.Element} Component template
 */
const BlockPublisher: FC<BlockPublisherProps> = ({owner}) => {
  const { t } = useTranslation();
  const [ownerMainImage] = useFetchOwnerProfile(owner);

  if (!owner) { return null; }

  return (
    <BlockContainer href={`/owner/${owner?.id}`} role="button" tabIndex={0}>
      {ownerMainImage && (<Img media={ownerMainImage} alt={owner?.name}/>)}
      <Metadata>
        <LeadText>{`${t("article.publishedBy")} `}</LeadText>
        <Publisher>{owner?.name}</Publisher>
      </Metadata>
    </BlockContainer>
  );
}

export default BlockPublisher;