import { FC, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import ErrorMessageView from '../components/ErrorMessage';
import PageTransition from '../components/PageTransition';
import Header from '../framework/Header';

/**
 * Render a 404 page
 * @returns {JSX.Element} Component template
 */
const NotFoundView: FC = () => {
  const { t } = useTranslation();

  // Update title
  useEffect(() => {
    document.title = `${t("error.notFound.title")} - KulturPunkt`;
  }, [t]);

  return (
    <>
      <Header/>
      <PageTransition>
        <ErrorMessageView title={t("error.notFound.title")} message={t("error.notFound.body")}/>
      </PageTransition>
    </>
  );
}

export default NotFoundView;
