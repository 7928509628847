import { FC, useCallback, useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppContext';
import { ReactComponent as CloseSvg } from "../assets/icon_close_small.svg";

const Wrapper = styled.div`
  position: sticky;
  background: ${p => p.theme.accentColor};
  color: #fff;
  font-size: .9em;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  clear: both;
  z-index: 21;
  top: 0;
`;

const Owner = styled.span`
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 85%;
  display: block;
  margin: 0 auto;

  &::before {
    content: '\feff';
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  padding: 15px;
  width: 50px;
  height: 50px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  & svg {
    fill: #fff;
  }
`;

/**
 * Render a header filter notice when owner filter is active
 * @returns {JSX.Element} Component template
 */
const FilterHeader: FC = () => {
  const { t } = useTranslation();
  const { ownerFilter, setOwnerFilter, previewIsActive } = useContext(AppContext);
  const themeContext = useContext(ThemeContext)
  const navigate = useNavigate();

  /**
   * Remove filter header
   */
  const removeOwnerFilter = useCallback(() => {
    setOwnerFilter(undefined);
    navigate("/");
  }, [setOwnerFilter, navigate]);

  /**
   * Navigate to owner page
   */
  const navigateToOwner = useCallback(() => {
    navigate(`/owner/${ownerFilter?.id}`);
  }, [ownerFilter?.id, navigate]);

  /**
   * Update browser theme
   */
  useEffect(() => {
    const themeColorElement = document?.querySelector('meta[name="theme-color"]');
    const iOSThemeColorElement = document?.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
    if (ownerFilter) {
      themeColorElement?.setAttribute('content', themeContext?.accentColor);
      iOSThemeColorElement?.setAttribute('content', themeContext?.accentColor);
    } else {
      themeColorElement?.setAttribute('content', "#ffffff");
      iOSThemeColorElement?.setAttribute('content', "#ffffff");
    }
  }, [ownerFilter, themeContext?.accentColor]);

  if (previewIsActive) { return null }

  return (
    <>
      {ownerFilter && (
        <Wrapper>
          <Owner onClick={navigateToOwner}>
            {t("filter.filterBy")} {ownerFilter?.name}.
          </Owner>
          <CloseButton onClick={removeOwnerFilter} aria-label={t("filter.deactivateFilter")}><CloseSvg/></CloseButton>
        </Wrapper>
      )}
    </>
  );
}

export default FilterHeader;