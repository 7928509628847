import { FC, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useLocation } from 'react-router-dom';

interface PageProps {
  waitFor?: any,
  scroll?: boolean,
  children?: JSX.Element|JSX.Element[],
  className?: string,
  onClick?: any
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Page = styled.div.attrs((p: any) => ({
  visible: p.visible ? fadeIn : null,
  scroll: (typeof p.scroll === "undefined" || Boolean(p.scroll)) ? true : false,
}))`
  animation: ${((p: { visible: any; }) => p.visible) && fadeIn} 1s ease-in;
  margin-bottom: ${p => p.scroll ? "130px" : 0};
  float: ${p => p.scroll ? "left" : "unset"};
  width: 100%;
`

/**
 * Render a page transition on enterence. Makes all pages fade in and out
 * @returns {JSX.Element} Component template
 */
const PageTransition: FC<PageProps> = ({waitFor, scroll, children, className, onClick}): JSX.Element => {
  const { pathname } = useLocation();

  // Set if current page can be scrollable
  useEffect(() => {
    if (scroll) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }

    // Scroll to top of page
    window?.scrollTo(0, 0);
  }, [scroll])

  // Scroll to top every time the URL changes
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Page visible={waitFor} onClick={onClick} scroll={scroll} className={className}>
      {children}
    </Page>
  );
}

export default PageTransition;