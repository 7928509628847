import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { formatDate } from '../common/Date';
import { getTranslation } from '../common/Translation';
import { BlockMediaItem, BlockMediaWrapper } from '../components/BlockMedia';
import BlockText from '../components/BlockText';
import BlockTitle from '../components/BlockTitle';
import ArticleTitle from '../components/BlockTitle';
import PageTransition from '../components/PageTransition';
import { AppContext } from '../contexts/AppContext';
import Header from '../framework/Header';
import Api from '../services/Api';
import { ReactComponent as MinnenLogo } from "../assets/logo_minnen.svg";
import { ReactComponent as MinnerLogo } from "../assets/logo_minner.svg";
import ErrorMessage from '../components/ErrorMessage';

interface MinneViewProps {
  country: "no" | "sv"
}

const ArticleWrapper = styled.article`
  background-color: #fff;
  float: left;
  width: 100%;
  margin-bottom: 25px;
  transition: opacity .2s;
  box-shadow: 0px 3.55px 16px 2.66px #0000000D;
  overflow: hidden;
  min-height: 100vh;
  padding-bottom: 25px;

  @media (min-width: 1000px) {
    border-radius: 6px;
  }
`;

const Metadata = styled.table`
  padding: 0 25px;
`;

const TableRow = styled.tr`
  & td:first-of-type {
    font-weight: bold;
  }
`;

const TableCol = styled.td`
  padding: 5px 10px 5px 0;
`;

const ReferenceText = styled.p`
  margin: 0 0 30px 0;
  padding: 0 25px;
`;

const Footer = styled.div`
  margin: 0 0 30px 0;
  padding: 20px 25px 0;
  text-align: center;
  border-top: 1px solid #00000010;

  & p {
    margin-bottom: 5px;
  }
`;

/**
 * Render the Minne page
 * @returns {JSX.Element} Component template
 */
const MinneView: FC<MinneViewProps> = ({country}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useContext(AppContext);
  const { responseId } = useParams<{responseId: string}>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [minneData, setMinneData] = useState<any>(null)

  // Fetch response from the Minne API
  useEffect(() => {
    let isSubscribed = true;

    Api.getMinneContent(`response/${responseId}`, country).then(data => {
      if (isSubscribed) {
        setMinneData(data);
        setIsLoading(false);
      }
    }).catch(e => {});

    return () => { isSubscribed = false }
  }, [responseId, country]);

  // Update title
  useEffect(() => {
    document.title = `${getTranslation(minneData?.topic?.name_i18n, currentLanguage)} - KulturPunkt`;
  }, [minneData, currentLanguage]);

  if (minneData && !minneData?.topic) {
    return (
      <>
        <Header/>
        <ErrorMessage message={t("error.notFound.body")}/>
      </>
    );
  }

  return (
    <>
      <Header showProgress={isLoading}/>
      <PageTransition>
        <ArticleWrapper>
          <ArticleTitle level="h1" title={getTranslation(minneData?.topic?.name_i18n, currentLanguage)}/>
          {minneData?.values?.map((value: any, i: number) => {
            return (<React.Fragment key={`block${i}`}>
              <BlockTitle level="h2" title={getTranslation(value?.topic_item?.label_i18n, currentLanguage)} />
              <BlockText text={value?.display_value}/>
            </React.Fragment>);
          })}

          {minneData?.media?.[0] && (
            <BlockMediaWrapper layout="auto" lightbox={true}>
              {minneData?.media?.map((value: any, i: number) => {
                return <BlockMediaItem key={`media${i}`} media={{dmsId: value?.dms_id, media_type: "image"}} caption={value?.description} lightbox={true}/>
              })}
            </BlockMediaWrapper>
          )}

          <Metadata>
            <tbody>
              <TableRow>
                <TableCol>{t("minne.published")}</TableCol>
                <TableCol>{formatDate(minneData?.created)}</TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t("minne.author")}</TableCol>
                <TableCol>{minneData?.contributor?.display_name}</TableCol>
              </TableRow>
              <TableRow>
                <TableCol>{t("minne.archiveCode")}</TableCol>
                <TableCol>{minneData?.archive_code}</TableCol>
              </TableRow>
            </tbody>
          </Metadata>

          <ReferenceText><a href={minneData?.presentation_url}>{t("minne.seeMemory")}</a></ReferenceText>
        </ArticleWrapper>

        <Footer>
          <p>{t("minne.contentFrom")}</p>
          {country === "no" && (<a href="https://minner.no"><MinnerLogo/></a>)}
          {country === "sv" && (<a href="https://minnen.se"><MinnenLogo/></a>)}
        </Footer>
      </PageTransition>
    </>
  );
}

export default MinneView;
