import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface BlockTaskWordGameProps {
  taskData: any,
  completeCallback: (wasCorrect: boolean) => void
}

const Question = styled.span`
  text-align: center;
  font-weight: bold;
  display: block;
  margin-bottom: 15px;
`;

const Answer = styled.div.attrs((p: any) => ({
  childCount: p.childCount
}))`
  display: flex;
  justify-content: ${p => p.childCount > 4 ? "space-between" : "center"};
  gap: 2px;
  margin: 0 auto;
  padding: 0;
  max-width: 340px;
  font-size: 18px;

  & li {
    background-color: ${p => p.theme.accentColor};
    color: #fff;
  }
`;

const AvailableLetters = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: space-between;
  row-gap: 10px;
  margin: 20px auto 0;
  padding: 0;
  max-width: 340px;
  font-size: 18px;
`;

const Letter = styled.button`
  background-color: ${p => p.theme.backgroundColor};
  border-radius: 4px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-shadow: 1px 1px 2px rgba(0,0,0,.2);
  cursor: pointer;
  border: none;

  &:active {
    opacity: .8;
  }

  &.inactive {
    color: #f4f4f4;
  }
`;

/**
 * Render a wordgame task
 * @returns {JSX.Element} Component template
 */
const BlockTaskWordGame: FC<BlockTaskWordGameProps> = ({taskData, completeCallback}) => {
  const availableLettersRef = useRef<HTMLDivElement>(null);
  const [answerLetters, setAnswerLetters] = useState<string[]>([]);
  const [availableLetters, setAvailableLetters] = useState<string[]>([]);

  /**
   * Get random string
   * @param {number} length Number of letters to return
   * @return {string} Randomized string
   */
  const getRandomString = useCallback((length: number) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result: string = "";
    for (var i = 0; i < length; i++) {
      result += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
    }
    return result;
  }, []);

  /**
   * Verify if answer is correct
   */
   const verifyAnswer = useCallback((answer: string) => {
    if (answer === taskData?.answer) {
      //console.log("CORRECT ANSWER!");
      completeCallback(true);
    } else {
      //console.log("WRONG ANSWER!")
      completeCallback(false);
    }
  }, [taskData?.answer, completeCallback]);

  /**
   * Append letter to answer
   * @param {object} e Event from onClick
   */
  const insertLetter = useCallback((e: any) => {
    if (answerLetters?.length >= taskData?.answer?.length) { return; }
    if (e.target.classList.contains("inactive")) { return; }
    const letter = e.target.innerHTML;
    const updatedAnswer = [...answerLetters, letter];
    setAnswerLetters(updatedAnswer);
    e.target.classList.add("inactive");

    // Verify answer if all letters are inserted
    if (updatedAnswer?.length === taskData?.answer?.length) {
      verifyAnswer(updatedAnswer.join(""));
    }
  }, [answerLetters, taskData?.answer, verifyAnswer]);

  /**
   * Remove letter from answer
   * @param {object} e Event from onClick
   * @param {number} index Position on letter
   */
  const removeLetter = useCallback((e: any, index: number) => {
    // Remove letter from answerLetters array
    let updatedAnswer = answerLetters;
    updatedAnswer?.splice(index, 1);
    setAnswerLetters([...updatedAnswer]);

    // Put letter back into availableLetters list
    if (availableLettersRef?.current) {
      let shouldBreak = false;
      availableLettersRef?.current?.querySelectorAll("li").forEach(letter => {
        if (letter.innerHTML === e.target.innerHTML && letter.classList.contains("inactive") && !shouldBreak) {
          letter.classList.remove("inactive");
          shouldBreak = true;
        }
      });
    }
  }, [answerLetters, setAnswerLetters]);

  // Generate available letters as array
  useEffect(() => {
    const totalLetterCount = 12;
    const randomString = getRandomString(totalLetterCount - taskData?.answer?.length);
    const letters = (randomString + taskData?.answer)?.toUpperCase()?.split("");
    setAvailableLetters(letters.sort(() => 0.5 - Math.random()));
  }, [taskData?.answer, getRandomString]);

  return (
    <>
      <Question>{taskData?.question}</Question>
      <Answer childCount={taskData?.answer?.length}>
        {Array.from(Array(taskData?.answer?.length), (item, i) => {
          return <Letter key={`answer${i}`} onClick={e => removeLetter(e, i)}>{answerLetters?.[i] || "_"}</Letter>
        })}
      </Answer>
      <AvailableLetters ref={availableLettersRef}>
          {availableLetters?.map((letter: string, i: number) => {
            return <Letter key={`letter${i}`} onClick={insertLetter}>{letter}</Letter>
          })}
      </AvailableLetters>
    </>
  )
}

export default BlockTaskWordGame;