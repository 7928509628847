import { useEffect, useRef, useState } from "react";
import Api from "../services/Api";
import { DM_API_KEY, DM_DMS_IMAGE_URL, DM_OBJECT_URL } from "../Settings";

/**
 * Fetch media object from the API
 * @param {object} media Media object
 * @param {string} dimension Dimension. Optional.
 * @returns {object} Media object
 */
export default function useFetchMedia(media: any, ignore?: boolean) {
  const isSubscribed = useRef(true);
  const cache = useRef({});
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (ignore) { return; }

    isSubscribed.current = true;
    (async() => {
      if (!media || Object.keys(media).length === 0 || cache?.current === JSON.stringify(media)) { return }

      setData(null);
      setIsLoading(true);
      cache.current = JSON.stringify(media);

      // Is media object a string?
      if (typeof media === "string") { 
        setData({url: media}); 
        return;
      }

      // Is a DMS id provided?
      if (media?.dmsId) {
        setData({...media, ...{url: DM_DMS_IMAGE_URL.replace("{id}", media?.dmsId)}});
        return;
      }

      // Skip API request if media is already loaded
      if (media?.url) {
        setData(media);
        return;
      }
      
      if (!media?.mediaId) { return }
      Api.getMedia(String(media?.mediaId)).then(async mediaObject => {
        if (isSubscribed.current) {
          let url = {};
          switch (mediaObject?.media_type) {
            case "video":
              let thumbOverrideUrl;
              if (mediaObject?.thumbnail_override) {
                thumbOverrideUrl = await Api.getMedia(mediaObject?.thumbnail_override?.mediaId);
              }
              url = { url: mediaObject?.src, thumbUrl: thumbOverrideUrl?.src || mediaObject?.thumbnail_src }
              break;
            case "image":
            case "dm":
              url = { url: `${mediaObject?.src}`, thumbUrl: `${mediaObject?.thumbnail_src}` }
              break;
            case "audio":
              url = { url: `${mediaObject?.src}` }
              break;
            case "sketchfab":
            case "youtube":
              case "vimeo":
              url = { url: `${mediaObject?.src}`, thumbUrl: `${mediaObject?.thumbnail_src}` }
              break;
          }

          // Should DM metadata also be fetched?
          let credits = mediaObject?.credits;
          if (mediaObject?.media_type === "dm" && mediaObject?.identifier) {
            await Api.getDMContent(`${DM_OBJECT_URL.replace("{id}", mediaObject?.identifier)}&api.key=${DM_API_KEY}`).then(data => {
              let creditList: any[] = [];
              if (data?.producer) { creditList.push({credit_type_id: 1, name: data?.producer}) }
              if (data?.partOfCollection?.name) { creditList.push({credit_type_id: 6, name: data?.partOfCollection?.name}) }
              credits = { credits: creditList };
            });
          }

          setData({...mediaObject, ...url, ...credits, ...media});
          setIsLoading(false);
        }
      }).catch(e => {
        setError(e); 
      });
    })();
    return () => { isSubscribed.current = false; }
  }, [media, data, ignore]);

  if (!media?.mediaId && !media?.url && !media?.dmsId) {
    return [null, null];
  }

  return [data, error, isLoading]
}