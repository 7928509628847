import {createContext} from "react";

interface AppProviderProps {
  currentLanguage: string,
  currentFontSize: number,
  previewIsActive: boolean,
  ownerFilter: {
    filter: boolean,
    id: number,
    name: string,
    analyticsId: string
  },
  presentationCache: {
    id?: string,
    title?: any,
    description?: any,
    image?: any,
    recordCount: number|undefined,
    selectionCount: number|undefined,
    ownerId?: number,
    ownerName?: string,
    articles?: any,
    selections?: any,
    startItem?: string,
    displayType?: string,
    google_analytics_code?: string
  },
  audioPlayerStats: {
    active?: boolean,
    url?: string,
    caption?: string
  },
  isDesktop: boolean,
  theme: {
    backgroundColor: string,
    textColor: string,
    accentColor: string
  },
  setCurrentLanguage: any,
  setCurrentFontSize: any,
  setPreviewIsActive: any,
  setOwnerFilter: any,
  setPresentationCache: any,
  setAudioPlayerStats: any,
}

export const AppContext = createContext<Partial<AppProviderProps>>({});