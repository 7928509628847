import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

interface SwitchItemProps {
  isActive: boolean,
  setActiveState?: any,
  label: string
}

const Container = styled.div`
  text-align: center;
  position: relative;
  display: inline;
  margin: 2px 0 0;
  pointer: cursor;
`

const SwitchItem = styled.label`
  display: inline-block;
  font-size: 1em;
  height: 1em;
  width: 2em;
  background: #BDB9A6;
  border-radius: 1em;

  & input {
    position: absolute;
    opacity: 0;
  }

  &.checked {
    background-color: ${p => p.theme.accentColor};
  }

  &.checked div {
    transform: translate3d(100%, 0, 0);
  }
`

const Bullet = styled.div`
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #fff;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
  transition: all 300ms;
`


/**
 * Render simple toggle switch
 * @returns {JSX.Element} Component template
 */
export const Switch: FC<SwitchItemProps> = ({isActive, setActiveState, label}): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(isActive);

  /**
   * Toggle swith by click
   */
  const onSwitchChange = useCallback((e) => {
    setActiveState && setActiveState(e.target.checked);
    setIsChecked(e.target.checked);
  }, [setActiveState]);

  /**
   * Toggle swith by keyboard
   */
  const toggleSwitch = useCallback((e) => {
    if (e?.type !== "keydown" || !(e?.key === "Enter" || e?.key === " ")) {
      return;
    }

    if (e?.target?.querySelector("input")?.checked) {
      setIsChecked(false);
      setActiveState(false);
    } else {
      setIsChecked(true);
      setActiveState(true);
    }
  }, [setActiveState]);

  useEffect(() => {
    setIsChecked(isActive);
  }, [isActive]);

  return (
    <Container>
      <SwitchItem className={isChecked ? "checked" : undefined} tabIndex={0} onKeyDown={toggleSwitch} role="button" aria-label={label}><input type="checkbox" role="switch" aria-checked={isChecked ? "true" : "false"} checked={isChecked} onChange={onSwitchChange}/><Bullet/></SwitchItem>
    </Container>
  );
}

export default Switch;