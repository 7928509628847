import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import PageTransition from '../components/PageTransition';
import Header from '../framework/Header';
import Image from '../components/Image';
import ArticleTitle from '../components/BlockTitle';
import Separator from '../components/Separator';
import { VListWrapper, VListItem, VListPlaceholderItem } from '../components/ListVertical';
import { HListWrapper, HListItem, HListPlaceholderItem } from '../components/ListHorizontal';
import HeroFrontPage from '../components/HeroFrontPage';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import ErrorMessage from '../components/ErrorMessage';
import useFetchArticleList from '../hooks/useFetchArticleList';
import useFetchOwnerList from '../hooks/useFetchOwnerList';
import { Helmet } from 'react-helmet-async';
import { trackOwner } from '../common/Analytics';
import styled from 'styled-components';
import BlockText from '../components/BlockText';
import { LocationContext } from '../contexts/LocationContext';
import { useNavigate } from 'react-router-dom';
import { KULTURIO_BASE_URL } from '../Settings';

interface HomeViewProps {
  active?: boolean
}

const AboutBlock = styled.div.attrs((p: any) => ({
  isDesktop: p.isDesktop || false,
}))`
  display: flex;
  flex-direction: ${p => p.isDesktop ? "row" : "column"};
  clear: both;
  margin: 0 0 25px 0;

  & figure {
    float: left;
    width: ${p => p.isDesktop ? "60%" : "inherit"};;
  }
`;

const AboutImage = styled(Image)`
  float: left;
  width: calc(100% - 50px);
  height: 240px;
  margin: 0 0 25px 25px;
`;

const ShowAllItem = styled.li`
  flex: 0 0 auto;
  display: flex;
  width: 120px;
  height: 120px;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}`;

/**
 * Render the front page
 * @returns {JSX.Element} Component template
 */
const HomeView: FC<HomeViewProps> = ({active}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentLanguage, ownerFilter, isDesktop } = useContext(AppContext);
  const { geoLocation } = useContext(LocationContext);
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [articleList, articleIsLoading, articleError] = useFetchArticleList({ location: true, page_size: 10, owner_id: ownerFilter?.id }, { updateDistance: true }, active);
  const [ownerList, ownerIsLoading, ownerError] = useFetchOwnerList({ location: true, page_size: 8 }, { updateDistance: true }, active);
  const [geoLocationIsDetected, setGeoLocationIsDetected] = useState<boolean>(geoLocation?.lat);

  // Navigate to article list
  const showAllArticles = useCallback(() => {
    navigate("/explore/list/articles");
  }, [navigate]);

  // Navigate to owner list
  const showAllOwners = useCallback(() => {
    navigate("/explore/list/owners");
  }, [navigate]);

  // Is any data being loaded?
  useEffect(() => {
    setIsLoading(articleIsLoading || ownerIsLoading);
  }, [setIsLoading, articleIsLoading, ownerIsLoading]);

  // Have any errors ocurred during fetching
  useEffect(() => {
    setError(articleError || ownerError);
  }, [articleError, ownerError, setError]);

  // Update title
  useEffect(() => {
    if (active) {
      document.title = `KulturPunkt`;
    }
  }, [t, active]);

  // Is the user's location available?
  useEffect(() => {
    if (geoLocation?.lat) {
      setGeoLocationIsDetected(true);
    } else {
      setGeoLocationIsDetected(false);
    }
  }, [geoLocation]);

  // Analytics tracker
  useEffect(() => {
    trackOwner(ownerFilter?.analyticsId);
  }, [ownerFilter?.analyticsId]);

  // Display error - if data could not be fetched
  if (error && active) {
    return (
      <>
        <Header/>
        <ErrorMessage message={t(error?.message)} tryAgain={true}/>
      </>
    );
  }

  if (!active) { return null; }

  return (
    <>
      <Helmet htmlAttributes={{lang: currentLanguage}}>
        <meta name="description" content={t("about.body")} />
        <meta property="og:description" content={t("about.body")} />
        <meta name="twitter:description" content={t("about.body")} />
        <link rel="alternate" hrefLang="no" href={`${KULTURIO_BASE_URL}/no`} />
        <link rel="alternate" hrefLang="sv" href={`${KULTURIO_BASE_URL}/sv`} />
        <link rel="alternate" hrefLang="en" href={`${KULTURIO_BASE_URL}/en`} />
        <link rel="alternate" hrefLang="de" href={`${KULTURIO_BASE_URL}/de`} />
        <link rel="alternate" hrefLang="fr" href={`${KULTURIO_BASE_URL}/fr`} />
        <link rel="alternate" hrefLang="es" href={`${KULTURIO_BASE_URL}/es`} />
        <link rel="alternate" hrefLang="pl" href={`${KULTURIO_BASE_URL}/pl`} />
      </Helmet>

      <Header layout={geoLocationIsDetected ? "transparent" : "margin"} showProgress={isLoading}/>
      <PageTransition>
        { ((geoLocation?.lat && articleList?.items?.length > 1) || !articleList?.items) && (
          <HeroFrontPage title={getTranslation(articleList?.items?.[0]?.content?.general?.title, currentLanguage)} location={articleList?.items?.[0]?.location} subtitle={articleList?.items?.[0]?.owner_name} media={{url: articleList?.items?.[0]?.image_url, media_type: "image", ...articleList?.items?.[0]?.content?.general?.image}} link={articleList?.items?.[0]?.id && `/article/${articleList?.items?.[0]?.id}`}/>
        )}

        <>
          { (!articleList?.items || articleList?.items?.length > 1) && (
            <>
              <ArticleTitle title={geoLocationIsDetected ? t("home.pointsNearby") : t("home.pointsUpdated")} level="h2" actionTitle={t("home.showAll")} actionLink="/explore/list/articles"/>
              <HListWrapper count={articleList?.items?.length || 0}>
                {Symbol.iterator in Object(articleList?.items) && articleList?.items?.map((item, i) => {
                  if (i > 0 || !geoLocationIsDetected) {
                    return <HListItem key={`article${i}`} media={{url: item?.image_url, media_type: "image", ...item?.content?.general?.image}} title={getTranslation(item?.content?.general?.title, currentLanguage)} location={item?.content?.metadata?.location} owner={item?.owner_name} link={item?.id && `/article/${item?.id}`}/>
                  } else {
                    return null;
                  }
                })}
                {!articleList?.items ? (
                  <HListPlaceholderItem count={10}/>
                ) : (
                  <ShowAllItem onClick={showAllArticles}>{t("home.showAll")}</ShowAllItem>
                )}
              </HListWrapper>
              <Separator/>
            </>
          )}
        </>

        <ArticleTitle title={geoLocation?.lat ? t("home.museumsNearby") : t("list.museums")} level="h2" actionTitle={t("home.showAll")} actionLink="/explore/list/owners"/>
        
        {isDesktop ? (
          <HListWrapper count={ownerList?.items?.length || 0}>
            {Symbol.iterator in Object(ownerList?.items) && ownerList?.items?.map((item: any, i) => {
              return <HListItem key={`article${i}`} title={item?.name} profile={item} link={item?.id && `/owner/${item?.id}`} location={item?.location}/>
            })}
            {!ownerList?.items ? (
              <HListPlaceholderItem count={10}/>
            ) : (
              <ShowAllItem onClick={showAllOwners}>{t("home.showAll")}</ShowAllItem>
            )}
          </HListWrapper>
        ) : (
          <VListWrapper>
            {Symbol.iterator in Object(ownerList?.items) && ownerList?.items?.map((item: any, i) => {
              return <VListItem key={`article${i}`} title={item?.name} profile={item} link={item?.id && `/owner/${item?.id}`} location={item?.location}/>
            })}
            {!ownerList?.items && (
              <VListPlaceholderItem count={6}/>
            )}
          </VListWrapper>
        )}

        <Separator/>

        <ArticleTitle title={t("about.title")} level="h2"/>
        <AboutBlock isDesktop={isDesktop}>
          <AboutImage media={{ url: "/img/kp_teaser.jpg"}}/>
          <BlockText text={t("about.body")}/>
        </AboutBlock>
      </PageTransition>
    </>
  );
}

export default HomeView;